export const ANNOTATIONS = [
  {
    title: 'Accession Numbers',
    type: 'Accession Numbers',
  },
  {
    title: 'Gene Ontology',
    type: 'Gene Ontology',
  },
  {
    title: 'Genes/Proteins',
    type: 'Gene_Proteins',
  },
  {
    title: 'Diseases',
    type: 'Diseases',
  },
  {
    title: 'Organisms',
    type: 'Organisms',
  },
  {
    title: 'Cell',
    type: 'Cell',
  },
  {
    title: 'Cell Line',
    type: 'Cell Line',
  },
  {
    title: 'Organ Tissue',
    type: 'Organ Tissue',
  },
  {
    title: 'Anatomy',
    type: 'Anatomy',
  },
  {
    title: 'Phenotype',
    type: 'Phenotype',
  },
  {
    title: 'body-site',
    type: 'body-site',
  },
  {
    title: 'Gene Function',
    type: 'Gene Function',
  },
  {
    title: 'Sequence',
    type: 'Sequence',
  },
  {
    title: 'Molecule',
    type: 'Molecule',
  },
  {
    title: 'Molecular Process',
    type: 'Molecular Process',
  },
  {
    title: 'Transcription factor – Target gene',
    type: 'Transcription factor - Target gene',
  },
  {
    title: 'Pathway',
    type: 'Pathway',
  },
  {
    title: 'Protein-protein Interactions',
    type: 'Protein Interaction',
  },
  {
    title: 'Phosphorylation Event',
    type: 'Biological Event',
  },
  {
    title: 'Gene mutations',
    type: 'Gene Mutations',
  },
  // - need more logic to determine the category
  {
    title: 'Gene-Disease OpenTargets',
    type: 'Gene Disease OpenTargets',
  },
  {
    title: 'Gene-Disease DisGeNET',
    type: 'Gene Disease DisGeNET',
  },
  // -
  {
    title: 'Chemicals',
    type: 'Chemicals',
  },
  {
    title: 'Clinical Drug',
    type: 'Clinical Drug',
  },
  {
    title: 'Experimental Methods',
    type: 'Experimental Methods',
  },
  {
    title: 'Resources',
    type: 'Resources',
  },
  {
    title: 'Sample-Material',
    type: 'Sample-Material',
    description: 'Sample from which the microbiome is extracted',
  },
  {
    title: 'Body-Site',
    type: 'Body-Site',
    description: "Host's body region/structure where microbiome is found",
  },
  {
    title: 'Host',
    type: 'Host',
    description: 'The organism where the microbiome is found',
  },
  {
    title: 'State',
    type: 'State',
    description: 'Host/Environment state',
  },
  {
    title: 'Site',
    type: 'Site',
    description: "Microbiome's site within place",
  },
  {
    title: 'Place',
    type: 'Place',
    description: "Microbiome's place or geocoordinates",
  },
  {
    title: 'Date',
    type: 'Date',
    description: 'Sampling date',
  },
  {
    title: 'Engineered',
    type: 'Engineered',
    description: "Microbiome's man-made environment",
  },
  {
    title: 'Ecoregion',
    type: 'Ecoregion',
    description: "Micrbiome's natural environment",
  },
  {
    title: 'Treatment',
    type: 'Treatment',
    description: 'Host/Environment treatments',
  },
  {
    title: 'Kit',
    type: 'Kit',
    description: 'Nucleic acid extraction-kit',
  },
  {
    title: 'Primer',
    type: 'Primer',
    description: 'PCR primers',
  },
  {
    title: 'Gene',
    type: 'Gene',
    description:
      'Target gene(s) (e.g. hypervariable regions of 16s/18s rRNA gene)',
  },
  {
    title: 'LS',
    type: 'LS',
    description: 'Library strategy (e.g amplicon, whole metagenome)',
  },
  {
    title: 'LCM',
    type: 'LCM',
    description: 'Library construction method (e.g. paired-end, single-end)',
  },
  {
    title: 'Sequencing',
    type: 'Sequencing',
    description: 'Sequencing platform',
  },
  {
    title: 'COVoc',
    type: 'COVoc',
  },
]
