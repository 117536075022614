import abstract from './abstract'
import annotations from './annotations'
// fullText => fulltext
import fullTextLinks from './fulltext-links'
import dataLinks from './data-links'
import fulltext from './fulltext'
import sections from './sections'
import textHighlight from './text-highlight'
import evaluations from './evaluations'

const namespaced = true

const actions = {
  resetArticleState({ commit }) {
    // commit('abstract/resetState')
    commit('annotations/resetState')
    commit('dataLinks/resetState')
    commit('fullTextLinks/resetState')
    commit('fulltext/resetState')
    commit('sections/resetState')
    commit('textHighlight/resetState')
    commit('evaluations/resetState')
  },
  loadArticle({ state, dispatch }, params) {
    return new Promise((resolve, reject) =>
      // no need to catch if it always succeeds
      dispatch('resetArticleState').then(() =>
        dispatch('abstract/loadAbstract', params)
          .then(() => {
            const { abstract } = state.abstract

            // place this condition here for simplicity
            if (abstract) {
              dispatch('sections/addSectionsData').then(() => resolve(abstract))
            } else {
              reject(new Error('No abstract'))
            }
          })
          .catch(() => reject(new Error('Abstract failed loading')))
      )
    )
  },
}

const modules = {
  abstract,
  annotations,
  dataLinks,
  fullTextLinks,
  fulltext,
  sections,
  textHighlight,
  evaluations,
}

export default { namespaced, actions, modules }
