export const FREQUENCY_OPTIONS = () => [
  {
    text: 'Daily',
    value: 'DAILY',
  },
  {
    text: 'Weekly',
    value: 'WEEKLY',
  },
  {
    text: 'Monthly',
    value: 'MONTHLY',
  },
]

export const ALL_PREFERRED_DAY_OPTIONS = () => ({
  day: [
    {
      text: 'Monday',
      value: 'MONDAY',
      selected: false,
    },
    {
      text: 'Tuesday',
      value: 'TUESDAY',
      selected: false,
    },
    {
      text: 'Wednesday',
      value: 'WEDNESDAY',
      selected: false,
    },
    {
      text: 'Thursday',
      value: 'THURSDAY',
      selected: false,
    },
    {
      text: 'Friday',
      value: 'FRIDAY',
      selected: false,
    },
    {
      text: 'Saturday',
      value: 'SATURDAY',
      selected: false,
    },
    {
      text: 'Sunday',
      value: 'SUNDAY',
      selected: false,
    },
  ],
  firstDay: [
    {
      text: 'The 1st of the month',
      value: 'DAY',
    },
    // {
    //   text: 'The first weekday',
    //   value: 'WEEKDAY'
    // },
    {
      text: 'The first Monday',
      value: 'MONDAY',
    },
    {
      text: 'The first Tuesday',
      value: 'TUESDAY',
    },
    {
      text: 'The first Wednesday',
      value: 'WEDNESDAY',
    },
    {
      text: 'The first Thursday',
      value: 'THURSDAY',
    },
    {
      text: 'The first Friday',
      value: 'FRIDAY',
    },
    {
      text: 'The first Saturday',
      value: 'SATURDAY',
    },
    {
      text: 'The first Sunday',
      value: 'SUNDAY',
    },
  ],
})

export function getFrequencyOptionTextByValue(value) {
  return FREQUENCY_OPTIONS().find((option) => option.value === value).text
}

export function getPreferredDayOptionTextByValue(value) {
  value = value === 'WEEKDAY' ? 'DAY' : value
  const allPreferredDayOptions = ALL_PREFERRED_DAY_OPTIONS()
  return [
    ...allPreferredDayOptions.day,
    ...allPreferredDayOptions.firstDay,
  ].find((option) => option.value === value).text
}
