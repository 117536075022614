<script>
import { mapState } from 'vuex'
import { fetchArticles } from '@/api'
import { NotificationList, Tooltip } from 'epmc-patterns/components/v2'
import { scrollTo } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
// keys are notification styles while values are comment/corection types
const TYPES = {
  informative: [
    // Associated Publication is not in the template
    'Associated publication',
    'Comment in',
    'Comment on',
    'Erratum for',
    'Erratum in',
    'Original report in',
    'Partial retraction in',
    'Partial retraction of',
    'Preprint in',
    'Reprint in',
    'Reprint of',
    'Republished from',
    'Republished in',
    'Retraction of',
    'Summary for patients in',
    'Update in',
    'Update of',
  ],
  confirmation: [],
  warning: [
    'Expression of concern in',
    'HIR',
    'Preprint of',
    'Preprint under review',
    'Preprint not reviewed',
    'Evaluations',
  ],
  error: ['Preprint removal', 'Preprint withdrawal', 'Retraction in'],
}

const TYPES_WITH_TITLE = [
  'Comment in',
  'Comment on',
  'Erratum for',
  'Original report in',
  'Partial retraction of',
  'Preprint of',
  'Retraction of',
  'Summary for patients in',
  'Update in',
  'Update of',
]

export default {
  components: { NotificationList, Tooltip },
  props: {
    // commentCorrectionList will be "transformed" into notificationList
    commentCorrectionList: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Number,
      default: 3,
    },
    allowedNofiticationStyles: {
      type: Array,
      default: () => Object.keys(TYPES),
    },
  },
  data() {
    return {
      notificationList: [],
    }
  },
  computed: {
    ...mapState('article/sections', ['sections']),
  },
  created() {
    this.allowedNofiticationStyles.forEach(
      (style) =>
        (this.notificationList = this.notificationList.concat(
          this.commentCorrectionList.filter(
            (c) => TYPES[style].indexOf(c.type) !== -1
          )
        ))
    )

    this.notificationList = this.notificationList.sort((a, b) => {
      const d1 = a.reference
        ? Date.parse(a.reference.split('.')[1].split(';')[0])
        : 0
      const d2 = b.reference
        ? Date.parse(b.reference.split('.')[1].split(';')[0])
        : 0
      return d2 - d1
    })

    this.notificationList.forEach((notification) => {
      for (const style in TYPES) {
        if (
          TYPES.hasOwnProperty(style) &&
          TYPES[style].indexOf(notification.type) !== -1
        ) {
          notification.notificationStyle = style
          break
        }
      }
    })

    const range =
      this.show < this.notificationList.length
        ? [0, this.show]
        : [0, this.notificationList.length]
    this.setTitles(range)
  },
  methods: {
    registerMatomoEvent,
    setTitles(range) {
      const articles = []
      for (let i = range[0]; i < range[1]; i++) {
        const notification = this.notificationList[i]
        if (TYPES_WITH_TITLE.indexOf(notification.type) !== -1) {
          articles.push({
            id: notification.id,
            source: notification.source,
          })
        }
      }
      if (articles.length > 0) {
        fetchArticles({
          articles,
        }).then((response) => {
          response.resultList.result.forEach((article) => {
            const notification = this.notificationList.find(
              (notification) => notification.id === article.id
            )
            if (notification) {
              this.$set(notification, 'title', article.title)
            }
          })
        })
      }
    },
    expand(e, secid, boolean) {
      const sec = this.sections.find((sec) => sec.id === secid)
      sec.visible = boolean
      scrollTo('#' + secid, '#' + secid + ' div:first-child')
      history.pushState(null, null, '#' + secid)
      this.sections.forEach((sec) => {
        sec.inFrame = false
      })
      sec.inFrame = true
      this.registerMatomoEventForPreprintNotification(
        this.sections.find((sec) => sec.id === secid).title
      )
    },
    registerMatomoEventForPreprintNotification(eventName) {
      registerMatomoEvent(
        'Article',
        'Preprint notification',
        'Link to ' + eventName
      )
    },
    formatVersionNumbers(arr) {
      if (arr.length > 4) {
        return `${arr[0]}, ${arr[1]}, ${arr[2]}, ... and ${arr[arr.length - 1]}`
      } else if (arr.length === 4) {
        return `${arr[0]}, ${arr[1]}, ${arr[2]} and ${arr[3]}`
      } else if (arr.length > 2 && arr.length < 4) {
        return `${arr[0]}, ${arr[1]} and ${arr[2]}`
      } else if (arr.length === 2) {
        return `${arr[0]} and ${arr[1]}`
      } else return arr.join(', ')
    },
  },
}
</script>
<template>
  <notification-list
    :notification-list="notificationList"
    :show="show"
    @hasShownAll="
      setTitles([show, notificationList.length])
      $emit('shownAll')
    "
  >
    <template slot-scope="{ notification }">
      <template v-if="notification.type === 'Comment in'">
        A comment on this article appears in "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Comment on'">
        This is a comment on "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Erratum for'">
        This corrects "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Erratum in'">
        This article has been corrected. See
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Expression of concern in'">
        The editors have expressed concern for this article. See
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'HIR'">
        Note: This Clinical Guideline may not be the most up-to-date version
        available.
      </template>
      <template v-else-if="notification.type === 'Original report in'">
        The original report appears in "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Partial retraction in'">
        This article has been partially retracted. See
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Partial retraction of'">
        This is a partial retraction of "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Preprint in'">
        This article is based on a previously available
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
        >
          preprint</router-link
        ><span v-else> preprint</span>.
      </template>
      <template v-else-if="notification.type === 'Preprint of'">
        This article is a preprint. A
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: {
              source: notification.source,
              id: notification.id,
              matomo_event: {
                category: 'Article',
                action: 'Preprint notification',
                name: 'Link to published article',
              },
            },
          }"
        >
          journal published article</router-link
        ><span v-else> journal published article</span>
        is available.
      </template>
      <template v-else-if="notification.type === 'Preprint removal'">
        <template v-if="!notification.version">
          This article is a preprint. This preprint has been removed by the
          author(s).
        </template>
        <template v-else>
          This article is a preprint.
          <span
            ><span v-if="notification.removedVersions.length > 1"
              >Versions
            </span>
            <span v-else>Version </span>
            <span>{{
              formatVersionNumbers(notification.removedVersions)
            }}</span>
            of this preprint</span
          >
          {{ notification.removedVersions.length === 1 ? 'has' : 'have' }} been
          removed by the author(s).
        </template>
        <template v-if="notification.source">
          A
          <router-link
            v-if="notification.source && notification.id"
            :to="{
              name: 'article',
              params: {
                source: notification.source,
                id: notification.id,
                matomo_event: {
                  category: 'Article',
                  action: 'Preprint notification',
                  name: 'Link to published article',
                },
              },
            }"
          >
            journal published article</router-link
          ><span v-else> journal published article</span>
          is available.
        </template>
      </template>
      <template v-else-if="notification.type === 'Preprint withdrawal'">
        <template v-if="!notification.version">
          This preprint has been withdrawn by the author(s).
          <template v-if="notification.url">
            <a :href="notification.url"
              >View this record on the preprint server</a
            >
            for more information.
          </template>
        </template>
        <template v-else>
          This article is a preprint.
          <span
            ><span v-if="notification.withdrawnVersions.length > 1"
              >Versions
            </span>
            <span v-else>Version</span>
            <span>{{
              formatVersionNumbers(notification.withdrawnVersions)
            }}</span>
            of this preprint</span
          >
          {{ notification.withdrawnVersions.length === 1 ? 'has' : 'have' }}
          been withdrawn by the author(s).
        </template>
        <template v-if="notification.source">
          A
          <router-link
            v-if="notification.source && notification.id"
            :to="{
              name: 'article',
              params: {
                source: notification.source,
                id: notification.id,
                matomo_event: {
                  category: 'Article',
                  action: 'Preprint notification',
                  name: 'Link to published article',
                },
              },
            }"
          >
            journal published article</router-link
          ><span v-else> journal published article</span>
          is available.
        </template>
      </template>
      <template v-else-if="notification.type === 'Preprint not reviewed'">
        This article is a preprint. It may not have been peer reviewed.
        <tooltip>
          <i slot="trigger" class="far fa-question-circle" />
          A preprint is a complete scientific manuscript that an author uploads
          on a public server for free viewing. Initially it is posted without
          peer review, but may acquire feedback or reviews as a preprint, and
          may eventually be published in a peer-reviewed journal. The posting of
          preprints on public servers allows almost immediate dissemination and
          scientific feedback early in the 'publication' process.
        </tooltip>
      </template>
      <template v-else-if="notification.type === 'Preprint under review'">
        This article is undergoing peer review. Please access the
        <a :href="'https://doi.org/' + notification.doi"
          >article on the publisher's site</a
        >
        to see the current peer review status.
      </template>
      <template v-else-if="notification.type === 'Reprint in'">
        A reprint appears in
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Reprint of'">
        This article is a reprint of
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Republished from'">
        This has been republished from
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Republished in'">
        This has been republished in
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Retraction in'">
        This article has been retracted. See
        <router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          >{{ notification.reference }}</router-link
        ><span v-else>{{ notification.reference }}</span
        >.
      </template>
      <template v-else-if="notification.type === 'Retraction of'">
        This retracts "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>" in
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Summary for patients in'">
        A summary for patients appears in "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Update in'">
        This article has been updated in "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Update of'">
        This is an update of "<router-link
          v-if="notification.source && notification.id"
          :to="{
            name: 'article',
            params: { source: notification.source, id: notification.id },
          }"
          v-html="notification.title"
        ></router-link
        ><span v-else v-html="notification.title"></span>"
        {{ notification.reference }}.
      </template>
      <template v-else-if="notification.type === 'Evaluations'">
        <template v-if="!notification.version">
          This article is a preprint.
          <a @click.prevent="expand($event, 'reviews', true)"
            >Reviews & evaluations</a
          >
          are available.
        </template>
        <template v-else>
          This article is a preprint. Reviews & evaluations are available for a
          version(s) in the Version history below.
        </template>
      </template>
    </template>
  </notification-list>
</template>
