import { fetchFulltextHtml } from '@/api'
import {
  addStylesAndScripts,
  processFulltextDoc,
} from '@/helpers/article/fulltext'

const getDefaultState = () => {
  return {
    fulltextHtml: '',
  }
}

const namespaced = true

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async loadFulltextHtml({ state, rootState, commit }, fulltextId) {
    const { abstract } = rootState.article.abstract
    const { bookid, hasBook, id, pmcid, source } = abstract

    commit('resetState')

    let ftId = ''
    if (fulltextId) {
      ftId = fulltextId
    } else if (pmcid) {
      ftId = pmcid
    } else if (['NBK', 'PPR'].includes(source)) {
      ftId = id
    } else if (hasBook === 'Y') {
      ftId = bookid
    }

    const response = await fetchFulltextHtml(ftId)
    const html = processFulltextDoc(ftId, response)
    state.fulltextHtml = html
    addStylesAndScripts(ftId, response)

    commit(
      'article/sections/changeSectionVariable',
      {
        id: 'free-full-text',
        preload: true,
      },
      { root: true }
    )

    return state.fulltextHtml
  },
}

export default { namespaced, state, mutations, actions }
