const formatNum = (num) => {
  num = num.toFixed(1)
  num = num.toString().endsWith('.0') ? parseInt(num, 10) : num
  return num.toLocaleString()
}

export default function convertNum(num) {
  if (num / 1000000000 >= 1) {
    num = formatNum(num / 1000000000) + ' billion'
  } else if (num / 1000000 >= 1) {
    num = formatNum(num / 1000000) + ' million'
  }
  // else if (num / 1000 >= 1) {
  //   num = formatNum(num / 1000) + ' thousand'
  // }
  else {
    num = formatNum(num)
  }
  return num
}
