<script>
import SocialPanel from '@/templates/static/SocialPanel'

export default {
  components: {
    SocialPanel,
  },
  methods: {
    isExternalLink(item) {
      return item.link.includes('//')
    },
    onFeedbackModalClosed(e) {
      if (e && e.success) {
        this.showFeedbackConfirmation = true
      }
      this.showFeedbackModal = false
    },
    onFeedbackConfirmationClosed() {
      this.showFeedbackConfirmation = false
    },
  },
}
</script>
<template>
  <div id="footer">
    <social-panel />
    <div id="footer-bottom">
      <div class="partnership-and-funding container">
        <h2>Partnerships &amp; funding</h2>
        <p>
          Europe PMC is developed by
          <a href="https://www.ebi.ac.uk/">EMBL-EBI</a> with support from the
          <a href="https://europepmc.org/Funders/">Europe PMC Funders' Group</a
          >, in collaboration with the
          <a href="https://www.nlm.nih.gov/"
            >National Library of Medicine (NLM)</a
          >, as part of the
          <a href="https://www.ncbi.nlm.nih.gov/pmc/about/pmci/"
            >PubMed Central International</a
          >
          archive network.
        </p>
        <div class="partner-logos">
          <img style="margin-right: 20px" src="@/assets/elixir-footer.png" />
          <img style="margin-right: 20px" src="@/assets/gbc-footer.png" />
          <img src="@/assets/embl-footer.png" />
        </div>
        <p>
          Europe PMC is an
          <a
            href="https://elixir-europe.org/platforms/data/core-data-resources"
          >
            ELIXIR Core Data Resource</a
          >,
          <a
            href="https://globalbiodata.org/what-we-do/global-core-biodata-resources/"
          >
            Global Core Biodata Resource</a
          >, and conforms with
          <a href="https://www.ebi.ac.uk/long-term-data-preservation">
            EMBL-EBI’s long term data preservation policies</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#footer {
  text-align: left;
  #footer-bottom {
    #footer-bottom-links {
      text-align: center;
      margin-top: $base-unit * 5;
    }
    .partnership-and-funding {
      text-align: center;
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      padding-bottom: $base-unit * 14;
    }
    .partner-logos {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
