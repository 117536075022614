<script>
import { mapActions } from 'vuex'
import {
  getFrequencyOptionTextByValue,
  getPreferredDayOptionTextByValue,
} from '@/config'
import {
  deleteAlert,
  readAccount,
  readAlerts,
  resendAlertConfirmationEmail,
} from '@/api'
import { registerMatomoEvent } from '@/helpers/matomo'
import {
  Action,
  Confirmation,
  ContentSection,
  List,
  Loading,
  NotificationList,
} from 'epmc-patterns/components/v2'
import AlertModal from '@/templates/AlertModal'

export default {
  metaInfo() {
    return {
      title: 'Saved alerts - Accounts - Europe PMC',
    }
  },
  components: {
    Action,
    AlertModal,
    Confirmation,
    ContentSection,
    List,
    Loading,
    NotificationList,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    alertName: {
      type: String,
      default: '',
    },
    alertQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      alerts: [],
      selectedAlert: null,
      confirmationMessage: '',
      modalTitle: '',
      account: {},
      notificationList: [],
      loading: true,
      showRefreshButton: false,
      quickFinderInput: '',
    }
  },
  computed: {
    displayedAlerts() {
      return this.alerts.filter((alert) =>
        alert.name.toLowerCase().includes(this.quickFinderInput.toLowerCase())
      )
    },
  },
  async created() {
    const { alertName, alertQuery, email, status } = this

    this.readAccount()
    const alerts = await this.readAlerts()

    let confirmationMessage = ''

    if (status) {
      switch (status) {
        case 'alert_created':
          confirmationMessage = 'You have created a new alert.'
          break
        case 'alert_created_without_email':
          confirmationMessage = `An email has been sent to <a href="mailto:${email}">${email}</a>, and the email must be verified before email updates will be sent to this address.`
          break
        case 'inactive_alert_created':
          confirmationMessage = 'You have created an inactive alert.'
          break
        case 'alert_edited':
          confirmationMessage = 'You have edited an alert.'
          break
        case 'alert_converted':
          confirmationMessage =
            'You have converted a previously saved search to an alert.'
          break
        case 'alert_converted_to_inactive':
          confirmationMessage =
            'You have converted a previously saved search to an inactive alert.'
          break
        case 'alert_deleted':
          confirmationMessage = 'You have deleted an alert.'
          break
        case 'email_already_verified':
          confirmationMessage = 'The alerts email has been verified.'
          break
        case 'on_alert_modal_opened': {
          const { alert, title } = await this.getAndClearAlertModal()
          this.openAlertModal(alert || {}, title)
          break
        }
      }

      if (['alert_created', 'inactive_alert_created'].includes(status)) {
        // at least one alert
        if (alerts.length) {
          const alertsWithoutFirst = [...alerts]
          alertsWithoutFirst.shift()
          const sameAlertName = alertsWithoutFirst.find(
            (alert) => alert.name === alertName
          )
          const sameAlertQuery = alertsWithoutFirst.find(
            (alert) => alert.query === alertQuery
          )
          if (sameAlertName && sameAlertQuery) {
            confirmationMessage +=
              ' The name and query for this alert duplicates the name and query for another saved alert.'
          } else if (sameAlertName) {
            confirmationMessage +=
              ' The name of this alert duplicates the name of another saved alert.'
          } else if (sameAlertQuery) {
            confirmationMessage +=
              ' The query for this alert duplicates the query for another saved alert.'
          }
        }
      }

      this.confirmationMessage = confirmationMessage

      this.$router.replace({ query: null })
    }

    registerMatomoEvent(
      'Saved alerts',
      'Arrive on Saved alerts page',
      'Saved alerts page'
    )
  },
  methods: {
    ...mapActions('alert', ['getAndClearAlertModal']),
    async readAccount() {
      const response = await readAccount()
      if (!response['Error']) {
        this.account = response
        if (
          response.tempNotificationEmail &&
          response.tempNotificationEmail !==
            (response.notificationEmail || response.email)
        ) {
          this.notificationList.unshift({
            name: 'temp-email',
            notificationStyle: 'error',
          })
        }
      }
    },
    async readAlerts() {
      this.loading = true
      this.showRefreshButton = false
      try {
        const alerts = await readAlerts()
        if (!alerts['Error']) {
          this.alerts = alerts
        }
        if (!alerts.length) {
          this.notificationList.push({
            name: 'no-alerts',
            notificationStyle: 'error',
          })
        }
        this.loading = false
        return alerts
      } catch (e) {
        this.showRefreshButton = true
      }
    },
    createAlert() {
      this.openAlertModal({}, 'Create alert')
      registerMatomoEvent(
        'Saved alerts',
        'Click "Create alert"',
        'Create alert'
      )
    },
    convertAlert(item) {
      this.openAlertModal({ ...item, isAlert: true }, 'Convert to alert')
      registerMatomoEvent(
        'Saved alerts',
        'Click "Convert" alert',
        'Convert alert'
      )
    },
    editAlert(item) {
      this.openAlertModal(item, 'Edit alert')
      registerMatomoEvent(
        'Saved alerts',
        'Click "Edit" alert icon',
        'Edit alert'
      )
    },
    getFrequencyOptionTextByValue,
    getPreferredDayOptionTextByValue,
    openAlertModal(alert, modalTitle) {
      this.selectedAlert = alert
      this.modalTitle = modalTitle
    },
    async deleteAlert(alert) {
      if (window.confirm('Are you sure you want to delete this alert?')) {
        const response = await deleteAlert(alert)
        if (!response['Error']) {
          location.href = '/accounts/SavedAlerts?status=alert_deleted'
        }
      }
      registerMatomoEvent(
        'Saved alerts',
        'Cick "Delete" alert icon',
        'Delete alert'
      )
    },
    async resendAlertConfirmationEmail() {
      try {
        const response = await resendAlertConfirmationEmail()
        if (!response['Error']) {
          const email = this.account.tempNotificationEmail
          this.confirmationMessage = `An email has been sent to <a href="mailto:${email}">${email}</a>, and the email must be verified before email updates will be sent to this address.`
        }
      } catch (e) {
        location.href = '/accounts/SavedAlerts?status=email_already_verified'
      }
    },
    closeAlertModal() {
      this.selectedAlert = null
    },
    connectAccounts() {
      registerMatomoEvent(
        'Saved alerts',
        'Click "Connect accounts"',
        'Alerts-Connect accounts'
      )
    },
    generateRssFeed(item) {
      window.open(`/searchrss?query=${item.query}&mode=rss`)
    },
  },
}
</script>
<template>
  <div id="saved-alerts-page">
    <div id="saved-alerts-menu">
      <action id="Alert_Edit_Alert_Email" :to="{ name: 'changeAlertsEmail' }"
        >Edit alerts email</action
      >&nbsp;|&nbsp;<action
        id="Alert_Create_new_alert"
        @click.prevent="createAlert"
        >Create a new alert</action
      >
    </div>
    <confirmation v-if="confirmationMessage" @close="confirmationMessage = ''">
      <span v-html="confirmationMessage" />
    </confirmation>
    <content-section
      :section="{
        id: 'saved-alerts-container',
        heading: 'h2',
        title: 'My saved alerts',
      }"
    >
      <loading
        v-if="loading"
        :show-refresh-button="showRefreshButton"
        @refresh="readAlerts"
      />
      <notification-list v-else :notification-list="notificationList">
        <template slot-scope="{ notification }">
          <template v-if="notification.name === 'temp-email'">
            Alerts will be sent to
            <a :href="'mailto:' + account.tempNotificationEmail">{{
              account.tempNotificationEmail
            }}</a>
            after the email has been verified. Check your inbox. |
            <action
              class="regular"
              @click.prevent="resendAlertConfirmationEmail"
              >Resend</action
            >
          </template>
          <template v-else-if="notification.name === 'no-alerts'">
            You have no saved alerts. The best way to create an alert is to
            enter a search query in the search bar, and when you are satisfied
            with the results of the query, click the Save & create alert button.
          </template>
        </template>
      </notification-list>

      <div id="quick-finder-container">
        <label for="quick-finder">Quick finder</label>
        <input
          id="quick-finder"
          v-model.trim="quickFinderInput"
          type="text"
          placeholder="Enter keyword to search alerts"
        />
        <span id="search-icon">
          <i class="fas fa-search" />
        </span>
      </div>
      <list
        :list="displayedAlerts"
        :separator-above="true"
        :separator-below="true"
      >
        <div slot-scope="{ item }" class="alert">
          <div class="alert-content">
            <div>
              <a
                :href="
                  '/search?query=' +
                  encodeURIComponent(item.query) +
                  '&sortBy=' +
                  encodeURIComponent('FIRST_IDATE_D+desc')
                "
                class="semi-bold"
                >{{ item.name }}</a
              >
            </div>
            <div>
              <action
                v-if="item.isPrevious"
                @click.prevent="convertAlert(item)"
              >
                <i slot="icon" class="fas fa-exclamation-triangle" />Convert
                this saved search to an alert
              </action>
              <template v-else-if="item.isAlert">
                {{ getFrequencyOptionTextByValue(item.frequency)
                }}{{
                  item.preferredDay
                    ? ': ' + getPreferredDayOptionTextByValue(item.preferredDay)
                    : ''
                }}
              </template>
              <span v-else class="semi-bold">Inactive alert</span>
            </div>
          </div>
          <div class="alert-actions">
            <action
              v-if="!item.isPrevious"
              class="alert-action Alert_Edit_Alert_Icon"
              aria-label="Edit alert"
              @click.prevent="editAlert(item)"
            >
              <i slot="icon" class="fas fa-edit" />
            </action>
            <action
              class="alert-action"
              aria-label="Generate RSS feed"
              @click.prevent="generateRssFeed(item)"
            >
              <i slot="icon" class="fas fa-rss" />
            </action>
            <action
              class="alert-action Alert_Delete_Alert_Icon"
              aria-label="Delete alert"
              @click.prevent="deleteAlert(item)"
            >
              <i slot="icon" class="fas fa-times" />
            </action>
          </div>
        </div>
      </list>
      <p>
        If you’d like to merge your Saved alerts from another sign-in account,
        you can
        <a href="/accounts/ConnectAccounts" @click="connectAccounts"
          >Connect accounts</a
        >
        here.
      </p>
    </content-section>
    <alert-modal
      v-if="selectedAlert"
      :alert="selectedAlert"
      :title="modalTitle"
      @close="closeAlertModal"
    />
  </div>
</template>
<style scoped lang="scss">
#saved-alerts-menu {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  @media screen and (max-width: $breakpoint-extra-small) {
    justify-content: center;
  }
}
.fa-exclamation-triangle {
  color: $epmc-dark-orange;
}
#quick-finder-container {
  position: relative;
  display: inline-block;
  margin-top: $base-unit * 4;
  margin-bottom: $base-unit * 4;
  #quick-finder {
    margin-left: $base-unit * 2;
    padding-right: $base-unit * 8;
  }
  #search-icon {
    position: absolute;
    top: $base-unit * 0.25;
    right: 0;
    width: $base-unit * 8;
    line-height: $base-unit * 8;
    text-align: center;
    color: $epmc-darker-blue;
  }
}
.alert {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .alert-actions {
    display: flex;
    .alert-action {
      margin-left: $base-unit * 4;
    }
  }
}
</style>
