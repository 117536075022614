import { EPMC_WEB_SERVICES_ROOT } from '@/config'
import { send } from '@/api'

export function fetchArticleStatus(ids) {
  return send(
    EPMC_WEB_SERVICES_ROOT + 'status-update-search?format=json',
    'POST',
    {
      ids,
    }
  )
}
