export const SECTIONS = [
  {
    id: 'keywordsOrTopics',
    heading: 'h3',
    title: 'Enter free text word(s) or phrase',
    type: 'primary',
    collapsible: true,
    visible: true,
    highlight: true,
    scroll: true,
  },
  /*{
    id: 'mesh',
    heading: 'h3',
    title: 'Find MeSH (Medical Subject Headings)',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'biologicalAndChemicalEntities',
    heading: 'h3',
    title:
      'Find biological & chemical entities (genes, proteins, diseases, etc.)',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'dateTypeSourceId',
    heading: 'h3',
    title: 'Apply date, type, source or ID',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'authorAffiliationJournalPublisher',
    heading: 'h3',
    title: 'Add author, affiliation, journal or publisher',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },
  {
    id: 'fundingLicensingFullTextAvailability',
    heading: 'h3',
    title: 'Specify funding, licensing, or full text availability',
    type: 'primary',
    collapsible: true,
    visible: false,
    highlight: true,
    scroll: true,
    disabled: true,
  },*/
]
export const PLACES_TO_SEARCH = [
  {
    id: 'title',
    label: 'Title',
    value: 'TITLE',
    information:
      'This search looks only within the titles of articles, books, etc., and tends to return more relevant results.',
    selected: false,
  },
  {
    id: 'abstract',
    label: 'Abstract',
    value: 'ABSTRACT',
    information:
      'This search looks only within the abstract of articles, books, etc., and tends to return more relevant results.',
    selected: false,
  },
  {
    id: 'fullText',
    label: 'All fields + Full text',
    value: '',
    information:
      'This search looks within all key article metadata fields, e.g. title, abstract, MeSH, author, affiliation, etc., plus the full text when available. This tends to expand the number of results.',
    selected: false,
  },
]
export const ARTICLE_SECTION_OPTIONS = [
  {
    value: 'ABBR',
    text: 'Abbreviations',
    selected: false,
  },
  {
    value: 'ACK_FUND',
    text: 'Acknowledgement & funding',
    selected: false,
  },
  {
    value: 'APPENDIX',
    text: 'Appendix',
    selected: false,
  },
  {
    value: 'AUTH_CON',
    text: 'Author Contribution',
    selected: false,
  },
  {
    value: 'CASE',
    text: 'Case Study',
    selected: false,
  },
  {
    value: 'COMP_INT',
    text: 'Competing Interest',
    selected: false,
  },
  {
    value: 'CONCL',
    text: 'Conclusion & Future Work',
    selected: false,
  },
  {
    value: 'DATA_AVAILABILITY',
    text: 'Data Availability',
    selected: false,
  },
  {
    value: 'DISCUSS',
    text: 'Discussion',
    selected: false,
  },
  {
    value: 'FIG',
    text: 'Figures',
    selected: false,
  },
  {
    value: 'INTRO',
    text: 'Introduction & Background',
    selected: false,
  },
  {
    value: 'METHODS',
    text: 'Materials & Methods',
    selected: false,
  },
  {
    value: 'OTHER',
    text: 'Other',
    selected: false,
  },
  {
    value: 'REF',
    text: 'References',
    selected: false,
  },
  {
    value: 'RESULTS',
    text: 'Results',
    selected: false,
  },
  {
    value: 'SUPPL',
    text: 'Supplementary Data',
    selected: false,
  },
  {
    value: 'TABLE',
    text: 'Tables',
    selected: false,
  },
]
