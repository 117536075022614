var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"grant-details-page"}},[(_vm.loading)?_c('loading'):_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-4"},[(_vm.grant['Funder'] && _vm.grant['Funder']['Name'])?[(_vm.imageSrc)?_c('div',[_c('a',{attrs:{"href":_vm.imageHref}},[_c('img',{staticClass:"funder-image",style:({ width: _vm.imageWidth + 'px' }),attrs:{"src":_vm.imageSrc,"alt":_vm.grant['Funder']['Name']}})])]):_vm._e(),_c('h4',[_vm._v("Funded by")]),_c('div',[_c('router-link',{attrs:{"to":_vm.funderLink,"aria-label":"Funder name"}},[_vm._v("\n            "+_vm._s(_vm.grant['Funder']['Name'])+"\n          ")])],1)]:_vm._e(),(_vm.amount)?_c('div',{staticClass:"feature-small semi-bold amount"},[_vm._v("\n        "+_vm._s(_vm.amount)+"\n      ")]):_vm._e(),(_vm.grant['StartDate'] || _vm.grant['EndDate'])?[_c('h4',[_vm._v("Duration")]),_c('div',[_vm._v("\n          "+_vm._s(_vm.grant['StartDate'] ? _vm.getDate(_vm.grant['StartDate']) : '')+_vm._s(_vm.grant['StartDate'] && _vm.grant['EndDate'] ? ' - ' : '')+_vm._s(_vm.grant['EndDate'] ? _vm.getDate(_vm.grant['EndDate']) : '')+"\n        ")])]:_vm._e(),(_vm.grant['Id'])?[_c('h4',[_vm._v("Internal grant ID")]),_c('div',[_vm._v(_vm._s(_vm.grant['Id']))])]:_vm._e(),(_vm.grant['Doi'])?[_c('h4',[_vm._v("Grant DOI")]),_c('div',[_c('a',{attrs:{"href":_vm.formatDoi,"target":"_blank"}},[_vm._v(_vm._s(_vm.formatDoi))])])]:_vm._e(),(_vm.grant['Stream'])?[_c('h4',[_vm._v("Funding stream")]),_c('div',[_vm._v(_vm._s(_vm.grant['Stream']))])]:_vm._e(),(_vm.grant['Type'])?[_c('h4',[_vm._v("Grant type")]),_c('div',[_vm._v(_vm._s(_vm.grant['Type']))])]:_vm._e(),_c('h4',[_vm._v("Publications")]),(_vm.allPubCount || _vm.ftCount)?[(_vm.allPubCount)?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'search',
              query: {
                query: _vm.allPubLink,
              },
            }}},[_vm._v("\n            All publications ("+_vm._s(_vm.allPubCount)+")\n          ")])],1):_vm._e(),(_vm.ftCount)?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'search',
              query: {
                query: _vm.allFTLink,
              },
            }}},[_vm._v("\n            Free to read articles ("+_vm._s(_vm.ftCount)+")\n          ")])],1):_vm._e()]:_c('div',[_vm._v("No publications available")])],2),_c('div',{staticClass:"col-12"},[(_vm.back)?_c('div',{staticClass:"back-action"},[_c('action',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-arrow-left",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Back to search results\n        ")])],1):_vm._e(),(_vm.grant['Title'])?_c('h1',[_vm._v("\n        "+_vm._s(_vm.grant['Title'])+"\n      ")]):_vm._e(),(_vm.person['FamilyName'] || _vm.institution['Name'])?_c('div',{staticClass:"author-metadata"},[(_vm.person['FamilyName'])?_c('router-link',{attrs:{"to":_vm.personLink,"aria-label":"Author name"}},[_vm._v("\n          "+_vm._s(_vm.person['Title'] || '')+"\n          "+_vm._s(_vm.person['FamilyName'])+_vm._s(_vm.person['GivenName']
              ? ', ' + _vm.person['GivenName']
              : _vm.person['Initials']
              ? ', ' + _vm.person['Initials']
              : ''))]):_vm._e(),(_vm.orcid)?[_vm._v("\n          "+_vm._s(_vm.person['FamilyName'] ? ' | ' : '')+"\n          "),_c('router-link',{attrs:{"to":{ name: 'grantSearch', query: { pi_id: _vm.orcid, cat: _vm.cat } },"aria-label":"ORCID"}},[_vm._v("\n            ORCID: "+_vm._s(_vm.orcid)+"\n          ")])]:_vm._e(),(_vm.institution && _vm.institution['Name'])?[_vm._v("\n          "+_vm._s(_vm.orcid || _vm.person['FamilyName'] ? ' | ' : '')+"\n          "),_c('router-link',{attrs:{"to":_vm.affLink,"aria-label":"Author institution"}},[_vm._v("\n            "+_vm._s(_vm.institution['Name'])+"\n          ")])]:_vm._e()],2):_vm._e(),(_vm.orcid)?_c('div',{staticClass:"author-profile"},[_c('action',{attrs:{"to":{
            name: 'authorProfile',
            params: { orcid: _vm.orcid },
          },"itemprop":"mainEntityOfPage"}},[_c('i',{staticClass:"fas fa-user",attrs:{"slot":"icon"},slot:"icon"}),_vm._v("Author profile\n        ")])],1):_vm._e(),_c('content-section',{attrs:{"section":{
          id: 'grant-abstract',
          heading: 'h2',
          title: 'Abstract',
        }}},[_vm._v("\n        "+_vm._s(_vm.grant['Abstract'] && _vm.grant['Abstract']['$']
            ? _vm.grant['Abstract']['$']
            : _vm.grant['Abstract'] && _vm.grant['Abstract'].length
            ? _vm.grant['Abstract'][0]['$']
            : 'Abstract not provided.')+"\n      ")]),(_vm.grant['Abstract'] && _vm.grant['Abstract'].length > 1)?_c('content-section',{attrs:{"section":{
          id: 'grant-lay-abstract',
          heading: 'h2',
          title: 'Lay abstract',
        }}},[_vm._v("\n        "+_vm._s(_vm.grant['Abstract'][1]['$'])+"\n      ")]):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }