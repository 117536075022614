<script>
import { setCookie } from '@/helpers/cookie'
import { fetchPrivacyNotice } from '@/api'
import { getPrivacyNoticeApiKey } from '@/helpers/privacy'

export default {
  data() {
    return {
      privacyNotice: null,
    }
  },
  async created() {
    const key = getPrivacyNoticeApiKey()
    const response = await fetchPrivacyNotice(key)
    this.privacyNotice = response ? response.nodes[0].node : null
  },
  methods: {
    onPrivacyNoticeClicked() {
      window.open('/PrivacyNotice')
    },
    agree() {
      setCookie('epmcPrivacyNoticeAgreed', true, 30)
      const privacyPolicyVersionCount =
        this.privacyNotice['version Count'] ||
        this.privacyNotice['version_count']
      if (privacyPolicyVersionCount) {
        setCookie('privacyPolicyVersionCount', privacyPolicyVersionCount, 30)
      }
      this.$emit('close')
    },
  },
}
</script>
<template>
  <div id="data-protection-banner">
    <div class="grid-row">
      <div class="col-16">
        <div id="data-protection-banner-left">
          This website requires cookies, and the limited processing of your
          personal data in order to function. By using the site you are agreeing
          to this as outlined in our
          <a href="/PrivacyNotice" @click.prevent="onPrivacyNoticeClicked"
            >privacy notice and cookie policy</a
          >.
        </div>
        <div>
          <input
            id="data-protection-agree"
            type="button"
            class="secondary"
            value="I agree, dismiss this banner"
            @click.prevent="agree"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#data-protection-banner {
  position: fixed;
  bottom: 0;
  z-index: 7;
  width: 100%;
  box-shadow: -1px 1px 4px 2px #111111;
  padding: ($base-unit * 4) 0;
  background-color: $epmc-darker-blue;
  color: white;
  text-align: left;
  .col-16 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $base-unit * 8;
    @media only screen and (max-width: $breakpoint-small) {
      display: block;
      #data-protection-banner-left {
        margin-bottom: $base-unit * 4;
      }
    }
  }
  a {
    color: white !important;
    text-decoration: underline;
  }
}
</style>
