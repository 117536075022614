import { MATOMO_SITE_ID } from '@/config'

// can merged into another helper
function loadScript(trackerScript) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = trackerScript

    const head = document.head || document.getElementsByTagName('head')[0]
    head.appendChild(script)

    script.onload = resolve
    script.onerror = reject
  })
}

function piwikExists() {
  return new Promise((resolve) => {
    const checkInterval = 50
    const timeout = 3000
    const waitStart = Date.now()

    const interval = setInterval(() => {
      if (window.Piwik) {
        clearInterval(interval)

        return resolve()
      }

      if (Date.now() >= waitStart + timeout) {
        clearInterval(interval)

        throw new Error(
          `[vue-matomo]: window.Piwik undefined after waiting for ${timeout}ms`
        )
      }
    }, checkInterval)
  })
}

function getMatomo() {
  return window.Piwik.getAsyncTracker()
}

export async function registerMatomoEvent(
  eventCategory,
  eventAction,
  eventName
) {
  await piwikExists()
  const Matomo = getMatomo()
  Matomo.trackEvent(eventCategory, eventAction, eventName)
}

export async function registerMatomoPageView(to) {
  if (to && to.fullPath) {
    window._paq.push([
      'setCustomUrl',
      window.location.protocol +
        '//' +
        window.location.host +
        to.fullPath.split('?')[0],
    ])
  }
  window._paq.push(['trackPageView'])
}

export async function loadMatomoScript() {
  try {
    const hostname = window.location.hostname
    const protocol = hostname.includes('europepmc.org') ? 'https://' : 'http://'
    let host = protocol + hostname
    if (host.includes('localhost')) {
      const port = window.location.port
      host = host + ':' + port
    }
    const trackerEndpoint = `${host}/piwik.jsp`
    const trackerScript = `${host}/piwik.js`
    window._paq.push(['setTrackerUrl', trackerEndpoint])
    window._paq.push(['setSiteId', MATOMO_SITE_ID])
    // not sure whether enableLinkTracking is needed
    window._paq.push(['enableLinkTracking'])
    await loadScript(trackerScript)
  } catch (error) {
    throw error
  }
}
