import VuexPersistence from 'vuex-persist'
import alert from './modules/alert'
import article from './modules/article'
import exportList from './modules/export-list'
import orcid from './modules/orcid'
import search from './modules/search'
import searchSessions from './modules/search-sessions'

const modules = {
  alert,
  article,
  exportList,
  orcid,
  search,
  searchSessions,
}

const getPlugins = () => {
  let plugins
  try {
    plugins = [
      new VuexPersistence({
        storage: window.sessionStorage,
        modules: ['alert', 'exportList', 'searchSessions'],
      }).plugin,
    ]
  } catch (e) {}
  return plugins
}

const plugins = getPlugins()

export default { modules, plugins }
