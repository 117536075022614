var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-row",attrs:{"id":"grant-search-page"}},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('content-section',{attrs:{"section":{
        heading: 'h2',
        title: _vm.getTitle(),
      }}},[(_vm.hitCount)?_c('button',{staticClass:"secondary",attrs:{"id":"export-btn"},on:{"click":function($event){$event.preventDefault();_vm.showExport = true}}},[_vm._v("\n        Export\n      ")]):_vm._e(),(_vm.getUiType() === 'epmc-search')?[_vm._l((_vm._props),function(v,k){return [(_vm.GRANT_FILED_NAMES[k] && v)?_c('div',{key:k},[_c('b',[_vm._v(_vm._s(_vm.GRANT_FILED_NAMES[k]))]),_vm._v(":\n            "+_vm._s(typeof v === 'object' && v.length
                ? v.join(', ')
                : k === 'current' && v
                ? 'active'
                : v.replace(/{/g, '').replace(/}/g, ' '))+"\n          ")]):_vm._e()]}),_c('div',{staticClass:"search-again"},[_c('action',{attrs:{"to":{ name: 'grantFinderForm' }}},[_vm._v("Search again"),_c('i',{staticClass:"fas fa-search",attrs:{"slot":"icon"},slot:"icon"})])],1)]:[_c('p',[_vm._v("\n          Find grants related to COVID-19 research. These grants are not\n          limited to Europe PMC funders. This data is compiled by the UKCDR\n          and GloPID-R COVID-19\n          "),_c('a',{attrs:{"href":"//www.ukcdr.org.uk/funding-landscape/covid-19-research-project-tracker/"}},[_vm._v("Research Project Tracker")]),_vm._v(", 2020, with the grateful support of funders and research\n          organisations worldwide.\n        ")]),(_vm.showFilterIndication)?[_vm._l((_vm._props),function(v,k){return [(_vm.GRANT_FILED_NAMES[k] && v)?_c('div',{key:k},[_c('b',[_vm._v(_vm._s(_vm.GRANT_FILED_NAMES[k]))]),_vm._v(":\n              "+_vm._s(typeof v === 'object' && v.length
                  ? v.join(', ')
                  : v.replace(/{/g, '').replace(/}/g, ' '))+"\n            ")]):_vm._e()]}),_c('div',{staticClass:"search-again"},[_c('action',{attrs:{"to":{ name: 'grantSearch', query: { kw: _vm.kw, cat: 'COVID-19' } }}},[_vm._v("Search again"),_c('i',{staticClass:"fas fa-search",attrs:{"slot":"icon"},slot:"icon"})])],1)]:[_c('label',{staticClass:"semi-bold",attrs:{"for":"keyword-input"}},[_vm._v("Keyword")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.keywordInput),expression:"keywordInput",modifiers:{"trim":true}}],attrs:{"id":"keyword-input","type":"text","placeholder":"Enter keyword"},domProps:{"value":(_vm.keywordInput)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.keywordInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('span',{staticClass:"search-icon",on:{"click":_vm.search}},[_c('i',{staticClass:"fas fa-search"})])]],(_vm.loading)?_c('loading'):(_vm.hitCount)?[_c('hr',{attrs:{"id":"result-separator"}}),_c('div',{staticClass:"result-count first"},[_vm._v("\n          "+_vm._s(((_vm.page - 1) * _vm.pageSize + 1).toLocaleString())+"-"+_vm._s(_vm.page * _vm.pageSize > _vm.hitCount
              ? _vm.hitCount.toLocaleString()
              : (_vm.page * _vm.pageSize).toLocaleString())+"\n          of\n          "),_c('span',{staticClass:"semi-bold"},[_vm._v(_vm._s(_vm.hitCount.toLocaleString()))]),_vm._v("\n          results\n          "),(_vm.hitCount > _vm.pageSize)?_c('pagination',{attrs:{"id":"top-pagination","total-size":_vm.hitCount,"page-size":_vm.pageSize,"max-number-of-displayed-pages":5,"current-page":+_vm.page},on:{"onPageEntered":_vm.onPageEntered}}):_vm._e()],1),_c('list',{attrs:{"list":_vm.grants,"separator-below":true},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return _c('grant-outline',{attrs:{"grant":item,"cat":_vm.cat}})}}])})]:_c('notification',[_vm._v("\n        There are no grants matching your search.\n      ")]),(_vm.hitCount && !_vm.loading)?_c('div',{staticClass:"result-count"},[_vm._v("\n        "+_vm._s(((_vm.page - 1) * _vm.pageSize + 1).toLocaleString())+"-"+_vm._s(_vm.page * _vm.pageSize > _vm.hitCount
            ? _vm.hitCount.toLocaleString()
            : (_vm.page * _vm.pageSize).toLocaleString())+"\n        of\n        "),_c('span',{staticClass:"semi-bold"},[_vm._v(_vm._s(_vm.hitCount.toLocaleString()))]),_vm._v("\n        results\n        "),(_vm.hitCount > _vm.pageSize)?_c('pagination',{attrs:{"id":"bottom-pagination","total-size":_vm.hitCount,"page-size":_vm.pageSize,"max-number-of-displayed-pages":5,"current-page":+_vm.page},on:{"onPageEntered":_vm.onPageEntered}}):_vm._e()],1):_vm._e()],2)],1),(_vm.showExport)?_c('modal',{on:{"close":_vm.closeExport}},[_c('template',{slot:"title"},[_vm._v("Export grants")]),_c('b',[_vm._v(_vm._s(_vm.hitCount))]),_vm._v(" grant\n    "+_vm._s(_vm.hitCount === 1 ? 'record' : 'records')+" are available to export\n    "),_c('div',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.exportFormat),expression:"exportFormat"}],attrs:{"type":"radio","value":"csv","disabled":_vm.downloadLoading},domProps:{"checked":_vm._q(_vm.exportFormat,"csv")},on:{"change":function($event){_vm.exportFormat="csv"}}}),_vm._v("CSV (comma separated - excel)")])]),_c('div',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.exportFormat),expression:"exportFormat"}],attrs:{"type":"radio","value":"xml","disabled":_vm.downloadLoading},domProps:{"checked":_vm._q(_vm.exportFormat,"xml")},on:{"change":function($event){_vm.exportFormat="xml"}}}),_vm._v("XML")])]),_c('div',[_c('button',{staticClass:"download-btn",attrs:{"disabled":_vm.downloadLoading},on:{"click":function($event){$event.preventDefault();return _vm.download.apply(null, arguments)}}},[_vm._v("\n        Download\n      ")])]),(_vm.downloadLoading)?_c('notification',[_vm._v("\n      The downloading may take from a few seconds to a few minutes.\n    ")]):_vm._e(),(_vm.downloadLoading)?_c('loading'):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('br')])
}]

export { render, staticRenderFns }