import { send } from '@/api'

// xml already ready
export function transformXml(xml, format) {
  const req = '/xsl/' + format + '.xsl'
  return new Promise((resolve) =>
    send(req).then((xsl) => {
      let resultDocument
      if (typeof XSLTProcessor !== 'undefined') {
        const xsltProcessor = new XSLTProcessor()
        xsltProcessor.importStylesheet(xsl)
        resultDocument = xsltProcessor.transformToFragment(xml, document)
      } else if (typeof xml.transformNode !== 'undefined') {
        resultDocument = xml.transformNode(xsl)
      }
      resolve(resultDocument)
    })
  )
}
