import { fetchArticles, fetchScite } from '@/api'
import { addScript } from '@/helpers/load-script-and-style'

const namespaced = true

const getDefaultState = () => {
  return {
    abstract: null,
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  setAbstractTitle(state, title) {
    state.abstract.title = title
  },
}

const actions = {
  loadAbstract({ state, rootState, dispatch }, params) {
    return fetchArticles({
      articles: [
        {
          id: params.id,
          source: params.source,
        },
      ],
      resultType: 'core',
    }).then(async (response) => {
      const result = response.resultList.result
      if (result.length !== 0) {
        const abstract = result[0]
        state.abstract = abstract

        if (abstract.abstractText) {
          abstract.abstractText = abstract.abstractText.replace(
            /<(?=[^>]*(?:<|$))/g,
            '&lt'
          )
        }

        if (
          (abstract.abstractText &&
            /\$\\([^$]+)\$/.test(abstract.abstractText)) ||
          abstract.title.indexOf('$$') > -1
        ) {
          addScript({
            src: 'https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js',
          })
          abstract.containsLatex = true
        }
        abstract.botRequest = params.botRequest

        abstract.hasFulltext =
          abstract.inEPMC === 'Y' ||
          abstract.hasBook === 'Y' ||
          abstract.source === 'NBK'

        abstract.hasDataLinks =
          abstract.hasData === 'Y' ||
          abstract.hasDbCrossReferences === 'Y' ||
          abstract.hasLabsLinks === 'Y' ||
          abstract.hasTextMinedTerms === 'Y'

        const { evaluations, fullTextLinks } = rootState.article
        const hasEvaluations = await getEvaluations(
          abstract,
          evaluations,
          dispatch
        )
        const hasVersionEvaluations =
          abstract.versionList &&
          abstract.versionList.version.some(
            (version) => version.hasEvaluations === 'Y'
          )

        if (abstract.doi) {
          const temp = document.createElement('div')
          temp.innerHTML = abstract.doi
          abstract.doi = temp.innerText
        }

        // for new author affiliation change in the web service
        if (abstract.authorList) {
          abstract.authorList.author.forEach((a) => {
            a.affiliation =
              a.authorAffiliationDetailsList &&
              a.authorAffiliationDetailsList.authorAffiliation[0].affiliation
            if (a.affiliation) {
              a.affiliation = a.affiliation.trim()
            }
          })
        }

        // fetch Scite
        if (abstract.doi) {
          fetchScite(abstract.doi).then((response) => {
            if (response && response.total) {
              abstract.scite = response
            }
          })
        }

        // add commentCorrectionList
        abstract.commentCorrectionList = abstract.commentCorrectionList || {
          commentCorrection: [],
        }
        const {
          pubTypeList,
          commentCorrectionList,
          doi,
          fullTextUrlList,
          versionList,
        } = abstract
        const { commentCorrection } = commentCorrectionList

        const isPreprintRemoval =
          pubTypeList &&
          pubTypeList.pubType.find(
            (type) => type.toLowerCase() === 'preprint-removal'
          )
        const isPreprintWithdrawal =
          pubTypeList &&
          pubTypeList.pubType.find(
            (type) => type.toLowerCase() === 'preprint-withdrawal'
          )
        const getLastVersion = (type) =>
          versionList &&
          versionList.version
            .reverse()
            .find(
              (version) =>
                version.pubTypeList &&
                version.pubTypeList.pubType.some(
                  (pubType) => pubType.toLowerCase() === type.toLowerCase()
                )
            )
        const getVersions = (type) =>
          versionList &&
          versionList.version
            .filter(
              (version) =>
                version.pubTypeList &&
                version.pubTypeList.pubType.some(
                  (pubType) => pubType.toLowerCase() === type.toLowerCase()
                )
            )
            .map((item) => item.versionNumber)
        const lastPreprintRemovedVersion = getLastVersion('preprint-removal')
        const lastPreprintWithdrawnVersion = getLastVersion(
          'preprint-withdrawal'
        )
        const withdrawnVersions = getVersions('preprint-withdrawal')
        const removedVersions = getVersions('preprint-removal')
        const journalData = commentCorrection.find(
          (comment) => comment.type === 'Preprint of'
        )

        if (isPreprintRemoval) {
          commentCorrection.push({
            ...journalData,
            type: 'Preprint removal',
            version: lastPreprintRemovedVersion,
            removedVersions,
          })
        } else if (lastPreprintRemovedVersion) {
          commentCorrection.push({
            ...journalData,
            type: 'Preprint removal',
            version: lastPreprintRemovedVersion,
            removedVersions,
          })
        } else if (isPreprintWithdrawal) {
          let url = ''
          const doiUrl = fullTextUrlList.fullTextUrl.find(
            (url) => url.site === 'DOI'
          )
          if (doiUrl) {
            url = doiUrl.url
          } else {
            const arXivUrl = fullTextUrlList.fullTextUrl.find(
              (url) => url.site === 'arXiv'
            )
            if (arXivUrl) {
              url = arXivUrl.url
            }
          }
          commentCorrection.push({
            ...journalData,
            type: 'Preprint withdrawal',
            version: lastPreprintWithdrawnVersion,
            withdrawnVersions,
            url,
          })
        } else if (lastPreprintWithdrawnVersion) {
          commentCorrection.push({
            ...journalData,
            type: 'Preprint withdrawal',
            version: lastPreprintWithdrawnVersion,
            withdrawnVersions,
          })
        } else if (!journalData && abstract.source === 'PPR') {
          // withdrawl/removal - N ; Journal - N
          if (
            //open research - Y
            doi &&
            ['10.12688', '10.3310', '10.35241', '10.15694', '10.1099'].find(
              (pattern) => doi.startsWith(pattern)
            )
          ) {
            commentCorrection.push({
              type: 'Preprint under review',
              doi: abstract.doi,
            })
          } else {
            // open research - N
            if (!hasEvaluations && !hasVersionEvaluations) {
              // reviews - N
              commentCorrection.push({
                type: 'Preprint not reviewed',
              })
            } else {
              // reviews - Y
              commentCorrection.push({
                type: 'Evaluations',
                version: !hasEvaluations,
              })
            }
          }
        }
        if (abstract.source === 'HIR') {
          commentCorrection.push({
            type: 'HIR',
          })
        }

        if (
          journalData &&
          (isPreprintRemoval ||
            isPreprintWithdrawal ||
            lastPreprintRemovedVersion ||
            lastPreprintWithdrawnVersion)
        ) {
          commentCorrectionList.commentCorrection = commentCorrection.filter(
            (comment) => comment.type !== 'Preprint of'
          )
        }

        const no_links = [
          'NCBI_Bookshelf',
          'PubMedCentral',
          'Europe_PMC',
          'DOI',
        ]
        if (abstract.fullTextUrlList) {
          const fullTextUrl = abstract.fullTextUrlList.fullTextUrl.filter(
            (link) =>
              no_links.indexOf(link.site) < 0 && link.documentStyle !== 'doi'
          )
          fullTextLinks.fullTextLinks = fullTextLinks.fullTextLinks.concat(
            fullTextUrl || []
          )
        }
      }
    })
  },
  updateAbstractTitle({ commit }, params) {
    commit('setAbstractTitle', params.title)
  },
}

export default { namespaced, state, mutations, actions }

async function getEvaluations(abstract, evaluations, dispatch) {
  if (
    abstract.hasEvaluations &&
    abstract.hasEvaluations.toLowerCase() === 'y' &&
    !Object.keys(evaluations.evaluationList.evaluationsGroup).length
  ) {
    await dispatch('article/evaluations/loadEvaluations', null, {
      root: true,
    })
  }
  const hasEvaluations = evaluations.evaluationList.length > 0
  return hasEvaluations
}
