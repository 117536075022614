import { EPMC_ROOT, EPMC_API_GET_ROOT } from '@/config'
import { formatDate } from 'epmc-patterns/helpers'
import { send, encodeQuery, fetchSearchResults, fetchYearlyCounts } from '@/api'

// used for grant finder
export async function fetchAllGrants(params) {
  const { hitCount, pageSize } = params
  const pageTotal = Math.ceil(hitCount / pageSize)
  const maxPageOnce = 500

  let response = []
  // if only 1 time, stop = 1
  for (let i = 0; i < Math.ceil(pageTotal / maxPageOnce); i++) {
    const promises = []
    // if only 1 time, stop = pageTotal
    const stop =
      pageTotal > maxPageOnce + maxPageOnce * i
        ? maxPageOnce + maxPageOnce * i
        : pageTotal
    // if only 1 time, page = 1
    for (let page = 1 + maxPageOnce * i; page <= stop; page++) {
      promises.push(fetchGrants(Object.assign({ page }, params)))
    }
    response = response.concat(await Promise.all(promises))
  }
  return Promise.resolve(response)
}

export function fetchCitationsByGrantData(params) {
  const { dates, grantAgencies, withOrcid } = params || {}
  let query = 'SRC:"MED"'

  if (grantAgencies) {
    const agencies = grantAgencies
      .map((ga) => 'GRANT_AGENCY:"' + ga + '')
      .join(' OR ')
    query += '+AND+(' + agencies + '")'
  }

  if (withOrcid) {
    query += ' AND (AUTHORID_TYPE:ORCID)'
  }

  if (
    dates &&
    dates.length &&
    (dates[0] !== '' || (dates.length > 1 && dates[1] !== '')) // at least one date is available
  ) {
    query += '+AND+'
    if (dates.length === 1 || dates[1] === '') {
      query += 'FIRST_PDATE:' + dates[0] || '1900-01-01'
    } else {
      query +=
        'FIRST_PDATE:[' + (dates[0] || '1900-01-01') + '+TO+' + dates[1] + ']'
    }
  }

  return fetchSearchResults({ query })
}

export function fetchGrant(gid, ga) {
  return fetchSearchResults({
    query: 'GRANT_AGENCY_ID:"' + gid + '_agency_' + ga + '"',
    resultType: 'idlist',
  })
}

// used for grant finder
export function fetchGrants({
  // if query set, will ignore kw - cat
  query,
  // core queries
  kw,
  aff,
  pi,
  pi_id,
  ga,
  gid,
  current,
  cat,
  // other queries
  page = 1,
  resultType = 'core',
  format = 'json',
  grantId,
  funderAgency,
}) {
  let req = EPMC_API_GET_ROOT + 'gristApi'
  if (grantId && funderAgency) {
    req +=
      '?grantAgency=' +
      encodeQuery(funderAgency) +
      '&grantId=' +
      encodeQuery(grantId) +
      '&format=' +
      format
  } else {
    let q = query || ''
    if (!q) {
      const queryArr = []
      if (kw) {
        queryArr.push({ kw })
      }
      if (aff) {
        queryArr.push({ aff })
      }
      if (pi) {
        queryArr.push({ pi })
      }
      if (pi_id) {
        queryArr.push({ pi_id: 'ORCID/' + pi_id })
      }
      if (ga) {
        // there may be multiple ga
        if (typeof ga === 'string') {
          queryArr.push({ ga })
        } else {
          ga.forEach((a) => {
            queryArr.push({ ga: a })
          })
        }
      }
      if (gid) {
        queryArr.push({ gid })
      }
      if (current) {
        queryArr.push({ active_date: formatDate(new Date(), 'system') })
      }
      if (cat) {
        queryArr.push({ cat })
      }
      q += queryArr.length
        ? queryArr
            .map((kv) => {
              const k = Object.keys(kv)[0]
              return k + ':' + '"' + kv[k] + '"'
            })
            .join(' ')
        : ''
    }
    q = encodeQuery(q).replace(/%5C/g, '%255C')

    // == should be used instead of ===
    req +=
      '?query=' +
      q +
      (page == 1 ? '' : '&page=' + page) +
      '&format=' +
      format +
      '&resultType=' +
      resultType
  }

  return send(req)
}

// type is one of pi, institution and grant
export function fetchGristSuggest(type, text) {
  const req = EPMC_ROOT + 'grist/rest/suggest/' + type + '/' + text
  return send(req)
}

export function fetchYearlyCitationsByGrantData(grantAgency) {
  const query =
    EPMC_API_GET_ROOT + 'FunderYearlyCitations?grant_agency=' + grantAgency
  return send(query)
}

export function fetchYearlyCountsByGrantData(params) {
  // grantAgency and type are required; type is one of abstractOnly, hasFulltext, isOpenAccess
  const { dates, grantAgencies, type, hasData, hasPreprint } = params

  let query =
    'SRC:"MED"+AND+(' +
    grantAgencies.map((ga) => 'GRANT_AGENCY:"' + ga + '').join(' OR ') +
    '")'

  if (type === 'abstractOnly') {
    query += '+AND+(IN_EPMC:N)'
  } else if (type === 'hasFulltext' || type === 'isOpenAccess') {
    query += '+AND+(IN_EPMC:Y)'
    if (type === 'isOpenAccess') {
      query += '+AND+(LICENSE:"CC-BY" OR LICENSE:"CC-BY-SA" OR LICENSE:"CC0")'
    }
  }

  if (hasData) {
    query += '+AND+(HAS_DATA:Y)'
  } else if (hasData !== undefined) {
    query += '+AND+(HAS_DATA:N)'
  }

  if (hasPreprint) {
    query += '+AND+(HAS_PREPRINT:Y)'
  } else if (hasPreprint !== undefined) {
    query += '+AND+(HAS_PREPRINT:N)'
  }

  if (
    dates &&
    dates.length &&
    (dates[0] !== '' || (dates.length > 1 && dates[1] !== '')) // at least one date is available
  ) {
    const currentYear = new Date().getFullYear()
    query +=
      '+AND+FIRST_PDATE:[' +
      (dates[0] || '1900-01-01') +
      '+TO+' +
      (dates[1] || currentYear + 1 + '-12-31') +
      ']'
  }

  return fetchYearlyCounts(query)
}
