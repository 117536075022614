var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"downloads-page"}},[_c('div',{staticClass:"grid-row"},[_c('div',{staticClass:"col-11"},[_c('h1',[_vm._v("Bulk downloads")]),_c('p',[_vm._v("\n        There are eight types of Europe PMC content available for automated,\n        bulk download:\n      ")]),_c('p',[_c('a',{attrs:{"href":"/downloads/openaccess"}},[_vm._v("Open access subset")]),_c('br'),_vm._v("\n        Nearly\n        "),_c('a',{attrs:{"href":'/search?query=' + _vm.oaCount.query}},[_vm._v(_vm._s(_vm.oaCount.count))]),_vm._v("\n        full text open access articles available to download using the Europe\n        PMC web services or FTP site.\n      ")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/downloads/manuscripts"}},[_vm._v("Author manuscript collection")]),_c('br'),_vm._v("\n        Author manuscripts uploaded by authors to PubMed Central and Europe\n        PMC, to comply with funders’ Open Access policies, available in XML\n        and Text format via FTP.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/downloads/preprints"}},[_vm._v("Preprints subset")]),_c('br'),_vm._v("\n        Preprint abstracts or full text, open access preprint articles,\n        available to download using the Europe PMC web services or in XML\n        format via FTP.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"//europepmc.org/ftp/pmclitemetadata/"}},[_vm._v("Metadata of all Full-Text Europe PMC articles")]),_c('br'),_vm._v("\n        This FTP directory contains XML for key metadata for all articles\n        available from Europe PMC (metadata is extracted using the\n        "),_c('a',{attrs:{"href":"/RestfulWebService#result-type"}},[_vm._v("lite response")]),_vm._v(" of the\n        Europe PMC API). It is updated weekly.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"//europepmc.org/ftp/DOI_mappings"}},[_vm._v("PMID-PMCID-DOI mappings")]),_c('br'),_vm._v("\n        A file of PMID-PMCID-DOI mappings, or partial mappings, available via\n        FTP. All PMIDs and PMCIDs are included, with the other two IDs shown\n        where available. The file is overwritten on the first of the month.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"//europepmc.org/ftp/TextMinedTerms"}},[_vm._v("Accession numbers in Europe PMC articles")]),_c('br'),_vm._v("\n        This FTP directory contains database accession numbers found in Europe\n        PMC articles, using text mining. It is updated weekly.\n      ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"//europepmc.org/ftp/ref_check"}},[_vm._v("Deposition dates for author manuscripts")]),_c('br'),_vm._v("\n        A CSV file of deposition dates and other citation details of author\n        manuscripts deposited in Europe PMC via the Europe PMC Plus submission\n        system, for the purposes of HEFCE REF compliance checking. The file is\n        updated monthly.\n      ")])
}]

export { render, staticRenderFns }