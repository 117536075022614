const namespaced = true

const state = {
  searchParams: {},
}

const mutations = {
  changeLastSearch(state, searchParams) {
    state.searchParams = searchParams
  },
}

export default {
  namespaced,
  state,
  mutations,
}
