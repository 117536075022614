import { isBeta } from './sessionStorage'
import { PLACES_TO_SEARCH, ARTICLE_SECTION_OPTIONS } from '@/config'

const productionSearchPath = 'search'
const betaSearchPath = 'betaSearch'

const placesToSearch = PLACES_TO_SEARCH
const articleSectionOptions = ARTICLE_SECTION_OPTIONS

export function isValidSearchPath(path) {
  return path === productionSearchPath || path === betaSearchPath
}

export function getProductionSearchPath() {
  return productionSearchPath
}

export function getBetaSearchPath() {
  return betaSearchPath
}

export function getSearchPath() {
  return isBeta() ? betaSearchPath : productionSearchPath
}

export function constructHtmlForQuery(item) {
  const { query, term, id } = item
  let html = query
  if (html.includes('TITLE_ABS:')) {
    html = html.replace('TITLE_ABS:', createSpanStyle('TITLE_ABS'))
  } else
    placesToSearch.map(({ value }) => {
      if (value && html.includes(value)) {
        html = html.replace(`${value}:`, createSpanStyle(value))
      }
    })
  articleSectionOptions.map(({ value }) => {
    if (html.includes(value)) {
      html = html.replace(`${value}:`, createSpanStyle(value))
    }
  })
  html = html.replaceAll(
    `(${term})`,
    `<span>(<span data-internalid=${id}>${term}</span>)</span>`
  )
  return html
}

function createSpanStyle(value) {
  return `<span style='color: #969696;'>${value}:</span>`
}
