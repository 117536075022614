export const SEARCH_FIELDS = [
  'ABBR',
  'ABSTRACT',
  'ACCESSION_ID',
  'ACCESSION_TYPE',
  'ACK_FUND',
  'AFF',
  'ANNOTATION_PROVIDER',
  'ANNOTATION_TYPE',
  'APPENDIX',
  'ARXPR_PUBS',
  'AUTH',
  'AUTHORID',
  'AUTHORID_TYPE',
  'AUTHOR_ROLES',
  'AUTH_CON',
  'AUTH_FIRST',
  'AUTH_LAST',
  'AUTH_MAN',
  'AUTH_MAN_ID',
  'BACK',
  'BACK_NOREF',
  'BODY',
  'BOOK_ID',
  'CASE',
  'CHEBITERM',
  'CHEBITERM_ID',
  'CHEBI_PUBS',
  'CHEM',
  'CHEMBL_PUBS',
  'CITED',
  'CITES',
  'COMP_INT',
  'CONCL',
  'CRD_LINKS',
  'CREATION_DATE',
  'DATA_AVAILABILITY',
  'DISCUSS',
  'DISEASE',
  'DISEASE_ID',
  'DOI',
  'ED',
  'EMBARGO_DATE',
  'EMBL_PUBS',
  'EPMC_AUTH_MAN',
  'ESSN',
  'EXPERIMENTAL_METHOD',
  'EXPERIMENTAL_METHOD_ID',
  'EXT_ID',
  'E_PDATE',
  'FIG',
  'FIRST_IDATE',
  'FIRST_IDATE_D',
  'FIRST_PDATE',
  'FT_CDATE',
  'FT_CDATE_D',
  'FULLTEXT_SITE',
  'GENE_PROTEIN',
  'GOTERM',
  'GOTERM_ID',
  'GRANT_AGENCY',
  'GRANT_AGENCY_ID',
  'GRANT_ID',
  'HAS_ARXPR',
  'HAS_BOOK',
  'HAS_CHEBI',
  'HAS_CHEMBL',
  'HAS_CRD',
  'HAS_DATA',
  'HAS_DOI',
  'HAS_EMBL',
  'HAS_FT',
  'HAS_FULLTEXT',
  'HAS_FULLTEXTDATA',
  'HAS_INTACT',
  'HAS_INTERPRO',
  'HAS_LABSLINKS',
  'HAS_OMIM',
  'HAS_PDB',
  'HAS_PDF',
  'HAS_PREPRINT',
  'HAS_PRIDE',
  'HAS_PUBLISHED_VERSION',
  'HAS_REFLIST',
  'HAS_SUPPL',
  'HAS_TM',
  'HAS_UNIPROT',
  'HAS_XREFS',
  'INDEX_DATE',
  'INTACT_PUBS',
  'INTERPRO_PUBS',
  'INTRO',
  'INVESTIGATOR',
  'IN_EPMC',
  'IN_PMC',
  'ISBN',
  'ISSN',
  'ISSUE',
  'IS_SCANNED',
  'JOURNAL',
  'JOURNAL_ID',
  'JRNL_ISS_ID',
  'KEYWORD', //
  'KW',
  'LABS_PUBS',
  'LANG',
  'LICENSE',
  'LPAGE',
  'METHODS',
  'NIH_AUTH_MAN',
  'OMIM_PUBS',
  'OMIM_TYPE',
  'OPEN_ACCESS',
  'ORGANISM',
  'ORGANISM_ID',
  'ORG_ID',
  'OTHER', //
  'PARENT_TITLE', //,
  'PATENTS',
  'PDB_PUBS',
  'PDF',
  'PMCID',
  'PMC_DOI',
  'PRIDE_PUBS',
  'PUBDATE',
  'PUBLISHER',
  'PUB_TYPE',
  'PUB_YEAR',
  'P_PDATE',
  'QN1',
  'QN2',
  'REF',
  'REFFED_BY',
  'RESOURCE_NAME',
  'RESULTS',
  'SB',
  'SERIES_NAME',
  'SHARD',
  'SPAGE',
  'SRC',
  'SUPPL',
  'TABLE',
  'TITLE',
  'UNIPROT_PUBS',
  'UPDATE_DATE',
  'VOLUME',
  '_version_',
  'text_hl',
  'text_synonyms',
]
