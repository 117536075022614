import { render, staticRenderFns } from "./GrantDetails.vue?vue&type=template&id=83c9524a&scoped=true"
import script from "./GrantDetails.vue?vue&type=script&lang=js"
export * from "./GrantDetails.vue?vue&type=script&lang=js"
import style0 from "./GrantDetails.vue?vue&type=style&index=0&id=83c9524a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83c9524a",
  null
  
)

export default component.exports