import { EPMC_ROOT, EPMC_API_GET_ROOT } from '@/config'
import { send } from '@/api'

export function saveRedirectTarget(target) {
  const req = EPMC_API_GET_ROOT + 'redirection?' + target
  const method = target ? 'GET' : 'DELETE'
  return send(req, method)
}

export function saveSearch(name, query, numberOfResults) {
  const req = EPMC_ROOT + 'rest/save/search'
  return send(req, 'POST', { name, query, numberOfResults })
}
