<script>
import { FUNDERS } from '@/config'
import { fetchGrants, fetchSearchResults } from '@/api'
import { getDate } from 'epmc-patterns/helpers'
import { getAllPubLink, getAllFTLink, getOrcid } from '@/helpers/grant-finder'
import { Action, ContentSection, Loading } from 'epmc-patterns/components/v2'
import { formatDoi } from '@/helpers/doi'

export default {
  metaInfo() {
    const { grant } = this

    return {
      title: grant ? grant['Title'] : '',
      titleTemplate: '%s - Grant details - Europe PMC',
      meta: [
        {
          name: 'description',
          content: grant ? grant['Title'] : '',
        },
      ],
    }
  },
  components: { Action, ContentSection, Loading },
  props: {
    query: {
      type: String,
      default: '',
    },
    cat: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      back: false,
      person: null,
      grant: null,
      institution: null,
      allPubCount: 0,
      ftCount: 0,
    }
  },
  computed: {
    personLink() {
      const { person, cat } = this
      return {
        name: 'grantSearch',
        query: {
          pi:
            (person['Title'] ? '{' + person['Title'] + '}' : '{}') +
            '{' +
            person['FamilyName'] +
            '}' +
            (person['GivenName'] ? '{' + person['GivenName'] + '}' : '{}') +
            (person['Initials'] ? '{' + person['Initials'] + '}' : '{}'),
          cat,
        },
      }
    },
    affLink() {
      const { institution, cat } = this
      return {
        name: 'grantSearch',
        query: { aff: institution['Name'], cat },
      }
    },
    orcid() {
      return getOrcid(this.person)
    },
    imageWidth() {
      return FUNDERS.find((f) => f.name === this.grant['Funder']['Name'])
        .imageWidth
    },
    imageHref() {
      return FUNDERS.find((f) => f.name === this.grant['Funder']['Name']).url
    },
    imageSrc() {
      const imageUrl = FUNDERS.find(
        (f) => f.name === this.grant['Funder']['Name']
      ).imageUrl
      return imageUrl
        ? require('@/assets/funders_logos/' +
            FUNDERS.find((f) => f.name === this.grant['Funder']['Name'])
              .imageUrl)
        : ''
    },
    funderLink() {
      const { grant, cat } = this
      return {
        name: 'grantSearch',
        query: {
          ga: FUNDERS.find((f) => f.name === grant['Funder']['Name']).name,
          cat,
        },
      }
    },
    amount() {
      const { grant } = this
      let unit = '',
        amount = ''

      if (grant['Amount']) {
        const currency = grant['Amount']['@Currency']
        if (currency === 'GBP') {
          unit = '£'
        } else if (currency === 'USD') {
          unit = '$'
        } else if (currency === 'EUR') {
          unit = '€'
        } else {
          unit = currency
        }
        amount = Math.floor(grant['Amount']['$']).toLocaleString()
      }
      return unit + ' ' + amount
    },
    allPubLink() {
      return getAllPubLink(this.grant)
    },
    allFTLink() {
      return getAllFTLink(this.grant)
    },
    formatDoi() {
      return formatDoi(this.grant['Doi'])
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from && from.name === 'grantSearch') {
        vm.back = true
      }
    })
  },
  created() {
    const { query } = this

    //a hacky way to populate the search query into the search bar
    const searchBarElm = document.getElementById('banner--search-input')
    if (searchBarElm) {
      const value = searchBarElm.value.trim()
      if (value === query) {
        searchBarElm.value = ''
      }
    }

    fetchGrants({ query }).then((response) => {
      if (response.HitCount) {
        const record = response.RecordList.Record.length
          ? response.RecordList.Record[0]
          : response.RecordList.Record
        this.person = record.Person
        this.grant = record.Grant
        this.institution = record.Institution

        this.loading = false

        fetchSearchResults({
          query: this.allPubLink,
        }).then((res) => {
          this.allPubCount = res.hitCount
        })

        fetchSearchResults({
          query: this.allFTLink,
        }).then((res) => {
          this.ftCount = res.hitCount
        })
      }
    })
  },
  methods: {
    getDate,
  },
}
</script>
<template>
  <div id="grant-details-page">
    <loading v-if="loading" />
    <div v-else class="grid-row">
      <div class="col-4">
        <template v-if="grant['Funder'] && grant['Funder']['Name']">
          <div v-if="imageSrc">
            <a :href="imageHref">
              <img
                class="funder-image"
                :src="imageSrc"
                :style="{ width: imageWidth + 'px' }"
                :alt="grant['Funder']['Name']"
              />
            </a>
          </div>

          <h4>Funded by</h4>
          <div>
            <router-link :to="funderLink" aria-label="Funder name">
              {{ grant['Funder']['Name'] }}
            </router-link>
          </div>
        </template>

        <div v-if="amount" class="feature-small semi-bold amount">
          {{ amount }}
        </div>

        <template v-if="grant['StartDate'] || grant['EndDate']">
          <h4>Duration</h4>
          <div>
            {{ grant['StartDate'] ? getDate(grant['StartDate']) : ''
            }}{{ grant['StartDate'] && grant['EndDate'] ? ' - ' : ''
            }}{{ grant['EndDate'] ? getDate(grant['EndDate']) : '' }}
          </div>
        </template>

        <template v-if="grant['Id']">
          <h4>Internal grant ID</h4>
          <div>{{ grant['Id'] }}</div>
        </template>

        <template v-if="grant['Doi']">
          <h4>Grant DOI</h4>
          <div>
            <a :href="formatDoi" target="_blank">{{ formatDoi }}</a>
          </div>
        </template>

        <template v-if="grant['Stream']">
          <h4>Funding stream</h4>
          <div>{{ grant['Stream'] }}</div>
        </template>

        <template v-if="grant['Type']">
          <h4>Grant type</h4>
          <div>{{ grant['Type'] }}</div>
        </template>

        <h4>Publications</h4>
        <template v-if="allPubCount || ftCount">
          <div v-if="allPubCount">
            <router-link
              :to="{
                name: 'search',
                query: {
                  query: allPubLink,
                },
              }"
            >
              All publications ({{ allPubCount }})
            </router-link>
          </div>
          <div v-if="ftCount">
            <router-link
              :to="{
                name: 'search',
                query: {
                  query: allFTLink,
                },
              }"
            >
              Free to read articles ({{ ftCount }})
            </router-link>
          </div>
        </template>
        <div v-else>No publications available</div>
      </div>
      <div class="col-12">
        <div v-if="back" class="back-action">
          <action @click="$router.go(-1)">
            <i slot="icon" class="fa fa-arrow-left" />Back to search results
          </action>
        </div>
        <h1 v-if="grant['Title']">
          {{ grant['Title'] }}
        </h1>

        <div
          v-if="person['FamilyName'] || institution['Name']"
          class="author-metadata"
        >
          <router-link
            v-if="person['FamilyName']"
            :to="personLink"
            aria-label="Author name"
          >
            {{ person['Title'] || '' }}
            {{ person['FamilyName']
            }}{{
              person['GivenName']
                ? ', ' + person['GivenName']
                : person['Initials']
                ? ', ' + person['Initials']
                : ''
            }}</router-link
          >
          <template v-if="orcid">
            {{ person['FamilyName'] ? ' | ' : '' }}
            <router-link
              :to="{ name: 'grantSearch', query: { pi_id: orcid, cat } }"
              aria-label="ORCID"
            >
              ORCID: {{ orcid }}
            </router-link>
          </template>
          <template v-if="institution && institution['Name']">
            {{ orcid || person['FamilyName'] ? ' | ' : '' }}
            <router-link :to="affLink" aria-label="Author institution">
              {{ institution['Name'] }}
            </router-link>
          </template>
        </div>

        <div v-if="orcid" class="author-profile">
          <action
            :to="{
              name: 'authorProfile',
              params: { orcid },
            }"
            itemprop="mainEntityOfPage"
          >
            <i slot="icon" class="fas fa-user" />Author profile
          </action>
        </div>

        <content-section
          :section="{
            id: 'grant-abstract',
            heading: 'h2',
            title: 'Abstract',
          }"
        >
          {{
            grant['Abstract'] && grant['Abstract']['$']
              ? grant['Abstract']['$']
              : grant['Abstract'] && grant['Abstract'].length
              ? grant['Abstract'][0]['$']
              : 'Abstract not provided.'
          }}
        </content-section>
        <content-section
          v-if="grant['Abstract'] && grant['Abstract'].length > 1"
          :section="{
            id: 'grant-lay-abstract',
            heading: 'h2',
            title: 'Lay abstract',
          }"
        >
          {{ grant['Abstract'][1]['$'] }}
        </content-section>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#grant-details-page {
  .col-12 {
    padding: 0 $base-unit * 5;
    .back-action {
      margin-bottom: $base-unit * 4;
    }
    .author-metadata {
      margin-top: $base-unit * 5;
    }
    .author-profile {
      .action:first-child {
        margin-right: $base-unit * 2;
      }
    }
  }
  .col-4 {
    .funder-image {
      max-width: 100% !important;
    }
    h4 {
      margin-bottom: 0;
    }
    .amount {
      margin-top: $base-unit * 6;
    }
  }
}
</style>
