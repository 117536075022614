import { fetchEvaluations } from '@/api'

const namespaced = true

const getDefaultState = () => {
  return {
    evaluationList: {
      length: 0,
      evaluationsGroup: [],
    },
    promise: null,
  }
}

const state = getDefaultState()
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async loadEvaluations({ state, rootState }) {
    const abstract = rootState.article.abstract.abstract
    if (!state.promise) {
      state.promise = fetchEvaluations(abstract.source, abstract.id, {})
    }
    return state.promise.then((response) => {
      if (
        response.evaluationList &&
        response.evaluationList.evaluation &&
        response.evaluationList.evaluation.length > 0
      ) {
        //filter out evaluations which does not contain url
        const evalutions = response.evaluationList.evaluation.filter(
          (item) => item.url && item.url.trim() !== ''
        )
        state.evaluationList.length = evalutions.length
        //group evaluations by platform/provider
        const groupedEvaluations = evalutions.reduce((groups, evaluation) => {
          if (!groups[evaluation.platform]) {
            groups[evaluation.platform] = { evaluations: [], logoUrl: null }
          }
          groups[evaluation.platform].evaluations.push(evaluation)
          if (!groups[evaluation.platform].logoUrl && evaluation.logoUrl) {
            groups[evaluation.platform].logoUrl = evaluation.logoUrl
          }
          return groups
        }, {})
        const sortedAndRankedGroups = Object.keys(groupedEvaluations)
          .map((platform) => {
            const group = groupedEvaluations[platform]
            //individual reviews will be ordered by date within the provider group. The least recent date will be on top, and the most recent date on the bottom of the list
            //if there is no evaluation date: place it at the bottom of the list
            const sortedEvaluations = customSort(group.evaluations)
            // Find the latest evaluation date in this group to aid sorting groups with latest evaluation date to be at top and vice versa
            const latestEvaluationDate =
              sortedEvaluations.length > 0
                ? new Date(
                    sortedEvaluations[sortedEvaluations.length - 1]
                      .evaluationDate || 0
                  )
                : null
            return {
              platform,
              logoUrl: group.logoUrl,
              evaluations: sortedEvaluations,
              latestEvaluationDate,
            }
          })
          .sort((a, b) => {
            //sort provider groups with most recent review at the top of the section, and the provider with the second more recent review will show second, and so on and so on
            const latestEvaluationDateA = a.latestEvaluationDate || new Date(0)
            const latestEvaluationDateB = b.latestEvaluationDate || new Date(0)
            return latestEvaluationDateB - latestEvaluationDateA
          })
        state.evaluationList.evaluationsGroup = sortedAndRankedGroups
      }
    })
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
}

function customSort(evaluations) {
  // place items without eveluationDate as the last items in the sorted group
  return evaluations.sort((a, b) => {
    const dateA = a.evaluationDate
    const dateB = b.evaluationDate

    if (!dateA && !dateB) return 0
    if (!dateA) return 1
    if (!dateB) return -1

    const dateObjA = new Date(dateA)
    const dateObjB = new Date(dateB)

    if (dateObjA < dateObjB) return -1
    if (dateObjA > dateObjB) return 1
    return 0
  })
}
