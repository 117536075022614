import { send } from '@/api'
import { getDate } from 'epmc-patterns/helpers'
import TextAnnotator from 'text-annotator'

export async function fetchBlog({ id = '', snippetPos = [1, 3], number = 3 }) {
  const req = 'https://blog.europepmc.org/wp-json/wp/v2/posts/' + id
  const response = await send(req)
  if (response && response != null)
    return response.length > 0
      ? response
          .slice(0, number)
          .map((item) => formatResponseFromBlog(item, snippetPos))
      : formatResponseFromBlog(response, snippetPos)
}

export async function fetchTechBlog({ path, snippetPos = [0, 2] }) {
  const req =
    'https://api.github.com/repos/EuropePMC/techblog/contents/_posts/' + path
  const response = await send(req)
  const data = atob(response.content)
  const lines = data.split('\n')
  const metaDataLineIndexs = [lines.indexOf('---'), lines.lastIndexOf('---')]
  const mataDataLines = lines.slice(
    metaDataLineIndexs[0] + 1,
    metaDataLineIndexs[1] - metaDataLineIndexs[0]
  )
  const techblogUrl = 'https://europepmc.github.io/techblog/'

  return {
    type: 'Europe PMC tech blog'.toUpperCase(),
    date: getDate(path.split('-').slice(0, 3).join('-')).toUpperCase(),
    title: mataDataLines
      .find((line) => line.startsWith('title:'))
      .replace('title:', '')
      .replace(/"/g, '')
      .trim(),
    snippet: getSnippet(
      lines
        .slice(metaDataLineIndexs[1] + 1, lines.length)
        .filter((line) => !line.startsWith('#'))
        .join('\n')
        .replace(/\[(.+)\]\([^)]+\)/g, '$1'),
      snippetPos
    ),
    readMoreLink:
      techblogUrl +
      mataDataLines
        .find((line) => line.startsWith('category:'))
        .replace('category:', '')
        .replace(/"/g, '')
        .trim() +
      '/' +
      path.split('-').slice(0, 3).join('/') +
      '/' +
      path.split('-').slice(3).join('-').replace(/\.md/g, '') +
      '.html',
  }
}

function formatResponseFromBlog(item, snippetPos) {
  return {
    type: 'Europe PMC blog'.toUpperCase(),
    date: getDate(item.date).toUpperCase(),
    title: item.title.rendered,
    snippet: getSnippet(item.content.rendered, snippetPos),
    readMoreLink: item.link,
  }
}

function getSnippet(content, pos) {
  const div = document.createElement('div')
  div.innerHTML = content
  const text = div.innerText
  const sentences = TextAnnotator.sentenize(text).map((s) => s.raw)
  return sentences.slice(...pos).join(' ')
}
