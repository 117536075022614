export const MENUS = [
  {
    category: 'About',
    link: '/About',
    list: [
      {
        text: 'About Europe PMC',
        link: '/About',
      },
      {
        text: 'Funders',
        link: '/Funders',
      },
      {
        text: 'Become a funder',
        link: '/BecomeFunder',
      },
      {
        text: 'Governance',
        link: '/Governance',
      },
      {
        text: 'Roadmap',
        link: '/Roadmap',
      },
      {
        text: 'Outreach',
        link: '/Outreach',
      },
    ],
  },
  {
    category: 'Tools',
    link: '/tools',
    list: [
      {
        text: 'Tools overview',
        link: '/tools',
      },
      {
        text: 'ORCID article claiming',
        link: '/orcid/import',
      },
      {
        text: 'Journal list',
        link: '/journalList',
      },
      {
        text: 'Grant finder',
        link: '/grantfinder',
      },
      {
        text: 'External links service',
        link: '/LabsLink',
      },
      {
        text: 'RSS feeds',
        link: '/RssFeeds',
      },
      {
        text: 'Annotations',
        link: '/Annotations',
      },
      {
        text: 'Annotations submission service',
        link: '/AnnotationsSubmission',
      },
    ],
  },
  {
    category: 'Developers',
    link: '/developers',
    list: [
      {
        text: 'Developer resources',
        link: '/developers',
      },
      {
        text: 'Articles RESTful API',
        link: '/RestfulWebService',
      },
      {
        text: 'Grants RESTful API',
        link: '/GristAPI',
      },
      {
        text: 'API case studies',
        link: '/API-case-studies',
      },
      {
        text: 'SOAP web service',
        link: '/SoapWebServices',
      },
      {
        text: 'Annotations API',
        link: '/AnnotationsApi',
      },
      {
        text: 'OAI service',
        link: '/OaiService',
      },
      {
        text: 'Bulk downloads',
        link: '/downloads',
      },
      {
        text: 'Developers Forum',
        link: '//groups.google.com/a/ebi.ac.uk/forum/#!forum/epmc-webservices',
      },
    ],
  },
  {
    category: 'Help',
    link: '/Help',
    list: [
      {
        text: 'Help using Europe PMC',
        link: '/Help',
      },
      {
        text: 'Search syntax reference',
        link: '/searchsyntax',
      },
      {
        text: 'Contact us',
        link: '/ContactUs',
      },
    ],
  },
  {
    category: 'Contact us',
    link: '/ContactUs',
    list: [
      {
        text: 'Helpdesk',
        link: '/ContactUs',
      },
      {
        text: 'Feedback',
        link: '#',
        onclick:
          "var w = window.open('/feedback?url='+window.location.origin + window.location.pathname + encodeURIComponent(window.location.search), 'ukpmc_feedback', 'scrollbars=no,location=no,menuBar=yes,resizable=yes,status=no,toolbar=yes,width=600,height=800'); try {if (w.blur) w.focus();}catch(ignore){}; return false;",
      },
      {
        text: 'Twitter',
        link: '//twitter.com/EuropePMC_news',
      },
      {
        text: 'Blog',
        link: 'http://blog.europepmc.org/',
      },
      {
        text: 'Tech blog',
        link: '//europepmc.github.io/techblog/',
      },
      {
        text: 'Developer forum',
        link: '//groups.google.com/a/ebi.ac.uk/forum/#!forum/epmc-webservices',
      },
    ],
  },
]
