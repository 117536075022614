<script>
export default {
  metaInfo() {
    return {
      title: 'Data Fields - Developers - Europe PMC',
    }
  },
}
</script>
<template>
  <div id="data-fields-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Grist Data Fields</h1>
        <p>
          The following gives a breakdown of the quantities that constitute the
          Grist database. The import of these quantites is governed by the Grist
          import schema, which can be viewed at
          <a href="/grist-api-schemas/gristImport.xsd"
            >http://europepmc.org/grist-api-schemas/gristImport.xsd</a
          >
        </p>
        <p>
          Multiple &lt;record&gt; elements can be provided in each
          <abbr title="eXtensible Markup Language">xml</abbr> file, though each
          &lt;record&gt; element must contain one
          <a href="#person-field">&lt;person&gt;</a> element, one
          <a href="#grant-field">&lt;grant&gt;</a> element and, optionally, one
          <a href="#institution-field">&lt;institution&gt;</a>
          element, and note that the quantities must be provided in the order
          given below.
        </p>

        <h2 id="person-field">Person</h2>
        <dl>
          <dt>FamilyName</dt>
          <dd>Family name of person, or name of a group.</dd>

          <dt>GivenName [optional]</dt>
          <dd>Given name of person.</dd>

          <dt>Initials [optional]</dt>
          <dd>Initials of person.</dd>

          <dt>Title [optional]</dt>
          <dd>Title, or salutation, of person.</dd>

          <dt>Commendation [optional]</dt>
          <dd>
            Commendation of person.
            <br />
            Permitted values are detailed in the relevant schema file,
            <a href="/grist-api-schemas/acceptedCommendations.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedCommendations.xsd</a
            >.
          </dd>

          <dt>Email</dt>
          <dd>
            Email address of person.
            <br />
            Multiple email addresses can be provided, though only one must be
            marked as <em>For Contact</em>.
          </dd>

          <dt>Type [default = 'PI']</dt>
          <dd>
            Type of person.
            <br />
            Permitted values are detailed in the relevant schema file,
            <a href="/grist-api-schemas/acceptedPersonTypes.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedPersonTypes.xsd</a
            >.
          </dd>

          <dt>Alias [optional]</dt>
          <dd>
            Alias of person, e.g. funder-specific ID, or ORCID.
            <br />
            The <em>Source</em> of the alias must be provided, e.g. BHF, and
            multiple Alias values can be provided, though the
            <em>Source</em> for each must be unique.
            <br />
            Permitted <em>Source</em> values are detailed in the relevant schema
            file,
            <a href="/grist-api-schemas/acceptedAliasSources.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedAliasSources.xsd</a
            >.
          </dd>
        </dl>

        <h2 id="grant-field">Grant</h2>
        <dl>
          <dt>Funder</dt>
          <dd>
            The source of funding of grant.
            <br />
            Permitted values are detailed in the relevant schema file,
            <a href="/grist-api-schemas/acceptedFunders.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedFunders.xsd</a
            >.
            <br />
            [<a href="/GristAPI/funderNames"
              >See a list of funder names used in Grist</a
            >].
          </dd>

          <dt>Id</dt>
          <dd>Name/Id of grant.</dd>

          <dt>Alias [optional]</dt>
          <dd>
            Alternative name/Id of grant.
            <br />
            Multiple aliases can be provided, though each must be unique.
          </dd>

          <dt>Title</dt>
          <dd>Title of grant.</dd>

          <dt>Abstract [optional]</dt>
          <dd>
            Abstract of grant, in a given
            <em>Language</em> and of a given <em>Type</em> [default
            <em>Type</em> is 'scientific'].
            <br />
            Multiple abstracts can be provided, though the combination of
            <em>Language</em> and <em>Type</em> for each must be unique.
            <br />
            Permitted <em>Language</em> values are detailed in the relevant
            schema file,
            <a href="/grist-api-schemas/acceptedLanguages.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedLanguages.xsd</a
            >.
            <br />
            Permitted <em>Type</em> values are detailed in the relevant schema
            file,
            <a href="/grist-api-schemas/acceptedAbstractTypes.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedAbstractTypes.xsd</a
            >.
          </dd>

          <dt>Type [optional]</dt>
          <dd>Type of grant.</dd>

          <dt>Stream [optional]</dt>
          <dd>Stream of grant.</dd>

          <dt>StartDate [optional]</dt>
          <dd>
            Start date of grant.
            <br />
            Must be in the format YYYY-MM-DD, where YYYY is the year, MM is the
            month and DD is the day.
          </dd>

          <dt>EndDate [optional]</dt>
          <dd>
            End date of grant.
            <br />
            Must be in the format YYYY-MM-DD, where YYYY is the year, MM is the
            month and DD is the day.
          </dd>

          <dt>Amount [optional]</dt>
          <dd>
            Amount of grant.
            <br />
            The <em>Currency</em> of the amount must be provided.
            <br />
            Permitted <em>Currency</em> values are detailed in the relevant
            schema file,
            <a href="/grist-api-schemas/acceptedCurrencies.xsd"
              >http://europepmc.org/grist-api-schemas/acceptedCurrencies.xsd</a
            >.
          </dd>

          <dt>PMID [optional]</dt>
          <dd>
            PubMed Id of an article associated with the grant.
            <br />
            Multiple PMID values can be provided.
            <br />
            Note that PubMed Ids are not stored in the Grist database. They are
            forwarded for processing in the main Europe PubMed Central database.
          </dd>
        </dl>

        <h2 id="institution-field">Institution</h2>
        <dl>
          <dt>Name</dt>
          <dd>Name of institution.</dd>
          <dt>Department [optional]</dt>
          <dd>Department of institution.</dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#data-fields-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}
</style>
