var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"change-alerts-email-page"}},[_c('h1',[_vm._v("Change alerts email")]),_c('h2',[_vm._v("Email for receiving alerts")]),(_vm.confirmationMessage)?_c('confirmation',{on:{"close":function($event){_vm.confirmationMessage = ''}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.confirmationMessage)}})]):_vm._e(),(_vm.loaded)?_c('div',[(!_vm.edit)?[_c('div',{staticClass:"input-group"},[_vm._v("\n        "+_vm._s(_vm.inputs.email)+"\n        "),(
            _vm.account.tempNotificationEmail &&
            _vm.account.tempNotificationEmail !==
              (_vm.account.notificationEmail || _vm.account.email)
          )?_c('div',[_c('span',{staticClass:"error-message small"},[_vm._v("Email unverified")]),_vm._v("\n          |\n          "),_c('action',{staticClass:"regular",on:{"click":function($event){$event.preventDefault();return _vm.resendAlertConfirmationEmail.apply(null, arguments)}}},[_vm._v("Resend")])],1):_vm._e()]),_c('div',{staticClass:"input-group"},[_c('input',{attrs:{"type":"button","value":"Edit"},on:{"click":function($event){$event.preventDefault();return _vm.onEditClicked.apply(null, arguments)}}})])]:[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"error-message"},[(!_vm.isEmailValid)?[_vm._v("\n            Please enter a valid email address.\n          ")]:(_vm.inputs.emailAgain && !_vm.isEmailAgainValid)?[_vm._v("\n            Please make sure the emails match.\n          ")]:(_vm.inputs.emailAgain && !_vm.isTempEmailValid)?[(
                _vm.account.notificationEmail === _vm.account.tempNotificationEmail
              )?[_vm._v("\n              Please enter an email address different to the current one.\n            ")]:[_vm._v("\n              This email is pending verification. Please\n              "),_c('span',{staticClass:"link",attrs:{"click.prevent":"resendAlertConfirmationEmail"}},[_vm._v("resend")]),_vm._v(".\n            ")]]:_vm._e()],2),_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"email-input"}},[_vm._v("Enter/edit alerts email")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputs.email),expression:"inputs.email",modifiers:{"trim":true}}],class:{
              error:
                !_vm.isEmailValid || (_vm.inputs.emailAgain && !_vm.isTempEmailValid),
            },attrs:{"id":"email-input","type":"text"},domProps:{"value":(_vm.inputs.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputs, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"input-group"},[_c('label',{staticClass:"semi-bold",attrs:{"for":"email-again-input"}},[_vm._v("Confirm alerts email")]),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.inputs.emailAgain),expression:"inputs.emailAgain",modifiers:{"trim":true}}],class:{
            error: _vm.isEmailValid && _vm.inputs.emailAgain && !_vm.isEmailAgainValid,
          },attrs:{"id":"email-again-input","type":"text"},domProps:{"value":(_vm.inputs.emailAgain)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.inputs, "emailAgain", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputs.confirm),expression:"inputs.confirm"}],attrs:{"id":"confirm-input","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputs.confirm)?_vm._i(_vm.inputs.confirm,null)>-1:(_vm.inputs.confirm)},on:{"change":function($event){var $$a=_vm.inputs.confirm,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.inputs, "confirm", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.inputs, "confirm", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.inputs, "confirm", $$c)}}}}),_c('label',{attrs:{"for":"confirm-input"}},[_vm._v("I understand that changing my alerts email means that all of the\n          current saved alerts in this account, and any merged accounts, will\n          sent to this new email.")])]),_c('div',{staticClass:"input-group"},[_c('input',{attrs:{"id":"save-btn","type":"button","value":"Save","disabled":!_vm.allInputsValid},on:{"click":function($event){$event.preventDefault();return _vm.updateAlertsEmail.apply(null, arguments)}}}),_c('action',{on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cancel")])],1)]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }