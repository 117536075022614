var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-row",attrs:{"id":"grant-finder-form-page"}},[_vm._m(0),_c('div',{staticClass:"col-12"},[_c('content-section',{attrs:{"section":{
        heading: 'h2',
        title: 'Europe PMC funder grants',
      }}},[_c('p',{attrs:{"id":"instruction"}},[_vm._v("\n        Find active and expired grants awarded by\n        "),_c('a',{attrs:{"href":"/Funders"}},[_vm._v("Europe PMC funders")]),_vm._v(".\n      ")]),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"keyword-input"}},[_vm._v("Keyword")])]),_c('div',{staticClass:"input-hint small"},[_vm._v("\n          Search the grant title, abstract and funding stream.\n        ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.query.kw),expression:"query.kw",modifiers:{"trim":true}}],attrs:{"id":"keyword-input","type":"text"},domProps:{"value":(_vm.query.kw)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.query, "kw", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"pi-input"}},[_vm._v("Principal Investigator (PI) name")])]),_c('div',{staticClass:"input-hint small"},[_vm._v("E.g. Glover DM, or Glover")]),_c('suggest-input',{attrs:{"input-id":"pi-input","suggestions":_vm.suggestions.pi.map((suggestion) => ({
              value:
                suggestion.familyName +
                (suggestion.firstName ? ' ' + suggestion.firstName : '') +
                (suggestion.initials ? ' ' + suggestion.initials : '') +
                (suggestion.title ? ' ' + suggestion.title : ''),
              text:
                suggestion.familyName +
                (suggestion.firstName ? ' ' + suggestion.firstName : '') +
                (suggestion.initials ? ' ' + suggestion.initials : '') +
                (suggestion.title ? ' ' + suggestion.title : ''),
              query:
                (suggestion.title ? '{' + suggestion.title + '}' : '{}') +
                '{' +
                suggestion.familyName +
                '}' +
                (suggestion.firstName
                  ? '{' + suggestion.firstName + '}'
                  : '{}') +
                (suggestion.initials
                  ? '{' + suggestion.initials + '}'
                  : '{}'),
            }))},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.suggest('pi')}],"onClickOutside":function($event){return _vm.clearSuggestions('pi')},"selectSuggestion":(suggestion) => {
              _vm.selectSuggestion('pi', suggestion)
            }},model:{value:(_vm.query.pi),callback:function ($$v) {_vm.$set(_vm.query, "pi", $$v)},expression:"query.pi"}})],1),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"orcid-input"}},[_vm._v("ORCID")])]),_c('div',{staticClass:"input-hint small"},[_vm._v("E.g. 0000-0002-3452-3382")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.query.pi_id),expression:"query.pi_id",modifiers:{"trim":true}}],attrs:{"id":"orcid-input","type":"text"},domProps:{"value":(_vm.query.pi_id)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.query, "pi_id", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"aff-input"}},[_vm._v("Affiliation")])]),_c('div',{staticClass:"input-hint small"},[_vm._v("E.g. King's College London")]),_c('suggest-input',{attrs:{"input-id":"aff-input","suggestions":_vm.suggestions.institution.map((suggestion) => ({
              value: suggestion,
              text: suggestion,
            }))},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.suggest('institution')}],"onClickOutside":function($event){return _vm.clearSuggestions('institution')},"selectSuggestion":(suggestion) => {
              _vm.selectSuggestion('institution', suggestion)
            }},model:{value:(_vm.query.aff),callback:function ($$v) {_vm.$set(_vm.query, "aff", $$v)},expression:"query.aff"}})],1),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"funders-input"}},[_vm._v("Funder(s)")])]),_c('div',{staticClass:"input-hint small"},[_vm._v("\n          Select funder(s) to filter your search\n        ")]),_c('multi-select',{attrs:{"input-id":"funders-input","options":_vm.funderOptions},on:{"onOptionChanged":_vm.changeFunders,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}}})],1),_c('div',{staticClass:"input-group"},[_c('div',[_c('label',{staticClass:"semi-bold",attrs:{"for":"gid-input"}},[_vm._v("Grant ID")])]),_c('suggest-input',{attrs:{"input-id":"gid-input","suggestions":_vm.suggestions.grant.map((suggestion) => ({
              value: suggestion,
              text: suggestion,
            }))},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.suggest('grant')}],"onClickOutside":function($event){return _vm.clearSuggestions('grant')},"selectSuggestion":(suggestion) => {
              _vm.selectSuggestion('grant', suggestion)
            }},model:{value:(_vm.query.gid),callback:function ($$v) {_vm.$set(_vm.query, "gid", $$v)},expression:"query.gid"}})],1),_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"semi-bold"},[_vm._v("Status")]),_c('input',{attrs:{"id":"active-grants-checkbox","type":"checkbox"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)},"change":function($event){_vm.query.current = !_vm.query.current}}}),_c('label',{staticClass:"semi-bold",attrs:{"for":"active-grants-checkbox"}},[_vm._v("\n          Active grants only\n        ")])]),_c('div',{attrs:{"id":"buttons"}},[_c('button',{staticClass:"secondary",attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.clearFields.apply(null, arguments)}}},[_vm._v("\n          Clear fields\n        ")]),_c('button',{staticClass:"search-btn",attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_vm._v("\n          Search grants\n        ")])])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-4"},[_c('br')])
}]

export { render, staticRenderFns }