<script>
import { isPageInVue } from '@/config'
import PageFooter from '@/templates/Footer'
import SearchPanel from '@/templates/SearchPanel'

const withoutSearchPanel = (pathname) =>
  ['/feedback', '/footer', '/searchPanel', '/betaAdvanceSearch'].includes(
    pathname
  )

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify metaInfo.title, this title will be used
    title: 'Europe PMC',
    // all the titles will be injected into this template
    titleTemplate: '%s',
  },
  components: { PageFooter, SearchPanel },
  data() {
    return {
      withoutSearchPanel: withoutSearchPanel(location.pathname),
      isPageInVue,
    }
  },
}
</script>
<template>
  <div id="app" class="epmc-elements">
    <!-- There is logic on backend stating that if the page is in Vue, do not render search panel and footer -->
    <search-panel v-if="!withoutSearchPanel" />
    <router-view />
    <page-footer v-if="!withoutSearchPanel && isPageInVue" />
  </div>
</template>
