var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.ga)?_c('router-link',{attrs:{"to":_vm.grantLink,"href":_vm.grantHref}},[_c('b',[_vm._v(_vm._s(_vm.grant.Grant.Title))])]):_vm._e(),_vm._v("\n    "+_vm._s(!_vm.ga ? _vm.grant.Grant.Title : '')+"\n    ("+_vm._s(_vm.grant.Grant.Id)+")\n  ")],1),_c('div',[_c('router-link',{attrs:{"to":_vm.personLink}},[_vm._v("\n      "+_vm._s(_vm.grant.Person.Title || '')+"\n      "+_vm._s(_vm.grant.Person.FamilyName)+_vm._s(_vm.grant.Person.GivenName
          ? ', ' + _vm.grant.Person.GivenName
          : _vm.grant.Person.Initials
          ? ', ' + _vm.grant.Person.Initials
          : ''))]),_vm._v(",\n    "),(_vm.grant.Institution)?_c('router-link',{attrs:{"to":_vm.institutionLink}},[_vm._v("\n      "+_vm._s(_vm.grant.Institution.Name))]):_vm._e(),_vm._v(_vm._s(_vm.grant.Institution ? ',' : '')+"\n    "),(_vm.ga)?_c('router-link',{attrs:{"to":_vm.funderLink}},[_vm._v("\n      "+_vm._s(_vm.grant.Grant.Funder.Name)+"\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(!_vm.ga ? _vm.grant.Grant.Funder.Name : '')+"\n    "+_vm._s(_vm.dates)+"\n    "),(_vm.expired)?_c('span',{staticClass:"expired small"},[_vm._v("Expired")]):_vm._e()],1),(_vm.displayCounts)?_c('div',[(_vm.allPubCount)?_c('router-link',{attrs:{"to":{
        name: 'search',
        query: {
          query: _vm.allPubLink,
        },
      }}},[_vm._v("\n      "+_vm._s(_vm.allPubCount)+" publications\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.allPubCount ? '' : '0 publications')+"\n    |\n    "),(_vm.ftCount)?_c('router-link',{attrs:{"to":{
        name: 'search',
        query: {
          query: _vm.allFTLink,
        },
      }}},[_vm._v("\n      "+_vm._s(_vm.ftCount)+" free full text articles\n    ")]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.ftCount ? '' : '0 free full text articles')+"\n  ")],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }