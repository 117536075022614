<script>
import { SharePanel } from 'epmc-patterns/components/v2'

export default {
  components: { SharePanel },
  props: {
    blogs: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<template>
  <div class="blogs">
    <div v-for="(item, index) in blogs" :key="index" class="blog">
      <p class="small semi-bold meta">
        {{ item.type + ', ' + item.date }}
        <share-panel
          :id="'epmc-blog' + index"
          :show="3"
          :media="['twitter', 'facebook', 'linkedin']"
          :title="item.title"
          :url="item.readMoreLink"
        />
      </p>
      <h3>
        <a :href="item.readMoreLink">{{ item.title }}</a>
      </h3>
      <div class="content">
        <img
          v-if="item.previewImage"
          :src="item.previewImage"
          :alt="'Preview image of ' + item.title"
          class="preview-image"
        />
        <p :class="{ 'text-with-preview-image': item.previewImage }">
          {{ item.snippet }}
          <a :href="item.readMoreLink">Read the full story...</a>
        </p>
      </div>
      <hr v-if="index !== blogs.length - 1" class="blog-divider" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.blogs {
  padding: $base-unit 12% ($base-unit * 4);
  @media screen and (max-width: $breakpoint-extra-small) {
    padding: 0;
  }
  .blog {
    .share-panel {
      float: right;
    }
    h3 {
      margin-top: 0;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .preview-image {
        max-width: 50%;
        max-height: $base-unit * 40;
      }
      .text-with-preview-image {
        max-width: 50% !important;
      }
    }
    .blog-divider {
      margin: ($base-unit * 6) 0;
    }
  }
}
</style>
