import { EPMC_API_ROOT } from '@/config'
import { checkRedirect, send } from '@/api'

const ACCOUNT_API_ROOT = EPMC_API_ROOT + 'account'

export async function readAccount() {
  const response = await send(ACCOUNT_API_ROOT, 'GET', null, { cache: false })
  checkRedirect(response)
  return response
}

export async function resendAlertConfirmationEmail() {
  const response = await send(
    ACCOUNT_API_ROOT + '?action=resendNotificationConfirmationEmail',
    'PUT'
  )
  checkRedirect(response)
  return response
}

export async function updateAlertsEmail({
  action = 'updateNotificationEmail',
  data,
}) {
  const response = await send(
    ACCOUNT_API_ROOT + `?action=${action}`,
    'PUT',
    data
  ).catch(() => {
    if (action === 'updateNotificationEmail') {
      checkRedirect({ Error: 'No account found' })
    }
  })
  if (action === 'updateNotificationEmail') {
    checkRedirect(response)
  }
  return response
}
