<script>
import { fetchSearchResults } from '@/api'
import convertNum from '@/helpers/number'

export default {
  metaInfo() {
    return {
      title: 'Preprints - Developers - Europe PMC',
    }
  },
  data() {
    return {
      pprQuery: {
        query: 'SRC:PPR',
        count: 0,
      },
      covidPprQuery: {
        query: 'FT_ID:PPR* AND ((HAS_FT:Y AND OPEN_ACCESS:Y))',
        count: 0,
      },
    }
  },
  created() {
    const { pprQuery, covidPprQuery } = this

    fetchSearchResults({ query: pprQuery.query }).then((response) => {
      pprQuery.count = convertNum(response.hitCount)
    })
    fetchSearchResults({
      query: covidPprQuery.query,
    }).then((response) => {
      covidPprQuery.count = convertNum(response.hitCount)
    })
  },
}
</script>
<template>
  <div id="preprints-download-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Preprints subset</h1>
        <p>
          Preprints are author manuscripts which have not yet been
          peer-reviewed. The Europe PMC preprints subset contains metadata of
          <a v-if="pprQuery.query" :href="'/search?query=' + pprQuery.query"
            >{{ pprQuery.count }} preprint abstracts</a
          >
          and full-text XML of
          <a
            v-if="covidPprQuery.query"
            :href="'/search?query=' + covidPprQuery.query"
            >{{ covidPprQuery.count }} COVID-19 preprints</a
          >
          which are open access, originally uploaded by the authors to
          <a href="/Preprints#preprint-servers">preprint servers</a>.
        </p>

        <h2>Copyright</h2>
        <p>
          Full-text articles in the preprints subset are still protected by
          <a href="/Copyright">copyright</a>, but are made available under a
          <a
            href="//creativecommons.org/2006/12/08/license-compatibility-wizard-from-cc-taiwan/"
            >Creative Commons</a
          >
          license, or similar, that generally allows more liberal redistribution
          and reuse than a traditional copyrighted work.
        </p>
        <p>
          Please refer to the license statement in each article for specific
          terms of use. The license terms are not identical for all articles in
          this subset.
        </p>

        <h2>Download the preprints subset</h2>
        <p>
          The preprints subset can be downloaded using a variety of Europe PMC
          services as below. Use the field search
          <code>PUB_TYPE:Preprint</code> to find preprint versions of articles.
        </p>
        <h3>Web services</h3>
        <ul>
          <li><a href="/RestfulWebService">RESTful</a> web service</li>
          <li><a href="/SoapWebServices">SOAP</a> web service</li>
        </ul>
        <h3>FTP</h3>
        <ul>
          <li>
            <a href="/ftp/preprint_abstracts">Preprint abstracts</a><br />This
            directory contains all the preprint abstracts available in Europe
            PMC in XML format.
          </li>
          <li>
            <a href="/ftp/preprint_fulltext">Full-text open-access preprints</a
            ><br />This directory contains all the open-access preprints,
            available as full-text XML in Europe PMC.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#preprints-download-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
}
</style>
