import { EPMC_API_GET_ROOT } from '@/config'
import { send } from '@/api'

export function fetchLoggedInOrcidId() {
  const url =
    window.location.hostname == 'localhost'
      ? 'http://localhost:8088/api/get/'
      : EPMC_API_GET_ROOT
  return send(
    url + 'loggedin/orcid',
    'GET',
    null,
    {
      xhrFields: {
        withCredentials: true,
      },
      dataType: 'text',
    },
    true
  )
}
