import { fetchUnpaywallLink } from '@/api'

const namespaced = true

const getDefaultState = () => {
  return {
    fullTextLinks: [],
    unpayWallRequestFinished: true,
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  loadFullTextLinks({ state, rootState, commit }) {
    const abstract = rootState.article.abstract.abstract
    const dataLinks = rootState.article.dataLinks
      ? rootState.article.dataLinks.dataLinks
      : []
    if (dataLinks['full_text']) {
      commit(
        'article/sections/changeSectionVariable',
        { id: 'full-text-links', render: !abstract.hasFulltext },
        { root: true }
      )
      state.fullTextLinks.push(...dataLinks['full_text'])
    }
  },
  loadUnpayWallLinks({ state, rootState }) {
    const abstract = rootState.article.abstract.abstract
    const availableSrc = ['MED', 'PPR', 'CTX', 'AGR']
    if (
      abstract &&
      availableSrc.includes(abstract.source) &&
      abstract.inEPMC === 'N' &&
      abstract.doi
    ) {
      state.unpayWallRequestFinished = false
      return fetchUnpaywallLink(abstract.doi)
        .then((response) => {
          const bestLink = response.best_oa_location
          const availableTypes = ['publisher', 'repository']
          if (bestLink && availableTypes.includes(bestLink.host_type))
            state.fullTextLinks.push(bestLink)
        })
        .then(() => {
          state.unpayWallRequestFinished = true
        })
        .catch(() => {
          state.unpayWallRequestFinished = true
        })
    }
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
}
