import { EPMC_API_GET_ROOT } from '@/config'
import { send } from '@/api'

export async function sendFeedback(
  subject,
  body,
  gRecaptchaResponse,
  userResearch
) {
  const errorMessage =
    'There is an error in sending feedback. Please try again.'

  try {
    await send(
      EPMC_API_GET_ROOT + 'message',
      'POST',
      {
        subject: `${location.hostname} - ${subject}`,
        body,
        gRecaptchaResponse,
        userResearch: userResearch ? 'Y' : 'N',
      },
      { timeout: 6000 }
    )
    return Promise.resolve(true)
  } catch (e) {
    if (e && e.responseText && e.responseText.includes('sent: true')) {
      return Promise.resolve(true)
    } else {
      throw new Error(errorMessage)
    }
  }
}
