import Annotations from '@/helpers/article/annotation'

const namespaced = true

const getDefaultState = () => {
  return {
    showAnnotationsPane: false,
    annotations: null,
    annotationsLoaded: false,
    annotationsCount: {
      fulltextCount: 0,
      supplementaryFilesCount: 0,
    },
    canHighlight: true,
    activeTab: 'full_text',
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  changeShowAnnotationsPane(state, showAnnotationsPane) {
    state.showAnnotationsPane = showAnnotationsPane
  },
  setActiveTab(state, activeTab) {
    if (activeTab) state.activeTab = activeTab
  },
  updateAnnotationsCount(state, annotationsCount) {
    state.annotationsCount = annotationsCount
  },
}

const getContentData = async (rootState, commit, dispatch) => {
  let contentData = {}

  const { fulltextCount } = rootState.article.annotations.annotationsCount
  const { abstractText, license, hasFulltext } =
    rootState.article.abstract.abstract
  const { fulltextHtml } = rootState.article.fulltext
  const canHighlightFulltext =
    license &&
    ['CC BY', 'CC0', 'CC BY-NC', 'CC BY-SA', 'CC BY-NC-SA'].includes(
      license.toUpperCase()
    ) &&
    hasFulltext

  if (
    (canHighlightFulltext && fulltextCount > 0) ||
    (!abstractText && hasFulltext)
  ) {
    contentData = { content: fulltextHtml, containerId: 'fulltextcontent' }
    if (
      canHighlightFulltext &&
      !fulltextHtml &&
      dispatch &&
      fulltextCount > 0
    ) {
      contentData.content = await dispatch(
        'article/fulltext/loadFulltextHtml',
        null,
        { root: true }
      )
    }
    if (commit) {
      commit(
        'article/sections/changeSectionVariable',
        { id: 'free-full-text', visible: true },
        { root: true }
      )
    }

    if (!canHighlightFulltext) {
      contentData.disableLocation = true
    }
  } else if (abstractText) {
    contentData = {
      content: abstractText,
      containerId: 'article--abstract--content',
      abstractOnly: !hasFulltext,
    }
    if (hasFulltext) {
      contentData.disableLocation = true
    }
  }

  return contentData
}

const updateContent = (rootState, contentData, content) => {
  if (contentData.containerId === 'article--abstract--content') {
    rootState.article.abstract.abstract.abstractText = content
  } else if (contentData.containerId === 'fulltextcontent') {
    rootState.article.fulltext.fulltextHtml = content
  }
}

const actions = {
  async loadAnnotations({ state, rootState, commit, dispatch }) {
    if (!state.annotationsLoaded) {
      const { id, source } = rootState.article.abstract.abstract

      await dispatch('article/textHighlight/unhighlightAll', null, {
        root: true,
      })

      const contentData = await getContentData(rootState, commit, dispatch)
      const { content, containerId, disableLocation, abstractOnly } =
        contentData

      state.canHighlight = !disableLocation

      if (containerId) {
        state.annotations = new Annotations({
          content,
          containerId, // make sure the id already exists
          disableLocation,
        })

        await state.annotations.loadAnnotations({
          id,
          source,
          abstractOnly,
        })
      }

      state.annotationsLoaded = true
    }

    return Promise.resolve(state.annotations)
  },
  async clearAnnotations({ state, rootState }) {
    if (state.canHighlight) {
      const contentData = await getContentData(rootState)
      const content = state.annotations.clearAnnotations(contentData.content)
      updateContent(rootState, contentData, content)
    }
  },
  async selectCategory({ state, rootState }, category) {
    const contentData = await getContentData(rootState)
    const content = state.annotations.selectCategory(
      category,
      contentData.content
    )
    updateContent(rootState, contentData, content)
  },
  async selectLabel({ state, rootState }, label) {
    const contentData = await getContentData(rootState)
    const content = state.annotations.selectLabel(label, contentData.content)
    updateContent(rootState, contentData, content)
  },
  goToAnnotation({ state }, { label, prev }) {
    state.annotations.goToAnnotation(label, prev)
  },
  updateAnnotationsCount({ state }, annotationsCount) {
    state.annotationsCount = annotationsCount
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
}
