import { EPMC_API_GET_ROOT, EPMC_WEB_SERVICES_ROOT } from '@/config'
import { send, encodeQuery } from '@/api'

export function fetchAllSearchResults(params) {
  return fetchSearchResults(params).then(async (response) => {
    const pageSize = params.pageSize
    const newResponse = Object.assign({}, response)
    const newParams = Object.assign({}, params)

    const rem = response.hitCount - pageSize
    if (rem > 0) {
      newParams.cursorMark = response.nextCursorMark
      const times =
        rem % pageSize === 0
          ? parseInt(rem / pageSize, 10)
          : parseInt(rem / pageSize, 10) + 1
      for (let i = 0; i < times; i++) {
        const nextResponse = await fetchSearchResults(newParams)
        newResponse.resultList.result = newResponse.resultList.result.concat(
          nextResponse.resultList.result
        )
        newParams.cursorMark = nextResponse.nextCursorMark
      }
    }
    return Promise.resolve(newResponse)
  })
}

export function fetchContentTypes(query) {
  const req =
    EPMC_API_GET_ROOT +
    'profileApi?' +
    'profiletype=SEARCHFILTERS&synonym=false&query=' +
    encodeQuery(query)
  return send(req)
}

export function fetchYearlyCounts(query) {
  const req = EPMC_API_GET_ROOT + 'yearlysearch?query=' + encodeQuery(query)
  return send(req)
}

export function fetchSearchResults(params) {
  const {
    cursorMark,
    format,
    page,
    pageSize,
    query,
    ref,
    resultType,
    sort,
    synonym,
    ws,
  } = params
  let req = ws
    ? EPMC_WEB_SERVICES_ROOT + 'search'
    : EPMC_API_GET_ROOT + 'articleApi'
  req += '?query=' + (encodeQuery(query) || '')
  req += cursorMark ? '&cursorMark=' + encodeURIComponent(cursorMark) : ''
  req += '&format=' + (format || 'json')
  req += page ? '&page=' + page : ''
  req += pageSize ? '&pageSize=' + pageSize : ''
  req += ref ? '&ref=web' : ''
  req += resultType ? '&resultType=' + resultType : ''
  req += sort ? '&sort=' + sort : ''
  req += synonym ? '&synonym=' + synonym : ''
  return send(req)
}

export function fetchSnippets(params) {
  const { cursorMark, pagesize, query, sortby } = params
  const req =
    EPMC_API_GET_ROOT +
    'snippets?ref=web&query=' +
    encodeQuery(query) +
    (cursorMark ? '&cursormark=' + encodeURIComponent(cursorMark) : '') +
    (pagesize ? '&pagesize=' + pagesize : '') +
    (sortby ? '&sortby=' + encodeURIComponent(sortby) : '')
  return send(req)
}

export function fetchSuggestions(query) {
  const req = EPMC_API_GET_ROOT + 'spellCheck?query=' + encodeQuery(query)
  return send(req)
}
