const namespaced = true

const getDefaultState = () => {
  return {
    lastSortBy: null,
    query: '',
    snippets: null,
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  changeLastSortBy(state, sortBy) {
    state.lastSortBy = sortBy
  },
  changeQuery(state, query) {
    state.query = query
  },
  changeSnippets(state, snippets) {
    state.snippets = snippets
  },
}

export default { namespaced, state, mutations }
