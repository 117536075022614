<script>
import { fetchSearchResults, fetchYearlyCounts } from '@/api'
import convertNum from '@/helpers/number'
import { Chart } from 'epmc-patterns/components/v2'

const yearRange = [2005, new Date().getFullYear() - 1]

export default {
  metaInfo() {
    return {
      title: 'Open access - Developers - Europe PMC',
    }
  },
  components: { Chart },
  data() {
    return {
      articleCountByQuery: {
        'HAS_FT:Y': 0,
        'OPEN_ACCESS:Y': 0,
      },
      chart: {
        title: '',
        type: 'bar',
        labels: [],
        datasets: [],
        update: false,
        options: {
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  max: 100,
                  min: 0,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Proportion of PubMed articles in Europe PMC',
                  fontSize: 14,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                const dataset = data.datasets[tooltipItem.datasetIndex]
                const label = dataset.label
                const number = Number(dataset.data[tooltipItem.index]).toFixed(
                  1
                )
                const value = number + '%'
                return label + ': ' + value
              },
            },
          },
        },
      },
      yearRange,
    }
  },
  computed: {
    yearPercentages() {
      const p = 0
      const ds = this.chart.datasets
      if (ds.length) {
        const nonOpenAccessYearlyCountData = ds[0].data
        const openAccessYearlyCountData = ds[1].data
        return [
          parseInt(
            openAccessYearlyCountData[0] + nonOpenAccessYearlyCountData[0] + 1
          ) + '%',
          parseInt(
            openAccessYearlyCountData[openAccessYearlyCountData.length - 1] +
              nonOpenAccessYearlyCountData[
                nonOpenAccessYearlyCountData.length - 1
              ] +
              1
          ) + '%',
        ]
      }
      return p
    },
  },
  created() {
    const { articleCountByQuery } = this
    for (const query in articleCountByQuery) {
      fetchSearchResults({
        pageSize: 1,
        query,
      }).then((response) => {
        articleCountByQuery[query] = convertNum(response.hitCount)
      })
    }
    Promise.all(
      [
        'OPEN_ACCESS:Y AND SRC:MED',
        'OPEN_ACCESS:N AND HAS_FT:Y AND SRC:MED',
        'HAS_FT:N AND SRC:MED',
        'SRC:MED',
      ].map((query) => fetchYearlyCounts(query))
    ).then((response) => {
      const { chart } = this

      const openAccessYearlyCount = response[0]
        .filter((yc) => yc.year >= yearRange[0] && yc.year <= yearRange[1])
        .reverse()
      const nonOpenAccessYearlyCount = response[1]
        .filter((yc) => yc.year >= yearRange[0] && yc.year <= yearRange[1])
        .reverse()
      const abstractOnlyYearlyCount = response[2]
        .filter((yc) => yc.year >= yearRange[0] && yc.year <= yearRange[1])
        .reverse()
      const allYearlyCount = response[3]
        .filter((yc) => yc.year >= yearRange[0] && yc.year <= yearRange[1])
        .reverse()

      chart.labels = openAccessYearlyCount.map((yc) => yc.year)
      chart.datasets.push(
        {
          label: 'Open access full text',
          data: openAccessYearlyCount.map(
            (yc, index) => (yc.count / allYearlyCount[index].count) * 100
          ),
        },
        {
          label: 'Non open access free full text',
          data: nonOpenAccessYearlyCount.map(
            (yc, index) => (yc.count / allYearlyCount[index].count) * 100
          ),
        },
        {
          label: 'Abstract only',
          data: abstractOnlyYearlyCount.map(
            (yc, index) => (yc.count / allYearlyCount[index].count) * 100
          ),
        }
      )
      chart.update = true
    })
  },
}
</script>
<template>
  <div id="open-access-download-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Open access subset</h1>
        <p>
          There are around
          <a href="/search?query=HAS_FT:Y">{{
            articleCountByQuery['HAS_FT:Y']
          }}</a>
          full text articles in Europe PMC, of which nearly
          <a href="/search?query=OPEN_ACCESS:Y">{{
            articleCountByQuery['OPEN_ACCESS:Y']
          }}</a>
          comprise the Europe PMC open access subset.
        </p>
        <h2>Copyright</h2>
        <p>
          Articles in the open access subset are still protected by
          <a href="/Copyright">copyright</a>, but are made available under a
          <a
            href="//creativecommons.org/2006/12/08/license-compatibility-wizard-from-cc-taiwan/"
            >Creative Commons license</a
          >, or similar, that generally allows more liberal redistribution and
          reuse than a traditional copyrighted work.
        </p>
        <p>
          Please refer to the license statement in each article for specific
          terms of use. The license terms are not identical for all articles in
          this subset. For an overview of open access
          <a href="http://legacy.earlham.edu/~peters/fos/overview.htm"
            >Peter Suber's introduction</a
          >
          is useful.
        </p>
        <h2>Download the open access subset</h2>
        <p>
          The open access subset can be downloaded using a variety of Europe PMC
          services:
        </p>
        <h3>Web services</h3>
        <ul>
          <li><a href="/RestfulWebService">RESTful</a> web service</li>
          <li><a href="/SoapWebServices">SOAP</a> web service</li>
          <li><a href="/OaiService">Europe PMC-OAI service</a></li>
        </ul>
        <h3>FTP</h3>
        <ul id="ftp-list">
          <li>Full-text open access Europe PMC articles:</li>
          <li class="secondary-li-style">
            <a href="//europepmc.org/ftp/oa/"
              >Full-text XML open access articles</a
            >
          </li>
          <li class="secondary-li-style no-li-style">
            This directory contains all the open access articles available as
            full-text XML in Europe PMC. It is updated weekly.
          </li>
          <li class="secondary-li-style">
            <a href="//europepmc.org/ftp/pdf/"
              >Full text PDF open access articles</a
            >
          </li>
          <li class="secondary-li-style no-li-style">
            This directory contains all the open access articles available as
            full-text PDF in Europe PMC. It is updated monthly.
          </li>
        </ul>
        <h2>Proportion of open access articles in Europe PMC</h2>
        <p>
          The chart below shows that each year the proportion of PubMed articles
          published during that 12 month period and accessible through Europe
          PMC as full text and/or open access increases. To illustrate: of the
          articles published in {{ yearRange[0] }}, less than
          {{ yearPercentages[0] }} are now available as full text or open
          access, while of the articles published in {{ yearRange[1] }} nearly
          {{ yearPercentages[1] }} are freely available. To get a current count
          of articles in the open access subset, search for
          <a href="/search?query=OPEN_ACCESS:Y">OPEN_ACCESS:y</a>
          in Europe PMC, or select the ‘Open Access’ option on the advanced
          search page.
        </p>
        <chart
          v-show="chart.update && chart.labels.length"
          :type="chart.type"
          :labels="chart.labels"
          :datasets="chart.datasets"
          :options="chart.options"
          :update="chart.update"
          aria-label="Open access citations"
          role="img"
        />
        <p>Note:</p>
        <ul>
          <li>
            The majority of the full text articles in Europe PMC are subject to
            traditional copyright restrictions and are not part of this subset.
            Read the <a href="/Copyright">Europe PMC copyright notice</a> for
            more information.
          </li>
          <li>
            Some journals use the label "open access" for an article that is
            available free in its entirety at the time of publication, but is
            still subject to traditional copyright restrictions. Such articles
            are not part of this subset.
          </li>
        </ul>
        <p>
          The open access subset includes articles from a number of publishers'
          programs, each of which deposits selected articles from a range of
          journals. A complete list of the publisher programs, with links to the
          articles, can be found on the
          <a
            href="/journalList?titles=current&search=journals&journals=collections&journalid"
            >Special Collections tab</a
          >
          of the PMC Journals List.
        </p>
        <p>
          In addition to these selective deposit programs, a number of the
          journals that deposit their complete contents in PubMed Central
          include some or all their articles in the open access subset. These
          journals are marked with the symbol
          <img src="@/assets/open-access.png" alt="Open access" /> in the
          <a href="/journalList">Journal List</a>.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#open-access-download-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .secondary-li-style {
    list-style-type: circle;
    margin-left: $base-unit * 4;
  }
  .no-li-style {
    list-style-type: none;
  }
}
</style>
