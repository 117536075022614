export function formatDoi(doi) {
  const doiPrefix = 'https://doi.org/'
  const prefix = 'http://doi.org/'
  if (doi != null) {
    if (doi.startsWith(doiPrefix)) {
      return doi
    } else if (doi.startsWith(prefix)) {
      return doi.replace(prefix, doiPrefix)
    } else {
      return doiPrefix + doi
    }
  }
  return doi
}
