const hostname = window.location.hostname
//new matomo
export const MATOMO_SITE_ID = hostname.includes('dev.europepmc.org')
  ? 2
  : hostname.includes('test.europepmc.org')
  ? 5
  : hostname.includes('staging.europepmc.org')
  ? 7
  : hostname.includes('europepmc.org')
  ? 1
  : 6
