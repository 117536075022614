<script>
import { Action, Confirmation } from 'epmc-patterns/components/v2'
import {
  readAccount,
  resendAlertConfirmationEmail,
  updateAlertsEmail,
} from '@/api'
import { containEmail } from 'epmc-patterns/helpers'

export default {
  metaInfo() {
    return {
      title: 'Change alerts email - Accounts - Europe PMC',
    }
  },
  components: { Action, Confirmation },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputs: {
        email: '',
        emailAgain: '',
        confirm: false,
      },
      edit: false,
      loaded: false,
      account: {},
      confirmationMessage: '',
      willRedirect: false,
    }
  },
  computed: {
    isEmailValid() {
      return containEmail(this.inputs.email)
    },
    isEmailAgainValid() {
      const { inputs } = this
      return inputs.email === inputs.emailAgain
    },
    isTempEmailValid() {
      const { account, inputs } = this
      return (
        !account.tempNotificationEmail ||
        account.tempNotificationEmail !== inputs.email
      )
    },
    allInputsValid() {
      const { isEmailValid, isEmailAgainValid, isTempEmailValid, inputs } = this
      return (
        isEmailValid && isEmailAgainValid && isTempEmailValid && inputs.confirm
      )
    },
  },
  async created() {
    if (this.status === 'on_alert_modal_opened') {
      this.willRedirect = true
      this.$router.replace({ query: null })
    }
    await this.init()
  },
  methods: {
    async init() {
      this.inputs.emailAgain = ''
      this.inputs.confirm = false
      this.edit = false
      this.loaded = false
      const response = await readAccount()
      if (!response['Error']) {
        this.account = response
        this.inputs.email =
          response.tempNotificationEmail ||
          response.notificationEmail ||
          response.email ||
          ''
        this.edit = this.inputs.email ? false : true
      }
      this.loaded = true
    },
    async resendAlertConfirmationEmail() {
      try {
        const response = await resendAlertConfirmationEmail()
        if (!response['Error']) {
          const { email } = this.inputs
          this.confirmationMessage = `An email has been sent to <a href="mailto:${email}">${email}</a>, and the email must be verified before email updates will be sent to this address.`
        }
      } catch (e) {
        await this.init()
        this.confirmationMessage = 'The alerts email has been verified.'
      }
    },
    async updateAlertsEmail() {
      const { account, inputs } = this
      const { email } = inputs
      const isPreviousEmail = email === account.notificationEmail
      const response = await updateAlertsEmail({
        data: { tempNotificationEmail: email },
      })
      if (!response['Error']) {
        this.checkRedirect()
        await this.init()
        if (isPreviousEmail) {
          this.confirmationMessage = 'The alerts email has been updated.'
        } else {
          this.confirmationMessage = `An email has been sent to <a href="mailto:${email}">${email}</a>, and the email must be verified before email updates will be sent to this address.`
        }
      }
    },
    async cancel() {
      this.checkRedirect()
      await this.init()
    },
    checkRedirect() {
      const { willRedirect } = this
      if (willRedirect) {
        // can consider url helpers
        const pathname = new URL(document.referrer).pathname

        if (pathname.startsWith('/search')) {
          const query = '&action=save_alert'
          location.href = document.referrer.includes(query)
            ? document.referrer
            : document.referrer + query
        } else if (pathname.startsWith('/accounts/SavedAlerts')) {
          const query = '?status=on_alert_modal_opened'
          location.href = document.referrer.includes(query)
            ? document.referrer
            : document.referrer + query
        }
      }
    },
    async onEditClicked() {
      const response = await readAccount()
      if (!response['Error']) {
        this.edit = true
      }
    },
  },
}
</script>
<template>
  <div id="change-alerts-email-page">
    <h1>Change alerts email</h1>
    <h2>Email for receiving alerts</h2>
    <confirmation v-if="confirmationMessage" @close="confirmationMessage = ''">
      <span v-html="confirmationMessage" />
    </confirmation>
    <div v-if="loaded">
      <template v-if="!edit">
        <div class="input-group">
          {{ inputs.email }}
          <div
            v-if="
              account.tempNotificationEmail &&
              account.tempNotificationEmail !==
                (account.notificationEmail || account.email)
            "
          >
            <span class="error-message small">Email unverified</span>
            |
            <action
              class="regular"
              @click.prevent="resendAlertConfirmationEmail"
              >Resend</action
            >
          </div>
        </div>

        <div class="input-group">
          <input type="button" value="Edit" @click.prevent="onEditClicked" />
        </div>
      </template>
      <template v-else>
        <div class="input-group">
          <div class="error-message">
            <template v-if="!isEmailValid">
              Please enter a valid email address.
            </template>
            <template v-else-if="inputs.emailAgain && !isEmailAgainValid">
              Please make sure the emails match.
            </template>
            <template v-else-if="inputs.emailAgain && !isTempEmailValid">
              <template
                v-if="
                  account.notificationEmail === account.tempNotificationEmail
                "
              >
                Please enter an email address different to the current one.
              </template>
              <template v-else>
                This email is pending verification. Please
                <span class="link" click.prevent="resendAlertConfirmationEmail"
                  >resend</span
                >.
              </template>
            </template>
          </div>
          <div>
            <label for="email-input" class="semi-bold"
              >Enter/edit alerts email</label
            ><br />
            <input
              id="email-input"
              v-model.trim="inputs.email"
              type="text"
              :class="{
                error:
                  !isEmailValid || (inputs.emailAgain && !isTempEmailValid),
              }"
            />
          </div>
        </div>

        <div class="input-group">
          <label for="email-again-input" class="semi-bold"
            >Confirm alerts email</label
          ><br />
          <input
            id="email-again-input"
            v-model.trim="inputs.emailAgain"
            type="text"
            :class="{
              error: isEmailValid && inputs.emailAgain && !isEmailAgainValid,
            }"
          />
        </div>

        <div class="input-group">
          <input id="confirm-input" v-model="inputs.confirm" type="checkbox" />
          <label for="confirm-input"
            >I understand that changing my alerts email means that all of the
            current saved alerts in this account, and any merged accounts, will
            sent to this new email.</label
          >
        </div>

        <div class="input-group">
          <input
            id="save-btn"
            type="button"
            value="Save"
            :disabled="!allInputsValid"
            @click.prevent="updateAlertsEmail"
          />
          <action @click.prevent="cancel">Cancel</action>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="scss">
.input-group {
  input[type='text'] {
    width: 100%;
  }
  &:not(:last-of-type) {
    margin-bottom: $base-unit * 6;
  }
  .error-message {
    color: $epmc-red;
  }
  #save-btn {
    margin-right: $base-unit * 4;
  }
}
</style>
