export default {
  data() {
    return {
      screenWidth: window.innerWidth,
    }
  },
  created() {
    window.addEventListener('resize', this.updateScreenWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.updateScreenWidth())
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
  },
}
