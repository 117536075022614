import { EPMC_API_GET_ROOT } from '@/config'
import { send, encodeQuery, fetchAllSearchResults } from '@/api'

export function fetchAuthorAff(orcid) {
  const req = EPMC_API_GET_ROOT + 'affiliations?orcid=' + orcid.toUpperCase()
  return send(req)
}

export function fetchAuthorProfile(orcid) {
  const req = EPMC_API_GET_ROOT + 'orcid/profile?orcid=' + orcid.toUpperCase()
  return send(req)
}

export function fetchAuthorPublications(orcid) {
  const query = 'AUTHORID:' + orcid.toUpperCase()
  return fetchAllSearchResults({
    pageSize: 1000,
    query,
    sort: 'CITED+desc',
  })
}

export function fetchAuthorPublicOrcidPublications(orcid, link) {
  const opts = {
    'europepmc.org': 'https://pub.orcid.org/v3.0',
    'beta.europepmc.org': 'https://pub.sandbox.orcid.org/v3.0',
    'staging.europepmc.org': 'https://pub.sandbox.orcid.org/v3.0',
    'test.europepmc.org': 'https://pub.sandbox.orcid.org/v3.0',
    'dev.europepmc.org': 'https://pub.sandbox.orcid.org/v3.0',
    localhost: 'https://pub.sandbox.orcid.org/v3.0',
  }
  const orcidPubApi = link || opts[window.location.hostname]
  const req = `${orcidPubApi}/${orcid.toUpperCase()}/works`
  return send(req)
}

export function fetchAuthorYearlyCitations(orcid) {
  const req = EPMC_API_GET_ROOT + 'yearlycitations?orcid=' + orcid.toUpperCase()
  return send(req)
}

export function fetchSuggestedAuthors(author) {
  const req = EPMC_API_GET_ROOT + 'authororcids?query=' + encodeQuery(author)
  return send(req)
}
