import { EPMC_API_ROOT } from '@/config'
import { checkRedirect, send } from '@/api'

const SAVED_SEARCH_API_ROOT = EPMC_API_ROOT + 'savedsearch'
const UNSUBSCRIBE_API_ROOT = EPMC_API_ROOT + 'unsubscribe/savedsearch/'

export async function createAlert(alert) {
  const response = await send(SAVED_SEARCH_API_ROOT, 'POST', packAlert(alert))
  checkRedirect(response)
  return response
}

export async function deleteAlert(alert) {
  const response = await send(SAVED_SEARCH_API_ROOT + '/' + alert.id, 'DELETE')
  checkRedirect(response)
  return response
}

export async function readAlerts() {
  let response = await send(SAVED_SEARCH_API_ROOT, 'GET', null, {
    cache: false,
    timeout: 5000,
  })
  checkRedirect(response)
  if (!response['Error']) {
    response = response
      .sort((a, b) => b.dateInserted - a.dateInserted)
      .map((alert) => unpackAlert(alert))
  }
  return response
}

export function unsubscribeAlert(code) {
  return send(UNSUBSCRIBE_API_ROOT + code, 'PUT')
}

export async function updateAlert(alert) {
  const response = await send(SAVED_SEARCH_API_ROOT, 'PUT', packAlert(alert))
  checkRedirect(response)
  return response
}

function packAlert(alert) {
  return {
    id: alert.id,
    searchString: alert.query,
    searchName: alert.name,
    activeIndicator: alert.isAlert ? 'A' : 'S',
    mailFrequency: alert.frequency || null,
    sendNoResultsIndicator: alert.alwaysSend ? 'Y' : 'N',
    mailSubFrequency: alert.preferredDay || null,
    metadataIndicator: alert.includeMetadata ? 'Y' : 'N',
    abstractIndicator: alert.includeShortAbstract ? 'Y' : 'N',
    sourcePage: alert.source,
    // always email for now
    alertType: 'email',
  }
}

function unpackAlert(alert) {
  return {
    id: alert.id,
    query: alert.searchString,
    name: alert.searchName,
    isAlert: alert.activeIndicator === 'A' ? true : false,
    frequency: alert.mailFrequency,
    alwaysSend: alert.sendNoResultsIndicator === 'Y' ? true : false,
    preferredDay: alert.mailSubFrequency,
    includeMetadata: alert.metadataIndicator === 'Y' ? true : false,
    includeShortAbstract: alert.abstractIndicator === 'Y' ? true : false,
    isPrevious: alert.activeIndicator === 'M',
  }
}
