const namespaced = true

const getDefaultState = () => {
  return {
    alert: null,
    title: '',
  }
}

const state = getDefaultState()

const mutations = {
  setOpenedAlertModal(state, newState) {
    Object.assign(state, newState)
  },
}

const actions = {
  getAndClearAlertModal({ state }) {
    const stateCopy = Object.assign({}, state)
    Object.assign(state, getDefaultState())
    return Promise.resolve(stateCopy)
  },
}

export default { namespaced, state, mutations, actions }
