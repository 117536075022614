<script>
import { FUNDERS } from '@/config'
import { fetchSearchResults } from '@/api'
import { getAllPubLink, getAllFTLink, getUiType } from '@/helpers/grant-finder'

export default {
  props: {
    grant: {
      type: Object,
      default: () => {},
    },
    cat: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // display the counts part after loaded
      displayCounts: false,
      allPubCount: 0,
      ftCount: 0,
    }
  },
  computed: {
    allPubLink() {
      return getAllPubLink(this.grant.Grant)
    },
    allFTLink() {
      return getAllFTLink(this.grant.Grant)
    },
    ga() {
      const funder = FUNDERS.find(
        (f) => f.name === this.grant.Grant.Funder.Name
      )
      return funder && funder.name
    },
    grantLink() {
      const { ga, grant, cat } = this
      return {
        name: 'grantDetails',
        query: {
          query:
            'pi:"' +
            (grant.Person.Title ? '{' + grant.Person.Title + '}' : '{}') +
            '{' +
            grant.Person.FamilyName +
            '}' +
            (grant.Person.GivenName
              ? '{' + grant.Person.GivenName + '}'
              : '{}') +
            (grant.Person.Initials ? '{' + grant.Person.Initials + '}' : '{}') +
            '" gid:"' +
            grant.Grant.Id +
            '" ga:"' +
            ga +
            '"',
          cat,
        },
      }
    },
    grantHref() {
      const { ga, grant, cat } = this
      const query =
        'pi:"' +
        (grant.Person.Title ? '{' + grant.Person.Title + '}' : '{}') +
        '{' +
        grant.Person.FamilyName +
        '}' +
        (grant.Person.GivenName ? '{' + grant.Person.GivenName + '}' : '{}') +
        (grant.Person.Initials ? '{' + grant.Person.Initials + '}' : '{}') +
        '" gid:"' +
        grant.Grant.Id +
        '" ga:"' +
        ga +
        '"'
      return (
        '/grantfinder/grantdetails?query=' + query + (cat ? '&cat=' + cat : '')
      )
    },
    personLink() {
      const { Title, FamilyName, GivenName, Initials } = this.grant.Person
      return {
        name: 'grantSearch',
        query: Object.assign(
          getUiType() === 'covid-19-search' ? { cat: 'COVID-19' } : {},
          {
            pi:
              (Title ? '{' + Title + '}' : '{}') +
              '{' +
              FamilyName +
              '}' +
              (GivenName ? '{' + GivenName + '}' : '{}') +
              (Initials ? '{' + Initials + '}' : '{}'),
          }
        ),
      }
    },
    institutionLink() {
      const { Name } = this.grant.Institution
      return {
        name: 'grantSearch',
        query: Object.assign(
          getUiType() === 'covid-19-search' ? { cat: 'COVID-19' } : {},
          { aff: Name }
        ),
      }
    },
    funderLink() {
      const { ga } = this
      return {
        name: 'grantSearch',
        query: Object.assign(
          getUiType() === 'covid-19-search' ? { cat: 'COVID-19' } : {},
          { ga }
        ),
      }
    },
    dates() {
      const { StartDate, EndDate } = this.grant.Grant
      // only either show StartDate or both StartDate and EndDate
      return StartDate
        ? new Date(StartDate).getFullYear() +
            (EndDate ? '-' + new Date(EndDate).getFullYear() : '')
        : '(no dates available)'
    },
    expired() {
      const { EndDate } = this.grant.Grant
      return EndDate && new Date(EndDate) < new Date()
    },
  },
  async created() {
    const res = await Promise.all([
      fetchSearchResults({ query: this.allPubLink }),
      fetchSearchResults({ query: this.allFTLink }),
    ])
    this.allPubCount = res[0].hitCount
    this.ftCount = res[1].hitCount
    this.displayCounts = true
  },
}
</script>
<template>
  <div>
    <div>
      <router-link v-if="ga" :to="grantLink" :href="grantHref"
        ><b>{{ grant.Grant.Title }}</b></router-link
      >
      {{ !ga ? grant.Grant.Title : '' }}
      ({{ grant.Grant.Id }})
    </div>
    <div>
      <router-link :to="personLink">
        {{ grant.Person.Title || '' }}
        {{ grant.Person.FamilyName
        }}{{
          grant.Person.GivenName
            ? ', ' + grant.Person.GivenName
            : grant.Person.Initials
            ? ', ' + grant.Person.Initials
            : ''
        }}</router-link
      >,
      <router-link v-if="grant.Institution" :to="institutionLink">
        {{ grant.Institution.Name }}</router-link
      >{{ grant.Institution ? ',' : '' }}
      <router-link v-if="ga" :to="funderLink">
        {{ grant.Grant.Funder.Name }}
      </router-link>
      {{ !ga ? grant.Grant.Funder.Name : '' }}
      {{ dates }}
      <span v-if="expired" class="expired small">Expired</span>
    </div>
    <div v-if="displayCounts">
      <router-link
        v-if="allPubCount"
        :to="{
          name: 'search',
          query: {
            query: allPubLink,
          },
        }"
      >
        {{ allPubCount }} publications
      </router-link>
      {{ allPubCount ? '' : '0 publications' }}
      |
      <router-link
        v-if="ftCount"
        :to="{
          name: 'search',
          query: {
            query: allFTLink,
          },
        }"
      >
        {{ ftCount }} free full text articles
      </router-link>
      {{ ftCount ? '' : '0 free full text articles' }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.expired {
  background-color: lighten($epmc-darker-grey, 60%);
  padding: 0 ($base-unit * 2);
}
</style>
