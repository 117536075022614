import '@babel/polyfill'
// prefer using polyfill so that it can be easily removed when IE is totally abandoned
import 'url-polyfill'

import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'
import Vuex from 'vuex'

import storeOptions from './store'
import router from './router'

// import fonts and elements on page load
import 'epmc-patterns/Open_Sans/open_sans.css'
import 'epmc-patterns/fontawesome/css/all.min.css'
import 'epmc-patterns/EPMC-iconfont/style.min.css'
import 'epmc-patterns/elements/index.scss'

import App from './App.vue'

import { loadMatomoScript } from '@/helpers/matomo'

// Matomo Tracking Script
window._paq = window._paq || []
loadMatomoScript()

Vue.use(VueMeta)

Vue.use(VueRouter)

Vue.use(Vuex)

const store = new Vuex.Store(storeOptions)

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
