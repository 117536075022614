<script>
export default {
  metaInfo() {
    return {
      title: 'Grist API - Developers - Europe PMC',
    }
  },
  data() {
    return {
      links: [
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=cluster',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=cluster randomised',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query="cluster randomised"',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=pi:smith',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=title:"Cluster"',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=ga:"Cancer Research UK"',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=ga:"BHF" pi:smith',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=ga:"Wellcome Trust" & pi:smith',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=ga:"Wellcome Trust" kw:& pi:smith',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=ga:"Wellcome Trust" pi:smith',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=gid:081052&resultType=core',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=malaria&page=4',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=malaria&format=json',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=malaria&format=cerif',
        'https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query=pi:smith&format=cerif&page=2',
      ],
    }
  },
}
</script>
<template>
  <div id="grist-api-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Grants RESTful (Grist) API</h1>
        <p>
          The Grist database contains details of grants awarded by the
          <a href="/Funders">Europe PMC Funders</a>.
        </p>
        <p>
          <a href="/GristAPI/dataFields"
            >See a description of the data fields used in Grist.</a
          >
        </p>
        <p>Use this API to search the Grist database.</p>
        <p>
          Join the
          <a
            href="//groups.google.com/a/ebi.ac.uk/forum/#!forum/epmc-webservices"
            >Europe PMC web service users' Google group</a
          >
          to receive notifications about upcoming web service releases and
          changes.
        </p>
        <p>
          We advise that you read our Privacy Notice before using this API. By
          using our public API you agree to accept the terms of the
          <a href="/PrivacyNoticeForEPMC?type=website"
            >Europe PMC website Privacy Notice</a
          >.
        </p>

        <h2>
          <span class="annotated" title="Uniform Resource Locator">URL</span>
          syntax
        </h2>
        <p>Construct your URL for search requests as follows:</p>
        <pre>
https://www.ebi.ac.uk/europepmc/GristAPI/rest/get/query={}[&amp;parameters]
</pre
        >

        <h2>Request Parameters</h2>
        <h3>query</h3>
        <p>
          Enter search terms with this parameter. [Note that this is the only
          obligatory parameter.]
        </p>
        <p>
          You can use a keyword or combination of keywords; for the definition
          of a keyword search see
          <a href="#grist-api-parameters">Grist API Parameters</a>
        </p>
        <pre><a :href="links[0]">{{ links[0] }}</a><br /><a :href="links[1]">{{ links[1] }}</a></pre>
        <p>Or a phrase with enclosing speech marks:</p>
        <pre>
<a :href="links[2]">{{ links[2] }}</a>
</pre>
        <p>
          Or a fielded search; for a list of available search fields see
          <a href="#grist-api-parameters">Grist API Parameters</a>
        </p>
        <pre><a :href="links[3]">{{ links[3] }}</a><br /><a :href="links[4]">{{ links[4] }}</a><br /><a :href="links[5]">{{ links[5] }}</a><br /><a :href="links[6]">{{ links[6] }}</a></pre>
        <p><b>Note:</b></p>
        <p>
          Separate search terms within a query should be separated by spaces,
          not ampersands (&s), and all such search terms are combined with
          logical ANDs. If a specific field (e.g., pi:, ga:, gr:) is not given
          for a search term then a keyword (kw:) search is used by default.
        </p>
        <p>
          So, for example the following query will NOT identify grantholders
          named "smith" funded by the Wellcome Trust:
        </p>
        <pre>
<a :href="links[7]">{{ links[7] }}</a>
</pre>
        <p>because it is equivalent to this keyword search:</p>
        <pre>
<a :href="links[8]">{{ links[8] }}</a>
</pre>
        <p>In this case the correct query would be:</p>
        <pre>
<a :href="links[9]">{{ links[9] }}</a>
</pre>

        <h3>resultType</h3>
        <p>
          The result type can either be <b>lite</b> [the default value if
          parameter unspecified] or <b>core</b>:
        </p>
        <ul>
          <li><b>lite</b> returns key metadata for the given search terms;</li>
          <li>
            <b>core</b> returns full metadata for a given search terms,
            including abstracts, grant start/end dates and institution details.
          </li>
        </ul>
        <p>For example:</p>
        <pre>
<a :href="links[10]">{{ links[10] }}</a>
</pre>

        <h3>page</h3>
        <p>
          The number of the results page required. Each page contains results
          for up to 25 grants and page numbering starts at 1 [the default value
          if parameter unspecified].
        </p>
        <p>For example:</p>
        <pre>
<a :href="links[11]">{{ links[11] }}</a>
</pre>

        <h3>format</h3>
        <p>
          The format can be
          <b class="annotated" title="Extensible Markup Language">XML</b> [the
          default value if parameter unspecified] or
          <b class="annotated" title="JavaScript Object Notation">JSON</b> or
          <b
            class="annotated"
            title="Common European Research Information Format"
            ><a href="#cerif-api">CERIF</a></b
          >.
        </p>
        <p>For example:</p>
        <pre><a :href="links[12]">{{ links[12] }}</a><br /><a :href="links[13]">{{ links[13] }}</a></pre>

        <h2 id="grist-api-parameters">Grist API Parameters</h2>
        <p>
          [<a href="/GristAPI/dataFields"
            >See a description of the data fields used in Grist</a
          >].
        </p>
        <table class="dark">
          <tr>
            <th>Syntax word</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>ga, grant_agency</td>
            <td>
              Search for a term in funder names/abbreviations/<a
                href="//www.crossref.org/fundref/fundref_registry.html"
                >FundRef</a
              >
              IDs. [<a href="/GristAPI/funderNames"
                >See a list of funder names used in Grist</a
              >.]
            </td>
            <td>ga:"Wellcome Trust", ga:BBSRC, grant_agency:501100000381</td>
          </tr>
          <tr>
            <td>gid, gr, grant_id</td>
            <td>Search for a term in grant numbers/IDs.</td>
            <td>gid:083611</td>
          </tr>
          <tr>
            <td>title, ti</td>
            <td>Search for a term or terms in grant titles.</td>
            <td>title:cancer, ti:"kidney cancer"</td>
          </tr>
          <tr>
            <td>abstract, abs</td>
            <td>Search for a term or terms in grant abstracts.</td>
            <td>abstract:"diverse functions"</td>
          </tr>
          <tr>
            <td>date, active_date</td>
            <td>
              Search by active date, where date given is between start and end
              dates. Format must by yyyy-mm-dd, where month (mm) and day (dd)
              are optional.
            </td>
            <td>date:2011-02-12, date:2012-04, active_date:2010</td>
          </tr>
          <tr>
            <td>kw</td>
            <td>
              Search for a term or terms in grant titles, abstracts, streams and
              types.
            </td>
            <td>kw:"Physiological Sciences"</td>
          </tr>
          <tr>
            <td>pi</td>
            <td>Search for a PI by last name, and optional initial(s).</td>
            <td>pi:hubbard, pi:"Hubbard S"</td>
          </tr>
          <tr>
            <td>pi_id, author_id</td>
            <td>
              Search for a PI by alternative identifier, e.g. ORCID. Format must
              be {id type}/{id value}
            </td>
            <td>pi_id:ORCID/0000-0001-2345-6789</td>
          </tr>
          <tr>
            <td>aff</td>
            <td>
              Search for a term or terms in institution names/departments.
            </td>
            <td>
              aff:Cambridge, aff:"University of Manchester", aff:"Life Sciences"
            </td>
          </tr>
          <tr>
            <td>cat</td>
            <td>
              Search for a category grouping of grants. Grants can be associated
              with multiple categories.
            </td>
            <td>cat:"COVID-19"</td>
          </tr>
          <tr>
            <td>epmc_funders</td>
            <td>
              Search for funders belonging to the Europe PMC funder group.
              Accepted values are: yes|no, y|n, and true|false.
            </td>
            <td>epmc_funders:yes</td>
          </tr>
        </table>

        <h2 id="cerif-api">
          <span
            class="annotated"
            title="Common European Research Information Format"
            >CERIF</span
          >
          API
        </h2>
        <p>
          Refer to the
          <a
            href="//www.eurocris.org/Uploads/Web%20pages/CERIF-1.5/CERIF_1.5_1.xsd"
            >CERIF XML Schema</a
          >
          for details on Response Entities.
        </p>
        <h3>How Pagination Works</h3>
        <p>
          Paginated resources will be limited to 25 Grants by default and page
          numbering starts at 1 [the default value if parameter unspecified].
          The pagination details are contained within HTTP response header.
        </p>
        <p>For example:</p>
        <pre>
<a :href="links[14]">{{ links[14] }}</a>
</pre>
        <p>For the search above link header values shown below:</p>
        <pre>HTTP/1.1 200 OK<br />Server: Apache-Coyote/1.1<br />HitCount: 407<br />ResultType: CERIF<br />Query: pi:smith<br />Page: 2<br />Content-Type: text/xml<br />Transfer-Encoding: chunked</pre>

        <h3>HitCount</h3>
        <p>
          An integer defining the total numbers of records: At the time of
          writing the above search returns the integer: 407.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#grist-api-page {
  margin-top: $base-unit * 6.5;
  margin-bottom: $base-unit * 13;
  .annotated {
    text-decoration: underline dotted;
  }
}
</style>
