import { fetchDatalinks } from '@/api'

const namespaced = true

const getDefaultState = () => {
  return {
    dataLinks: [],
    promise: null,
  }
}

const state = getDefaultState()
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

/*
the desired data links object structure
{
  "tag_1": [
  {
  name,
  nameLong,
  collectionUrl,
  links:[],

}
]
}
*/

const actions = {
  async loadDataLinks({ state, rootState }) {
    const abstract = rootState.article.abstract.abstract
    if (!state.promise) {
      state.promise = fetchDatalinks(abstract.source, abstract.id, {})
    }
    return state.promise.then((response) => {
      if (response.dataLinkList) {
        const dataLinks = {}
        response.dataLinkList.Category.forEach((category) => {
          category.Section.forEach((section) => {
            if (section.Tags) {
              section.Tags.forEach((tag) => {
                if (!dataLinks[tag]) {
                  dataLinks[tag] = []
                }
                dataLinks[tag].push({
                  name:
                    category.Name === 'GOA Project'
                      ? 'QuickGO Annotations'
                      : category.Name,
                  nameLong: category['NameLong'],
                  collectionURL: section.CollectionURL,
                  sectionLinkCount: section.SectionLinkCount,
                  categoryLinkCount: category.CategoryLinkCount,
                  ObtainedBy: section.ObtainedBy,
                  collectionURLIdentifier:
                    section.Linklist.Link[0].Target.Identifier.IDScheme,
                  links: section.Linklist.Link.map((link) => {
                    const newLink = {
                      id: link.Target.Identifier.ID,
                      title: link.Target.Title,
                      frequency: link.Frequency,
                      url: link.Target.Identifier.IDURL,
                      img: link.Target.ImageURL,
                      scheme:
                        section.Linklist.Link[0].Target.Identifier.IDScheme,
                      ObtainedBy: section.ObtainedBy,
                      description: link.description,
                    }

                    //how link title will appear in UI
                    switch (newLink.ObtainedBy) {
                      case 'ext_links':
                        newLink.description = newLink.url
                        break
                      case 'tm_accession':
                        newLink.description =
                          (newLink.scheme === 'PDB' &&
                          newLink.url.includes('pdbe')
                            ? 'PDBe'
                            : newLink.scheme) +
                          ' - ' +
                          (newLink.scheme === 'CHEBI'
                            ? newLink.id
                            : newLink.id.replace(':', ''))
                        break
                      case 'submission':
                        newLink.description = newLink.title
                          ? newLink.title
                          : newLink.id
                        break
                    }
                    return newLink
                  }),
                })
              })
            }
          })
        })
        // sort supporting_data
        if (dataLinks['supporting_data']) {
          let supportingDataLinks = []
          const biostudies = dataLinks['supporting_data'].filter(
            (provider) =>
              provider.name.indexOf('BioStudies') > -1 &&
              provider.ObtainedBy !== 'tm_accession'
          )
          const dataDois = dataLinks['supporting_data'].filter(
            (provider) =>
              provider.name.indexOf('Data Citations') > -1 &&
              provider.collectionURLIdentifier === 'DOI'
          )
          let accessions = dataLinks['supporting_data']
            .filter(
              (provider) =>
                provider.ObtainedBy === 'tm_accession' &&
                provider.name.indexOf('Data Citations') == -1 &&
                provider.collectionURLIdentifier !== 'DOI'
            )
            .sort((a, b) => a.name.localeCompare(b.name))
          const otherDataLinks = dataLinks['supporting_data']
            .filter(
              (provider) =>
                provider.ObtainedBy !== 'tm_accession' &&
                provider.name.indexOf('BioStudies') == -1
            )
            .sort((a, b) => a.name.localeCompare(b.name))
          let otherDataLinksList = []
          otherDataLinks.forEach((provider) => {
            otherDataLinksList = otherDataLinksList.concat(provider.links)
          })
          // remove duplicate accessions
          accessions.forEach((provider) => {
            provider.links.forEach((link) => {
              const exists = otherDataLinksList.find(
                (otherLink) =>
                  otherLink.url
                    .toUpperCase()
                    .indexOf(
                      link.url
                        .substring(link.url.lastIndexOf('/') + 1)
                        .toUpperCase()
                    ) > -1
              )
              if (exists) {
                link.duplicate = true
                provider.sectionLinkCount--
                provider.categoryLinkCount--
              }
            })
            provider.links = provider.links.filter((link) => !link.duplicate)
          })
          accessions = accessions.filter(
            (provider) => provider.links.length > 0
          )
          // concat result
          supportingDataLinks = []
            .concat(biostudies)
            .concat(dataDois)
            .concat(accessions)
            .concat(otherDataLinks)
          dataLinks['supporting_data'] = supportingDataLinks
        }
        state.dataLinks = dataLinks
      }
    })
  },
}

export default {
  namespaced,
  state,
  mutations,
  actions,
}
