import { getDateRange } from '@/helpers/search-filters'

export const getDefaultFilters = () => {
  const currentYear = new Date().getFullYear()
  return {
    'Free full text access': [
      {
        id: 'fulltext',
        name: 'FULL TEXT',
        label: 'Full text in Europe PMC',
        query: 'HAS_FT:Y',
        count: 0,
        checked: false,
      },
      {
        id: 'link-to-free-full-text',
        name: 'LINK TO FREE FULL TEXT',
        label: 'Link to free full text',
        query: '(HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y)',
        count: 0,
        checked: false,
      },
    ],
    Type: [
      {
        id: 'research-articles',
        name: 'Research articles',
        label: 'Research articles',
        query:
          '((SRC:MED OR SRC:PMC OR SRC:AGR OR SRC:CBA) NOT (PUB_TYPE:"Review"))',
        count: 0,
        checked: false,
      },
      {
        id: 'reviews',
        name: 'REVIEW',
        label: 'Review articles',
        query: 'PUB_TYPE:REVIEW',
        count: 0,
        checked: false,
      },
      {
        id: 'preprints',
        name: 'PPR',
        label: 'Preprints',
        query: 'SRC:PPR',
        count: 0,
        checked: false,
        subfilter: [
          { name: 'PPR REVIEWED', index: 3 },
          { name: 'PPR JOURNAL PUBLISHED', index: 4 },
        ],
      },
      {
        id: 'Reviewed',
        name: 'PPR REVIEWED',
        label: 'Reviewed',
        query: 'HAS_VERSION_EVALUATIONS:Y',
        count: 10,
        checked: false,
        parent: { name: 'PPR', index: 2 },
      },
      {
        id: 'journal-published',
        name: 'PPR JOURNAL PUBLISHED',
        label: 'Journal published',
        query: 'HAS_PUBLISHED_VERSION:Y',
        count: 10,
        checked: false,
        parent: { name: 'PPR', index: 2 },
      },
      {
        id: 'books-and-documents',
        name: 'BOOKS AND DOCUMENTS',
        label: 'Books & documents',
        query: 'HAS_BOOK:Y',
        count: 0,
        checked: false,
      },
    ],
    'Date published': [
      {
        id: 'last-year',
        name: 'The last year',
        label: 'The last year',
        query: getDateRange(1),
        startYear: currentYear - 1,
        endYear: currentYear,
        count: 0,
      },
      {
        id: 'last-three-years',
        name: 'The last 3 years',
        label: 'The last 3 years',
        query: getDateRange(3),
        startYear: currentYear - 3,
        endYear: currentYear,
        count: 0,
      },
      {
        id: 'last-five-years',
        name: 'The last 5 years',
        label: 'The last 5 years',
        query: getDateRange(5),
        startYear: currentYear - 5,
        endYear: currentYear,
        count: 0,
      },
      {
        id: 'custom-date-filter',
        name: 'Custom date filter',
      },
    ],
  }
}

export const getChartConfig = () => {
  return {
    type: 'bar',
    labels: [],
    datasets: [
      {
        maxBarThickness: 84,
        minBarLength: 4,
      },
    ],
    update: false,
    maintainAspectRatio: false,
  }
}

export const SHARED_CHART_OPTIONS = {
  responsive: true,
  tooltips: {
    position: 'average',
    backgroundColor: '#3C7EAD',
    borderColor: '#3C7EAD',
    enabled: true,
    intersect: false,
    displayColors: false,
    bodyFontColor: 'white',
  },
  scales: {
    xAxes: [
      {
        ticks: {
          display: false, // hide x-axis labels
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  legend: {
    display: false,
  },
  title: 'Histogram',
}
