export function deepClone(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) return obj // Primitive value
  if (hash.has(obj)) return hash.get(obj) // Circular reference
  let copy
  if (Array.isArray(obj)) {
    // Handle arrays
    copy = obj.map((item) => deepClone(item, hash))
  } else if (typeof obj === 'object' && obj !== null) {
    // Handle objects
    copy = Object.create(Object.getPrototypeOf(obj))
    // Add this object to the hash map
    hash.set(obj, copy)
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepClone(obj[key], hash)
      }
    }
  } else {
    // Copy primitive values
    copy = obj
  }
  return copy
}
export function getDateRange(years) {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const startDate = new Date()
  startDate.setFullYear(currentYear - years)
  const startYear = startDate.getFullYear()
  const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0')
  return (
    'FIRST_PDATE:[' +
    startYear +
    '-' +
    startMonth +
    ' TO ' +
    currentYear +
    '-' +
    currentMonth +
    ']'
  )
}
