<script>
import { EPMC_FUNDERS } from '@/config'
import { fetchGristSuggest } from '@/api'
import {
  ContentSection,
  MultiSelect,
  SuggestInput,
} from 'epmc-patterns/components/v2'
import vClickOutside from 'v-click-outside'

const createFunderOptions = () => {
  return EPMC_FUNDERS.map((f) => ({
    value: f.name,
    text: f.name,
    selected: false,
  }))
}

const suggestionQueryMappings = {
  pi: 'pi',
  institution: 'aff',
  grant: 'gid',
}

const getQuery = () => ({
  kw: '',
  aff: '',
  pi: '',
  pi_id: '',
  ga: '',
  gid: '',
  current: '',
})

const uniqueQuery = getQuery()

export default {
  metaInfo() {
    return {
      title: 'Grant finder - Europe PMC',
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: { ContentSection, MultiSelect, SuggestInput },
  data() {
    return {
      query: getQuery(),
      funderOptions: createFunderOptions(),
      suggestions: {
        pi: [],
        institution: [],
        grant: [],
      },
    }
  },
  computed: {
    searchLink() {
      const linkQuery = Object.assign({}, this.query)
      Object.keys(linkQuery).forEach((k) => {
        if (!linkQuery[k]) {
          linkQuery[k] = undefined
        } else {
          if (uniqueQuery[k]) {
            linkQuery[k] = uniqueQuery[k]
          }
        }
      })
      return { name: 'grantSearch', query: linkQuery }
    },
    disabled() {
      const { query } = this
      return !Object.keys(query).filter((k) =>
        typeof query[k] === 'object' ? query[k].length : query[k]
      ).length
    },
  },
  methods: {
    changeFunders() {
      this.query.ga = this.funderOptions
        .filter((op) => op.selected)
        .map((op) => op.value)
    },
    suggest(type) {
      const text = this.query[suggestionQueryMappings[type]]
      fetchGristSuggest(type, text).then((response) => {
        if (type === 'institution') {
          //make sure institutions is a list for further processing
          let institutionList = response.institutions
            ? Array.isArray(response.institutions)
              ? response.institutions
              : [response.institutions]
            : []
          // console.log(institutionList)
          //collect unique RorOfficialNames
          const RorOfficialNames = [
            ...new Set(
              institutionList.map((institution) =>
                institution.RORID
                  ? institution.RorOfficialName.toLowerCase()
                  : null
              )
            ),
          ]
          //filter out institutionList which does not contain matching Name and RorOfficialName
          institutionList = institutionList.filter((institution) => {
            if (
              institution.RorOfficialName ||
              (institution.Name &&
                !RorOfficialNames.includes(institution.Name.toLowerCase()))
            )
              return institution
          })
          //have only unique "RorOfficialName" values
          institutionList = [
            ...new Map(
              institutionList.map((institution) => [
                institution.RORID
                  ? institution['RorOfficialName']
                  : institution['Name'],
                institution,
              ])
            ).values(),
          ]
          //replace name with RorOfficialName (RORID) if exists
          this.suggestions[type] = institutionList.map((institution) =>
            institution.RORID
              ? institution.RorOfficialName +
                ' (RORID: ' +
                institution.RORID +
                ')'
              : institution.Name
          )
        } else {
          this.suggestions[type] = response.list
            ? Array.isArray(response.list)
              ? response.list
              : [response.list]
            : []
        }
      })
    },
    selectSuggestion(type, suggestion) {
      this.query[suggestionQueryMappings[type]] = suggestion.value
      //useful for queries different from the format of chosen value
      if (suggestion.query) {
        uniqueQuery[type] = suggestion.query
      }
      this.clearSuggestions(type)
    },
    clearSuggestions(type) {
      this.suggestions[type] = []
    },
    search() {
      if (!this.disabled) {
        // console.log(this.searchLink)
        if (this.searchLink.query.aff) {
          this.searchLink.query.aff = this.searchLink.query.aff
            .split('(RORID:')[0]
            .trim()
        }
        this.$router.push(this.searchLink)
      }
    },
    clearFields() {
      const { query } = this
      Object.keys(query).forEach((k) => {
        query[k] = ''
      })
      this.funderOptions = createFunderOptions()
    },
  },
}
</script>
<template>
  <div id="grant-finder-form-page" class="grid-row">
    <div class="col-4">
      <br />
    </div>
    <div class="col-12">
      <content-section
        :section="{
          heading: 'h2',
          title: 'Europe PMC funder grants',
        }"
      >
        <p id="instruction">
          Find active and expired grants awarded by
          <a href="/Funders">Europe PMC funders</a>.
        </p>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="keyword-input">Keyword</label>
          </div>
          <div class="input-hint small">
            Search the grant title, abstract and funding stream.
          </div>
          <input
            id="keyword-input"
            v-model.trim="query.kw"
            type="text"
            @keyup.enter="search"
          />
        </div>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="pi-input"
              >Principal Investigator (PI) name</label
            >
          </div>
          <div class="input-hint small">E.g. Glover DM, or Glover</div>
          <suggest-input
            v-model="query.pi"
            input-id="pi-input"
            :suggestions="
              suggestions.pi.map((suggestion) => ({
                value:
                  suggestion.familyName +
                  (suggestion.firstName ? ' ' + suggestion.firstName : '') +
                  (suggestion.initials ? ' ' + suggestion.initials : '') +
                  (suggestion.title ? ' ' + suggestion.title : ''),
                text:
                  suggestion.familyName +
                  (suggestion.firstName ? ' ' + suggestion.firstName : '') +
                  (suggestion.initials ? ' ' + suggestion.initials : '') +
                  (suggestion.title ? ' ' + suggestion.title : ''),
                query:
                  (suggestion.title ? '{' + suggestion.title + '}' : '{}') +
                  '{' +
                  suggestion.familyName +
                  '}' +
                  (suggestion.firstName
                    ? '{' + suggestion.firstName + '}'
                    : '{}') +
                  (suggestion.initials
                    ? '{' + suggestion.initials + '}'
                    : '{}'),
              }))
            "
            @keyup.enter="search"
            @keyup.exact="suggest('pi')"
            @onClickOutside="clearSuggestions('pi')"
            @selectSuggestion="
              (suggestion) => {
                selectSuggestion('pi', suggestion)
              }
            "
          />
        </div>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="orcid-input">ORCID</label>
          </div>
          <div class="input-hint small">E.g. 0000-0002-3452-3382</div>
          <input
            id="orcid-input"
            v-model.trim="query.pi_id"
            type="text"
            @keyup.enter="search"
          />
        </div>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="aff-input">Affiliation</label>
          </div>
          <div class="input-hint small">E.g. King's College London</div>
          <suggest-input
            v-model="query.aff"
            input-id="aff-input"
            :suggestions="
              suggestions.institution.map((suggestion) => ({
                value: suggestion,
                text: suggestion,
              }))
            "
            @keyup.enter="search"
            @keyup.exact="suggest('institution')"
            @onClickOutside="clearSuggestions('institution')"
            @selectSuggestion="
              (suggestion) => {
                selectSuggestion('institution', suggestion)
              }
            "
          />
        </div>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="funders-input">Funder(s)</label>
          </div>
          <div class="input-hint small">
            Select funder(s) to filter your search
          </div>
          <multi-select
            input-id="funders-input"
            :options="funderOptions"
            @onOptionChanged="changeFunders"
            @keyup.enter="search"
          />
        </div>

        <div class="input-group">
          <div>
            <label class="semi-bold" for="gid-input">Grant ID</label>
          </div>
          <suggest-input
            v-model="query.gid"
            input-id="gid-input"
            :suggestions="
              suggestions.grant.map((suggestion) => ({
                value: suggestion,
                text: suggestion,
              }))
            "
            @keyup.enter="search"
            @keyup.exact="suggest('grant')"
            @onClickOutside="clearSuggestions('grant')"
            @selectSuggestion="
              (suggestion) => {
                selectSuggestion('grant', suggestion)
              }
            "
          />
        </div>

        <div class="input-group">
          <div class="semi-bold">Status</div>
          <input
            id="active-grants-checkbox"
            type="checkbox"
            @keyup.enter="search"
            @change="query.current = !query.current"
          />
          <label for="active-grants-checkbox" class="semi-bold">
            Active grants only
          </label>
        </div>

        <div id="buttons">
          <button
            class="secondary"
            :disabled="disabled"
            @click.prevent="clearFields"
          >
            Clear fields
          </button>
          <button
            class="search-btn"
            :disabled="disabled"
            @click.prevent="search"
          >
            Search grants
          </button>
        </div>
      </content-section>
    </div>
  </div>
</template>
<style lang="scss">
#grant-finder-form-page {
  #instruction {
    margin-bottom: $base-unit * 5;
  }
  .input-group {
    margin-bottom: $base-unit * 4;
    .input-hint {
      margin: $base-unit 0;
    }
    input[type='text'] {
      width: 100%;
      &#orcid-input {
        width: $base-unit * 63.5;
      }
    }
    #pi-input {
      width: $base-unit * 89;
    }
    #gid-input {
      width: $base-unit * 88;
    }
  }
  #buttons {
    float: right;
    .search-btn {
      margin-left: $base-unit * 4;
    }
  }
}
</style>
