import { claimArticleToOrcid, fetchLoggedInOrcidId } from '@/api'

const namespaced = true

const getDefaultState = () => {
  return {
    claimNotification: null,
    loggedInOrcidId: null,
    orcidAuthorized: false,
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  checkIfAuthorizedToClaim({ state }, citation) {
    state.claimNotification = null
    return claimArticleToOrcid(citation, true)
      .then((response) => {
        state.orcidAuthorized = +response.jqXHR.status === 200
      })
      .catch((response) => {
        state.orcidAuthorized = +response.status === 409
        if (+response.status === 409) {
          state.claimNotification = 'alreadyClaimed'
        }
      })
  },
  claimToOrcid({ state }, citation) {
    return claimArticleToOrcid(citation)
      .then((response) => {
        if (+response.jqXHR.status === 201) {
          state.claimNotification = 'justClaimed'
        }
      })
      .catch((response) => {
        state.claimNotification =
          +response.status === 409 ? 'alreadyClaimed' : 'claimFailed'
      })
  },
  getLoggedInOrcidId({ state }) {
    return fetchLoggedInOrcidId()
      .then((response) => {
        state.loggedInOrcidId =
          +response.jqXHR.status === 200 ? response.data : null
      })
      .catch((response) => response)
  },
}

export default { namespaced, state, mutations, actions }
