// what is left****
// temp sentence
// callback in the adjustLoc method
// use loc in paragraph returned by api
import { ANNOTATIONS } from '@/config'
import { fetchAnnotations } from '@/api'
import TextAnnotator from 'text-annotator'

class Annotations {
  static highlightIdPattern = 'annotation-'
  static highlightClass = 'scilite-annotation'

  static removeAnnotationTags(text) {
    const regex = new RegExp(
      '<span id="' + Annotations.highlightIdPattern + '([^>]+)>(.+)</span>',
      'gi'
    )
    return text.replace(regex, '$2')
  }

  static getTagLink(tag) {
    let uri = tag.uri

    if (uri.includes('http://purl.uniprot.org/uniprot/')) {
      uri = 'https://www.uniprot.org/uniprot/?query=' + tag.name
    }

    return uri
  }

  static getBioStructureInfoFromTag(tag) {
    const { uri, name } = tag
    let info = null
    if (uri.includes('/pdb')) {
      info = {
        linkScheme: 'PDB',
        linkId: name,
      }
    } else if (uri.includes('/CHEBI_')) {
      info = {
        linkScheme: 'CHEBI',
        linkId: uri.split('CHEBI_')[1],
      }
    } else if (name.startsWith('CHEBI:') && !isNaN(name.split(':')[1])) {
      info = {
        linkScheme: 'CHEBI',
        linkId: name.split(':')[1],
      }
    }
    return info
  }

  static getTagLinkLabel(tag) {
    const { uri } = tag

    let label = 'Details'

    if (uri.includes('/uniprot')) {
      const parts = uri.split('/')
      label = 'Uniprot (' + parts[parts.length - 1] + ')'
    } else if (uri.includes('/taxonomy')) {
      label = 'Taxonomy'
    } else if (uri.includes('/CHEBI_')) {
      label = 'ChEBI'
    } else if (uri.includes('/GO:')) {
      label = 'GO Term'
    } else if (uri.includes('linkedlifedata')) {
      label = 'Linked Life Data'
    } else if (uri.includes('www.ebi.ac.uk')) {
      label = 'EBI'
      if (uri.includes('/pdb') && tag.name.length === 4) {
        label = 'PDBe - ' + tag.name
      }
    } else if (uri.includes('/pdb')) {
      label = 'PDB'
    } else if (uri.includes('/arrayexpress')) {
      label = 'ArrayExpress'
    } else if (uri.includes('/bioproject')) {
      label = 'BioProject'
    } else if (uri.includes('/biosample')) {
      label = 'BioSample'
    } else if (uri.includes('/clinicaltrials')) {
      label = 'Clinical Trial'
    } else if (uri.includes('/doi')) {
      label = 'DOI'
    } else if (uri.includes('/ega.')) {
      label = 'EGA'
    } else if (uri.includes('/emdb')) {
      label = 'EMDB'
    } else if (uri.includes('/ensembl') || uri.includes('www.ensembl.org')) {
      label = 'Ensembl'
    } else if (uri.includes('/go')) {
      label = 'Gene Ontology'
    } else if (uri.includes('/interpro')) {
      label = 'InterPro'
    } else if (uri.includes('/omim')) {
      label = 'OMIM'
    } else if (uri.includes('/pfam')) {
      label = 'Pfam'
    } else if (uri.includes('/proteomexchange')) {
      label = 'ProteomeXchange'
    } else if (uri.includes('/refseq')) {
      label = 'RefSeq'
    } else if (uri.includes('/dbsnp')) {
      label = 'RefSNP'
    } else if (uri.includes('ncbi.nlm.nih.gov')) {
      label = 'NCBI'
    } else if (uri.includes('/umls')) {
      label = 'UMLS'
    }

    return label
  }

  static processSentence(raw) {
    let newRaw = raw
    newRaw = newRaw.replace(
      /(^<h3>(Abstract|ABSTRACT|Introduction|Funding Statement|Disclosure statement|Conclusions|Background|Results|Discussuion|Methods|Abbreviations|Competing interests|Acknowledgement|References|Authors' contributions)<\/h3>)/gi,
      ''
    )
    newRaw = newRaw.replace(
      /(<h3>(Abstract|ABSTRACT|Introduction|Funding Statement|Disclosure statement|Conclusions|Background|Results|Discussuion|Methods|Abbreviations|Competing interests|Acknowledgement|References|Authors' contributions)<\/h3>+$)/gi,
      ''
    )
    newRaw = newRaw.replace(
      /(^<h2([^>]+)>(Abstract|ABSTRACT|Introduction|Funding Statement|Disclosure statement|Conclusions|Background|Results|Discussuion|Methods|Abbreviations|Competing interests|Acknowledgement|References|Authors' contributions)<\/h2>)/gi,
      ''
    )
    newRaw = newRaw.replace(
      /(<h2([^>]+)>(Abstract|ABSTRACT|Introduction|Funding Statement|Disclosure statement|Conclusions|Background|Results|Discussuion|Methods|Abbreviations|Competing interests|Acknowledgement|References|Authors' contributions)<\/h2>+$)/gi,
      ''
    )
    newRaw = newRaw.replace(/.*<h1 class="content-title">(.*?)<\/h1>.*/gi, '$1')
    newRaw = newRaw.replace(/.*<div id="article-title">(.*?)<\/div>.*/gi, '$1')
    return newRaw
  }

  static decodeHTML(str) {
    const textarea = document.createElement('textarea')
    textarea.innerHTML = str
    return textarea.value
  }

  static processText(str) {
    if (str) {
      str = Annotations.decodeHTML(str)
      str = str.replace(/&amp;nbsp/g, '&nbsp')
      str = str.replace(/&amp;lt;/g, '&lt;')
      str = str.replace(/&amp;gt;/g, '&gt;')
      str = str.replace(/&amp;quot;/g, '&quot;')
    }
    return str
  }

  // --------1. intitialization--------
  constructor({ content, containerId, isHTML, disableLocation }) {
    // category: {
    //  title
    //  type
    //  labels
    // }

    // label: {
    //  title
    //  annotations
    //  providers?
    //  selected (if not disableLocation)
    //  nextAnnotationIndex (if not disableLocation)
    // }

    // annotation: {
    //  id
    //  prefix
    //  exact
    //  postfix
    //  providers
    //  highlightIndex (if not disableLocation)
    // }

    // provider: {
    //  provider
    //  tags
    // }
    this.categories = ANNOTATIONS.map((annotation) =>
      Object.assign({ labels: [] }, annotation)
    )

    this.disableLocation = disableLocation

    // no need to search/highlight for bio-entities
    if (!disableLocation) {
      const options = { isHTML }
      if (content) {
        options.content = content
      }
      // require highlighter and containerId if not disableLocation
      this.highlighter = new TextAnnotator(options)
      this.containerId = containerId
    }
  }
  // --------1 end--------

  // --------2. load annotations--------
  loadAnnotations({ source, id, abstractOnly }) {
    return fetchAnnotations(source, id, abstractOnly)
      .then((response) => {
        if (response.length) {
          const annotations = response[0].annotations
          const hasMetagenomicsProviderData = annotations.find(
            (annotation) => annotation.provider.toLowerCase() === 'metagenomics'
          )
          this.hasMetagenomicsProviderData = hasMetagenomicsProviderData
            ? true
            : false
          this._assignCategory(
            hasMetagenomicsProviderData
              ? annotations.filter(
                  (annotation) =>
                    annotation.provider.toLowerCase() === 'metagenomics' ||
                    annotation.provider.toLowerCase() === 'biostudies'
                )
              : annotations
          )
        }
      })
      .then(() => {
        // sort labels of each category
        this.categories.forEach((cat) => {
          cat.labels.sort((l1, l2) => {
            const a = l1.frequency || l1.annotations.length
            const b = l2.frequency || l2.annotations.length
            return b - a
          })
        })
      })
  }

  _assignCategory(annotations) {
    annotations.forEach((annotation) => {
      const catIndex = this.categories.findIndex(
        (category) =>
          category.type ===
          (annotation.type === 'Gene Disease Relationship'
            ? annotation.provider === 'Open Targets Platform'
              ? 'Gene Disease OpenTargets'
              : 'Gene Disease DisGeNET'
            : annotation.type)
      )
      if (catIndex !== -1) {
        this._addAnnotation(catIndex, annotation)
      }
    })
  }

  _addAnnotation(catIndex, annotation) {
    const {
      id,
      prefix,
      exact,
      postfix,
      tags,
      provider,
      type,
      frequency,
      fileName,
    } = annotation
    // disable eager search for now
    // const { containerId, highlighter, disableLocation } = this
    const { highlighter, disableLocation } = this

    const newPrefix = Annotations.processText(prefix)
    const newExact = Annotations.processText(exact)
    const newPostfix = Annotations.processText(postfix)

    let highlightIndex = -1

    // no need to take time to search if the annotation has frequency because the annotation has no pre- and post- fixes
    if (!disableLocation && !frequency) {
      // search options
      const options = {
        prefix: newPrefix,
        postfix: newPostfix,
        directSearchOptions: {
          caseSensitive: true,
          encode: true,
        },
        // eagerSearchOptions: { containerId },
        // disable fuzzy search for open targets for now
        fuzzySearchOptions: {
          processSentence: Annotations.processSentence,
          tokenBased:
            provider === 'Europe PMC' ||
            provider === 'PubTator (NCBI)' ||
            type === 'COVoc',
          sentenceBased: type !== 'Gene Disease OpenTargets',
        },
      }

      highlightIndex = highlighter.search(newExact, options)
    }

    // if disableLocation, that the annotation has frequency or that the annotation can locate
    if (disableLocation || frequency || highlightIndex !== -1) {
      // the annotation to be added into the label
      const annotationToAdd = {
        id,
        prefix,
        exact,
        postfix,
        providers: [
          {
            provider,
            tags,
          },
        ],
      }

      // no need to assign highlightIndex to the annotation without frequency because it was not searched
      if (!disableLocation && !frequency) {
        annotationToAdd.highlightIndex = highlightIndex
      }

      const labelName = Annotations.decodeHTML(exact)
      // the label title displayed on the side bar can be different to the label name in sentence annotations
      const labelTitle = this._getLabelTitle(labelName, tags)
      const labels = this.categories[catIndex].labels
      let label = labels.find((label) => label.title === labelTitle)

      // add new label only when the same label does not exist
      if (label) {
        const annotations = label.annotations
        // if disableLocation is true, it means that the annotation cannot be highlighted, which means that it is impossible to know whether the annotation is the same as one of the others
        // the annotation with frequency was not searched, so sameAnnotation is false
        const sameAnnotation =
          disableLocation || frequency
            ? false
            : annotations.find((a) => {
                const annotationLocation =
                  highlighter.highlights[a.highlightIndex]
                const loc =
                  annotationLocation && annotation.loc
                    ? annotationLocation.loc
                    : false
                const loc2 = highlighter.highlights[highlightIndex].loc
                return loc[0] === loc2[0] && loc[1] === loc2[1]
              })

        // add new annotation only when the same annotation does not exist
        if (sameAnnotation) {
          const sameProvider = sameAnnotation.providers.find(
            (p) => p.provider === provider
          )

          // update the same annotation only when the provider is different
          if (!sameProvider) {
            sameAnnotation.providers.push({
              provider,
              tags,
            })
          }
        } else {
          // assign the frequency to the label
          if (frequency) {
            if (provider === 'Biostudies') {
              if (label.supplementaryFrequency) {
                label.supplementaryFrequency =
                  label.supplementaryFrequency + frequency
              } else label.supplementaryFrequency = frequency
            } else label.frequency = frequency
          }
          // no need to add the annotation with frequency because the annotation can not be highlighted
          else {
            annotations.push(annotationToAdd)
          }

          if (fileName) {
            if (label.files) {
              label.files.push({
                fileName: fileName,
                supplementaryFrequency: frequency || 0,
              })
            } else
              label.files = [
                {
                  fileName: fileName,
                  supplementaryFrequency: frequency || 0,
                },
              ]
          }
        }
      } else {
        label = {
          title: labelTitle,
          annotations: [annotationToAdd],
          providers: [],
          hovered: false,
        }
        if (fileName) {
          label.files = [
            {
              fileName: fileName,
              supplementaryFrequency: frequency || 0,
            },
          ]
        }
        if (!disableLocation) {
          label = Object.assign(label, {
            selected: false,
            nextAnnotationIndex: false,
          })
        }
        // assign the frequency to the label
        if (frequency) {
          if (provider === 'Biostudies') {
            label.supplementaryFrequency = frequency
          } else {
            label = Object.assign(label, {
              frequency,
            })
          }
        }
        labels.push(label)
      }

      // update the providers in the label
      const sameProvider = label.providers.find((p) => p.provider === provider)
      if (sameProvider) {
        const sameTags = tags.every((tag) =>
          sameProvider.tags.find(
            (t) => t.name === tag.name && t.uri === tag.uri
          )
        )
        if (!sameTags) {
          sameProvider.tags = sameProvider.tags.concat(tags)
        }
      } else {
        label.providers.push({ provider, tags })
      }
    }
  }

  _getLabelTitle(labelName, tags) {
    let labelTitle = labelName
    if (tags.length === 2) {
      let firstTagIndex = 0
      for (let i = 0; i < tags.length; i++) {
        if (
          tags[i].uri.includes('ncbigene') ||
          tags[i].uri.includes('uniprot')
        ) {
          firstTagIndex = i
          break
        }
      }
      labelTitle =
        tags[firstTagIndex].name + ' - ' + tags[!firstTagIndex ? 1 : 0].name
    }
    return labelTitle
  }
  // --------2 end--------

  // --------3. interact with annotations that can be located--------
  clearAnnotations() {
    this.categories.forEach((cat) => {
      cat.labels.forEach((label) => {
        if (label.selected) {
          label.annotations.forEach((annotation) => {
            this.highlighter.highlights[
              annotation.highlightIndex
            ].highlighted = false
          })
          label.selected = false
          label.nextAnnotationIndex = false
        }
      })
    })
    return this.highlighter.originalContent
  }

  selectCategory(category, content) {
    let newContent = content

    const selected = Boolean(category.labels.find((label) => !label.selected))
    category.labels
      .filter((label) => label.selected !== selected)
      .forEach((label) => {
        newContent = this.selectLabel(label, newContent)
      })

    return newContent
  }

  selectLabel(label, content) {
    let newContent = content

    const { highlighter } = this
    label.selected = !label.selected
    const annotations = label.annotations
    const highlightIndexes = annotations.map((a) => a.highlightIndex)
    if (label.selected && annotations.length > 0) {
      // sort annotations first time of selecting
      if (
        highlighter.highlights[annotations[0].highlightIndex].highlighted ===
        undefined
      ) {
        annotations.sort((a1, a2) => {
          const loc1 = highlighter.highlights[a1.highlightIndex].loc
          const loc2 = highlighter.highlights[a2.highlightIndex].loc
          return loc1[0] - loc2[0]
        })
      }

      highlightIndexes.forEach((highlightIndex) => {
        const options = {
          highlightClass: Annotations.highlightClass,
          highlightIdPattern: Annotations.highlightIdPattern,
        }
        newContent = highlighter.highlight(highlightIndex, options)
      })
    } else {
      highlightIndexes.forEach((highlightIndex) => {
        const options = {
          highlightClass: Annotations.highlightClass,
          highlightIdPattern: Annotations.highlightIdPattern,
        }
        newContent = highlighter.unhighlight(highlightIndex, options)
      })
    }

    // is it the best place
    label.nextAnnotationIndex = false

    return newContent
  }

  goToAnnotation(label, prev) {
    if (label.nextAnnotationIndex === false) {
      label.nextAnnotationIndex = 0
    } else if (prev) {
      if (label.nextAnnotationIndex !== 0) {
        label.nextAnnotationIndex--
      }
    } else {
      label.nextAnnotationIndex =
        label.nextAnnotationIndex === label.annotations.length - 1
          ? 0
          : ++label.nextAnnotationIndex
    }
  }
}

export default Annotations
