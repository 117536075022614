// currently only for snippets and linkback
// import can be improved
// the size of text-highlight may be optimized
import TextAnnotator from 'text-annotator'
import { scrollTo } from 'epmc-patterns/helpers'
import Annotations from '@/helpers/article/annotation'

const namespaced = true

const getDefaultState = () => {
  return {
    abstractHighlighter: null,
    fulltextHighlighter: null,
    abstractHighlighterData: {},
    fulltextHighlighterData: {},
  }
}

const state = getDefaultState()

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
}

const actions = {
  async searchAndHighlight({ state, rootState, commit, dispatch }, params) {
    const { abstract } = rootState.article.abstract
    const { fulltext } = rootState.article
    const { highlighter, id, str, options } = params

    const opt = options || {}
    const highLightAbstract =
      highlighter === 'abstractHighlighter' && abstract.abstractText
    const highLightFulltext =
      highlighter === 'fulltextHighlighter' && abstract.hasFulltext

    // decide the content to be highlighted
    let content = ''
    if (highLightAbstract) {
      content = abstract.abstractText
    } else if (highLightFulltext) {
      if (fulltext.fulltextHtml) {
        content = fulltext.fulltextHtml
      } else {
        content = await dispatch('article/fulltext/loadFulltextHtml', null, {
          root: true,
        })
      }
    }

    // create new Highlighter if it does not exist
    if (!state[highlighter]) {
      state[highlighter] = new TextAnnotator({
        content,
      })
    }

    // decide the search options
    opt.searchOptions = opt.searchOptions || {}
    opt.directSearchOptions = {
      caseSensitive: true,
      encode: true,
    }
    opt.searchOptions.eagerSearchOptions = {
      containerId:
        highlighter === 'abstractHighlighter'
          ? 'article--abstract--content'
          : 'fulltextcontent',
    }
    opt.searchOptions.fuzzySearchOptions = {
      processSentence: Annotations.processSentence,
    }

    let highlightIndex = -1

    // search and highlight only when not trying before
    const highlight = state[highlighter + 'Data'][id]
    if (highlight !== undefined) {
      highlightIndex = highlight
    } else {
      const res = state[highlighter].searchAndHighlight(str, opt)
      highlightIndex = res ? res.highlightIndex : -1
      state[highlighter + 'Data'][id] = highlightIndex
      if (highlightIndex !== -1) {
        if (highLightAbstract) {
          abstract.abstractText = res.content
        } else if (highLightFulltext) {
          fulltext.fulltextHtml = res.content
        }
      }
    }

    if (highLightFulltext && highlightIndex !== -1) {
      // open the fulltext section
      commit(
        'article/sections/changeSectionVariable',
        { id: 'free-full-text', visible: true },
        { root: true }
      )

      // go to the highlighted str
      const highlightId = '#highlight-' + highlightIndex
      scrollTo(highlightId, highlightId, 64)
    }

    return Promise.resolve(highlightIndex)
  },
  unhighlightAll({ state, rootState, commit }) {
    if (state.abstractHighlighter) {
      rootState.article.abstract.abstract.abstractText =
        state.abstractHighlighter.originalContent
    }
    if (state.fulltextHighlighter) {
      rootState.article.fulltext.fulltextHtml =
        state.fulltextHighlighter.originalContent
    }
    commit('resetState')
  },
}

export default { namespaced, state, mutations, actions }
