<script>
import { mapMutations } from 'vuex'
import {
  FREQUENCY_OPTIONS,
  ALL_PREFERRED_DAY_OPTIONS,
  SEARCH_QUERY_LIMIT,
} from '@/config'
import {
  createAlert,
  fetchStats,
  readAccount,
  updateAlert,
  updateAlertsEmail,
} from '@/api'
import { containEmail } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import { Action, Modal, Tooltip } from 'epmc-patterns/components/v2'
import { Dropdown } from 'epmc-patterns/components/v3'
import { baseUnit } from 'epmc-patterns/foundations/index.scss'

/*
  id
  query
  name
  isAlert
  email
  frequency
  alwaysSend
  preferredDay
  includeMetadata
  includeShortAbstract,
  source
*/
export default {
  components: { Action, Dropdown, Modal, Tooltip },
  props: {
    alert: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // id and source are not inputs, but will be added into the event emitted
      inputs: {
        query: '',
        name: '',
        isAlert: true,
        email: '',
        frequency: '',
        alwaysSend: false,
        preferredDay: '',
        includeMetadata: true,
        includeShortAbstract: false,
      },
      showQueryError: false,
      showCharacterLimitError: false,
      showNameError: false,
      showFrequencyError: false,
      showPreferredDayError: false,
      updatedHitCount: '',
      queryEdited: false,
      queryTested: false,
      searchTermLimit: SEARCH_QUERY_LIMIT,
      frequencyOptions: FREQUENCY_OPTIONS().map((option) => ({
        ...option,
        selected: false,
      })),
      allPreferredDayOptions: {
        day: ALL_PREFERRED_DAY_OPTIONS().day.map((option) => ({
          ...option,
          selected: false,
        })),
        firstDay: ALL_PREFERRED_DAY_OPTIONS().firstDay.map((option) => ({
          ...option,
          selected: false,
        })),
      },
      emailType: '',
      accountLoaded: false,
      maxNameLength: 200,
      inputInitilised: false,
    }
  },
  computed: {
    isQueryValid() {
      return this.inputs.query
    },
    isQueryInCharacterLimit() {
      return this.inputs.query.length <= SEARCH_QUERY_LIMIT
    },
    isNameValid() {
      return this.inputs.name
    },
    isEmailValid() {
      const { inputs } = this
      return !inputs.isAlert || containEmail(inputs.email)
    },
    isFrequencyValid() {
      const { inputs } = this
      return !inputs.isAlert || inputs.frequency
    },
    isPreferredDayValid() {
      const { inputs } = this
      return (
        !inputs.isAlert || inputs.frequency === 'DAILY' || inputs.preferredDay
      )
    },
    showPreferredDayOptions() {
      const { inputs } = this
      return inputs.frequency && !['DAILY'].includes(inputs.frequency)
    },
    preferredDayOptions() {
      return this.allPreferredDayOptions[
        this.inputs.frequency === 'WEEKLY' ? 'day' : 'firstDay'
      ]
    },
    areInputsValid() {
      const {
        isQueryValid,
        isNameValid,
        isQueryInCharacterLimit,
        isEmailValid,
        isFrequencyValid,
        isPreferredDayValid,
      } = this

      return (
        isQueryValid &&
        isQueryInCharacterLimit &&
        isNameValid &&
        isEmailValid &&
        isFrequencyValid &&
        isPreferredDayValid
      )
    },
  },
  watch: {
    'inputs.query': function (newVal, oldVal) {
      if (this.inputInitilised && newVal !== oldVal) {
        // display the test links
        this.queryEdited = true
        // need to be retested
        this.queryTested = false
      }
      if (newVal) {
        this.showQueryError = false
      } else {
        this.showQueryError = true
        this.queryEdited = false
        this.queryTested = false
      }
      if (newVal.length > SEARCH_QUERY_LIMIT) {
        this.showCharacterLimitError = true
        return
      }
      this.showCharacterLimitError = false
    },
    'inputs.name': function (newVal) {
      this.showNameError = newVal ? false : true
    },
    'inputs.includeShortAbstract': function (newVal) {
      if (newVal) {
        registerMatomoEvent(
          'Create/Edit/Convert alert',
          'Tick box to receive abstract',
          'Receive abstract'
        )
      }
    },
    'inputs.isAlert': function (newVal) {
      if (!newVal) {
        registerMatomoEvent(
          'Create/Edit/Convert alert',
          'Choose not to receive alert',
          'Select no alert'
        )
      }
    },
  },
  async created() {
    const { alert, inputs, maxNameLength } = this

    // populate values into inputs
    if (alert.query !== undefined) inputs.query = alert.query
    if (alert.name !== undefined) inputs.name = alert.name
    inputs.name = inputs.name.substring(0, maxNameLength)
    if (alert.isAlert !== undefined) inputs.isAlert = alert.isAlert
    readAccount().then((response) => {
      if (!response['Error']) {
        inputs.email =
          response.tempNotificationEmail ||
          response.notificationEmail ||
          response.email ||
          ''
        if (
          response.tempNotificationEmail &&
          response.tempNotificationEmail !==
            (response.notificationEmail || response.email)
        ) {
          this.emailType = 'temp'
        } else if (response.notificationEmail || response.email) {
          this.emailType = 'notification'
        }
      }
      this.accountLoaded = true
    })
    if (alert.frequency !== undefined) inputs.frequency = alert.frequency || ''
    if (inputs.frequency) {
      this.frequencyOptions.find(
        (option) => option.value === inputs.frequency
      ).selected = true
    }
    if (alert.alwaysSend !== undefined) inputs.alwaysSend = alert.alwaysSend
    if (alert.preferredDay !== undefined)
      inputs.preferredDay = alert.preferredDay
    if (inputs.preferredDay) {
      this.preferredDayOptions.find(
        (option) => option.value === inputs.preferredDay
      ).selected = true
    }
    // if (alert.includeMetadata !== undefined) inputs.includeMetadata = alert.includeMetadata
    if (alert.includeShortAbstract !== undefined)
      inputs.includeShortAbstract = alert.includeShortAbstract

    // init hitCount
    const updatedHitCount =
      (inputs.query && (await fetchStats(inputs.query)).hitCount) || '0'
    this.updatedHitCount = updatedHitCount.toLocaleString()

    this.inputInitilised = true
  },
  methods: {
    ...mapMutations('alert', ['setOpenedAlertModal']),
    async updateHitCount() {
      if (this.inputs.query) {
        this.updatedHitCount = (
          await fetchStats(this.inputs.query)
        ).hitCount.toLocaleString()
        // hide the test link
        this.queryTested = true
      }
      registerMatomoEvent(
        'Create/Edit/Convert alert',
        'Click to get a new count',
        'Test results'
      )
    },
    onFrequencyOptionSelected(option) {
      const { inputs, preferredDayOptions } = this

      inputs.frequency = option.value
      preferredDayOptions.forEach((option) => {
        option.selected = false
      })

      inputs.preferredDay = ''
      this.showFrequencyError = false
      this.showPreferredDayError = false
    },
    onPreferredDayOptionSelected(option) {
      this.inputs.preferredDay = option.value
      this.showPreferredDayError = false
    },
    async saveAlert() {
      const {
        alert,
        areInputsValid,
        emailType,
        inputs,
        isQueryValid,
        isNameValid,
        isQueryInCharacterLimit,
        isFrequencyValid,
        isPreferredDayValid,
        title,
        $router,
      } = this

      if (areInputsValid) {
        const newAlert = {
          ...alert,
          ...inputs,
          source: $router.currentRoute.name,
        }
        // can avoid some refreshes in future
        let href = ''
        if (alert.id) {
          const response = await Promise.all(
            emailType
              ? [updateAlert(newAlert)]
              : [
                  updateAlert(newAlert),
                  updateAlertsEmail({
                    data: { tempNotificationEmail: inputs.email },
                  }),
                ]
          )
          const success =
            response.length === 1
              ? !response['Error']
              : !response[0]['Error'] && !response[1]['Error']
          if (success) {
            let status = ''
            if (title === 'Edit alert') {
              status = 'alert_edited'
            } else if (title === 'Convert to alert') {
              if (newAlert.isAlert) {
                status = 'alert_converted'
              } else {
                status = 'alert_converted_to_inactive'
              }
            }
            href = '/accounts/SavedAlerts?status=' + status
          }
        } else {
          const response = await Promise.all(
            emailType
              ? [createAlert(newAlert)]
              : [
                  createAlert(newAlert),
                  updateAlertsEmail({
                    data: { tempNotificationEmail: inputs.email },
                  }),
                ]
          )
          const success =
            response.length === 1
              ? !response['Error']
              : !response[0]['Error'] && !response[1]['Error']
          if (success) {
            let status = ''
            if (inputs.isAlert) {
              if (emailType) {
                status = 'alert_created'
              } else {
                status = 'alert_created_without_email&email=' + inputs.email
              }
            } else {
              status = 'inactive_alert_created'
            }
            href = '/accounts/SavedAlerts?status=' + status
          }
        }
        if (href) {
          location.href =
            href +
            '&alertName=' +
            newAlert.name +
            '&alertQuery=' +
            newAlert.query
        }

        registerMatomoEvent(
          'Create/Edit/Convert alert',
          'Click "Save"',
          'Save alert'
        )
      } else {
        if (!isQueryValid) {
          this.showQueryError = true
        } else if (!isQueryInCharacterLimit) {
          this.showCharacterLimitError = true
        }
        if (!isNameValid) {
          this.showNameError = true
        }
        if (!isFrequencyValid) {
          this.showFrequencyError = true
        }
        if (!isPreferredDayValid) {
          this.showPreferredDayError = true
        }
      }
    },
    closeAlertModal() {
      this.$emit('close')
    },
    editAlertsEmail() {
      const { alert, inputs, title } = this
      this.setOpenedAlertModal({ alert: { ...alert, ...inputs }, title })
      location.href = '/accounts/ChangeAlertsEmail?status=on_alert_modal_opened'
    },
    registerMatomoEvent,
    resize() {
      const element = this.$refs['textarea']
      if (
        element.style.height !== `${parseInt(baseUnit) * 25}px` ||
        element.value === ''
      ) {
        element.style.height = `${parseInt(baseUnit) * 9}px`
        element.style.height = element.scrollHeight + 'px'
      }
    },
  },
}
</script>
<template>
  <modal
    class="large"
    :close-when-clicking-outside="true"
    @close="closeAlertModal"
  >
    <template slot="title">
      {{ title }}
    </template>

    <div class="input-group">
      <div>
        <div>
          <label for="Alert_Popup_Search_Terms" class="semi-bold"
            >Search terms</label
          ><br />
          <template v-if="showQueryError">
            <span class="error-message">Please enter query.</span><br />
          </template>
          <template v-if="showCharacterLimitError">
            <span class="error-message">
              Your query is {{ inputs.query.length }} characters long, and the
              Save & create alert feature has a limit of
              {{ searchTermLimit }} characters. To continue, shorten your
              query</span
            ><br />
          </template>
          <textarea
            id="Alert_Popup_Search_Terms"
            ref="textarea"
            v-model.trim="inputs.query"
            :class="{ error: showQueryError || showCharacterLimitError }"
            @input="resize()"
          />
        </div>
      </div>
      <div
        v-if="updatedHitCount !== ''"
        id="query-input-helper"
        class="semi-bold small"
      >
        <span
          >{{ updatedHitCount }}
          {{ updatedHitCount === '1' ? 'result' : 'results' }}</span
        >
        <span v-if="isQueryValid && queryEdited">
          <action
            v-if="!queryTested"
            id="test-results-links"
            @click.prevent="updateHitCount"
            >Check for new results</action
          >
          <span id="edit-query-links-separator">|</span>
          <action
            :href="'/search?query=' + encodeURIComponent(inputs.query)"
            target="_blank"
            @click="
              registerMatomoEvent(
                'Create/Edit/Convert alert',
                'Click new window to see new results',
                'View results in new window'
              )
            "
            >View new results in separate window</action
          >
        </span>
      </div>
    </div>

    <div class="input-group">
      <div>
        <label for="Alert_Popup_Alert_Name" class="semi-bold"
          >Name of alert
          <span class="regular"
            >(max. {{ maxNameLength }} characters)</span
          ></label
        ><br />
        <template v-if="showNameError">
          <span class="error-message">Please enter name.</span><br />
        </template>
        <input
          id="Alert_Popup_Alert_Name"
          v-model.trim="inputs.name"
          type="text"
          :maxlength="maxNameLength"
          :class="{ error: showNameError }"
        />
      </div>
    </div>

    <div class="input-group">
      <label class="semi-bold"
        >Would you like to receive email updates when there are new
        results?</label
      ><br />
      <input
        id="Alert_Popup_Email_Yes"
        v-model="inputs.isAlert"
        type="radio"
        :value="true"
      />
      <label for="Alert_Popup_Email_Yes">Yes</label>&nbsp;&nbsp;
      <input
        id="Alert_Popup_Email_No"
        v-model="inputs.isAlert"
        type="radio"
        :value="false"
      />
      <label for="Alert_Popup_Email_No">No, just save the search terms</label>
    </div>

    <template v-if="inputs.isAlert">
      <div class="input-group">
        <label for="email-list-input" class="semi-bold">Alerts email</label
        ><br />
        <span v-if="emailType" id="email-list-input" class="small">
          {{ inputs.email
          }}<span class="label"
            ><template v-if="emailType === 'temp'"
              ><span class="error-message">Unverified</span
              >&nbsp;|&nbsp;</template
            ><action
              id="Alert_Popup_Email_Edit"
              @click.prevent="editAlertsEmail"
              >Edit email for all alerts</action
            ></span
          >
        </span>
        <template v-else-if="accountLoaded">
          <div v-if="!isEmailValid" class="error-message">
            Please enter a valid email address.
          </div>
          <div>
            <em
              >Enter email for alerts, no email is currently associated with
              this account.</em
            >
          </div>
          <input
            id="email-list-input"
            v-model.trim="inputs.email"
            type="text"
            :class="{ error: !isEmailValid }"
          />
        </template>
      </div>

      <div class="input-group">
        <div id="dropdown-group">
          <div>
            <label for="Alert_Popup_Frequency_Dropdown" class="semi-bold"
              >Frequency</label
            ><br />
            <span v-if="showFrequencyError" class="error-message"
              >Please select frequency.</span
            ><br v-if="showFrequencyError || showPreferredDayError" />
            <dropdown
              id="Alert_Popup_Frequency_Dropdown"
              :class="[
                'alert-modal-dropdown',
                { 'error-input': showFrequencyError },
              ]"
              :options="frequencyOptions"
              default-option-text="Select frequency"
              @onOptionSelected="onFrequencyOptionSelected"
            />
          </div>
          <div v-if="showPreferredDayOptions">
            <label for="Alert_Popup_Preferred_Day" class="semi-bold">Day</label
            ><br />
            <template v-if="showPreferredDayError">
              <span class="error-message">Please select preferred day.</span
              ><br />
            </template>
            <dropdown
              id="Alert_Popup_Preferred_Day"
              :class="[
                'alert-modal-dropdown',
                { 'error-input': showPreferredDayError },
              ]"
              :options="preferredDayOptions"
              default-option-text="Select day"
              @onOptionSelected="onPreferredDayOptionSelected"
            />
          </div>
        </div>
        <div id="frequency-helptext" class="small">
          The query will be checked for new results at approx. 06:00 GMT, on the
          day and frequency selected, and you will be emailed only if there is
          an update.
        </div>
      </div>

      <div class="input-group">
        <label class="semi-bold">What would you like to receive?</label><br />
        <input
          id="title-author-journal-input"
          v-model="inputs.includeMetadata"
          type="checkbox"
          disabled="disabled"
        />
        <label for="title-author-journal-input">Title, author, journal</label>
        <br />
        <input
          id="Alert_Popup_Partial_Abstract"
          v-model="inputs.includeShortAbstract"
          type="checkbox"
        />
        <label for="Alert_Popup_Partial_Abstract">Partial abstract</label
        >&nbsp;<tooltip placement="right">
          <i slot="trigger" class="far fa-question-circle" />
          The first few lines of the abstract will be forwarded in the email
          update.
        </tooltip>
      </div>
    </template>

    <div class="input-group">
      <input
        id="Alert_Popup_Save_Button"
        type="button"
        value="Save"
        @click.prevent="saveAlert"
      />
      <action id="Alert_Popup_Cancel_Button" @click.prevent="closeAlertModal"
        >Cancel</action
      >
    </div>
  </modal>
</template>
<style scoped lang="scss">
.input-group {
  &:not(:last-of-type) {
    margin-bottom: $base-unit * 6;
  }
  &:last-of-type {
    margin-top: $base-unit * 6;
  }
  .error-message {
    color: $epmc-red;
  }
  input[type='text'] {
    width: 100%;
  }
  input[type='radio'] {
    margin-left: 0;
  }
  input[type='checkbox'] {
    margin-left: 0;
  }
  #query-input-helper {
    margin-top: $base-unit;
    #test-results-links {
      margin-left: $base-unit * 2;
    }
    #edit-query-links-separator {
      margin: 0 ($base-unit * 2);
    }
  }
  #email-list-input .label {
    margin-left: $base-unit * 4;
  }
  #dropdown-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .alert-modal-dropdown {
      width: $base-unit * 75;
    }
  }
  #frequency-helptext {
    margin-top: $base-unit;
  }
  #Alert_Popup_Save_Button {
    margin-right: $base-unit * 4;
  }
  #Alert_Popup_Search_Terms {
    height: $base-unit * 8.5;
    padding-top: $base-unit;
    resize: none;
    width: 100%;
    max-height: $base-unit * 25;
  }
}
</style>
