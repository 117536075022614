import { EXPORT_LIST_LIMIT } from '@/config'

const namespaced = true

const state = {
  exportList: [],
}

const mutations = {
  addSelectedArticle(state, article) {
    // the article will be added if within the limit and has not been added
    if (
      state.exportList.length < EXPORT_LIST_LIMIT &&
      !state.exportList.find((art) => art.id === article.id)
    ) {
      state.exportList.push(article)
    }
  },
  removeArticles(state) {
    state.exportList = []
  },
  removeSelectedArticle(state, article) {
    state.exportList = state.exportList.filter((art) => art.id !== article.id)
  },
}

export default { namespaced, state, mutations }
