<script>
import { fetchAllGrants, fetchGrants } from '@/api'
import { replaceUrlQuery } from 'epmc-patterns/helpers'
import { downloadFile } from '@/helpers/file'
import { getOrcid, getUiType } from '@/helpers/grant-finder'
import {
  Action,
  ContentSection,
  List,
  Loading,
  Modal,
  Notification,
  Pagination,
} from 'epmc-patterns/components/v2'
import GrantOutline from '@/templates/grant-finder/GrantOutline'

export default {
  metaInfo() {
    return {
      title: 'Grant search - Europe PMC',
    }
  },
  components: {
    Action,
    ContentSection,
    GrantOutline,
    Loading,
    List,
    Modal,
    Notification,
    Pagination,
  },
  // type check of pi_id and current fail
  props: {
    kw: {
      type: String,
      default: '',
    },
    aff: {
      type: String,
      default: '',
    },
    pi: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line
    pi_id: {
      type: String,
      default: '',
    },
    ga: {
      type: String,
      default: '',
    },
    gid: {
      type: String,
      default: '',
    },
    current: {
      type: String,
      default: '',
    },
    cat: {
      type: String,
      default: '',
    },
    page: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      loading: true,
      hitCount: 0,
      pageSize: 25,
      grants: [],
      keywordInput: this.kw,
      GRANT_FILED_NAMES: {
        kw: 'Keyword',
        aff: 'Affiliation',
        pi: 'Principal Investigator (PI) name',
        pi_id: 'ORCID',
        ga: 'Funder(s)',
        gid: 'Grant ID',
        current: 'Status',
      },
      showExport: false,
      exportFormat: 'csv',
      downloadLoading: false,
    }
  },
  computed: {
    showFilterIndication() {
      const excludedList = ['kw', 'cat', 'page']
      return Object.entries(this._props).find(
        (kw) => !excludedList.includes(kw[0]) && kw[1]
      )
    },
  },
  watch: {
    $route: 'getGrantSearchResults',
    kw(val) {
      this.keywordInput = val
    },
  },
  created() {
    this.getGrantSearchResults()
  },
  methods: {
    async getGrantSearchResults() {
      const { kw, aff, pi, pi_id, ga, gid, current, cat, page } = this
      this.loading = true
      const response = await fetchGrants({
        kw,
        aff,
        pi,
        pi_id,
        ga,
        gid,
        current,
        cat,
        page,
      })
      this.loading = false
      this.hitCount = +response.HitCount
      const record = response.RecordList.Record
      this.grants = record.length ? record : [record]
    },
    getUiType,
    getTitle() {
      return getUiType() === 'epmc-search'
        ? 'Europe PMC funder grants search results'
        : 'COVID-19 grants'
    },
    search() {
      // always search for covid-19 related grants in the search bar
      this.$router.push({
        name: 'grantSearch',
        query: {
          kw: this.keywordInput,
          cat: 'COVID-19',
        },
      })
    },
    async onPageEntered({ value }) {
      await this.getGrantSearchResults()
      this.$router.push({
        path: 'results' + replaceUrlQuery({ page: value }),
      })
    },
    async download() {
      const {
        kw,
        aff,
        pi,
        pi_id,
        ga,
        gid,
        current,
        cat,
        hitCount,
        pageSize,
        exportFormat,
      } = this

      this.downloadLoading = true

      const response = await fetchAllGrants({
        kw,
        aff,
        pi,
        pi_id,
        ga,
        gid,
        current,
        cat,
        hitCount,
        pageSize,
        format: exportFormat === 'csv' ? 'json' : 'xml',
      })

      let doc = ''
      if (exportFormat === 'csv') {
        // add header if the format is csv
        doc +=
          'Salutation,Initials,Given Name,Surname,ORCID,Funder,PubMed Search Term,Grant Title,Grant ID,Old Grant ID,Currency,Amount Awarded,Grant Stream,Grant Type,Start Date,End Date,Lay Abstract,Scientific Abstract,Institution Name,Department\n'
        response.forEach((r) => {
          if (r.RecordList) {
            const record = r.RecordList.Record
            const grants = record.length ? record : [record]
            grants.forEach((grant) => {
              const { Person, Grant, Institution } = grant
              const getAbstract = (type) => {
                let abstract = ''
                if (Grant.Abstract) {
                  if (Grant.Abstract.length) {
                    const obj = Grant.Abstract.find((a) => a['@Type'] === type)
                    if (obj) {
                      abstract = obj['$']
                    }
                  } else if (Grant.Abstract['@Type'] === type) {
                    abstract = Grant.Abstract['$']
                  }
                }
                return abstract
              }

              const layAbstract = getAbstract('lay')
              const scientificAbstract = getAbstract('scientific')
              const lineArr = [
                Person.Title || '',
                Person.Initials || '',
                Person.GivenName ? '"' + Person.GivenName + '"' : '',
                Person.FamilyName ? '"' + Person.FamilyName + '"' : '',
                getOrcid(Person),

                Grant.Funder.Name,
                Grant.Funder.pubMedSearchTerm || '',
                '"' + Grant.Title + '"',
                '"' + Grant.Id + '"',
                Grant.Alias ? '"' + Grant.Alias + '"' : '',
                Grant.Amount ? Grant.Amount['@Currency'] : '',
                Grant.Amount ? Grant.Amount['$'] : '',
                Grant.Stream ? '"' + Grant.Stream + '"' : '',
                Grant.Type ? '"' + Grant.Type + '"' : '',
                Grant.StartDate || '',
                Grant.EndDate || '',
                layAbstract ? '"' + layAbstract + '"' : '',
                scientificAbstract ? '"' + scientificAbstract + '"' : '',

                Institution && Institution.Name
                  ? '"' + Institution.Name + '"'
                  : '',
                Institution && Institution.Department
                  ? '"' + Institution.Department + '"'
                  : '',
              ]
              doc += lineArr.join(',') + '\n'
            })
          }
        })
      } else if (exportFormat === 'xml') {
        const resStartElm = '<Response>'
        const resEndElm = '</Response>'
        const hitCountElm = `<HitCount>${hitCount}</HitCount>`
        response.forEach((r) => {
          let xmlStr = new XMLSerializer().serializeToString(r.documentElement)
          xmlStr = xmlStr.replace(resStartElm, '')
          xmlStr = xmlStr.replace(resEndElm, '')
          xmlStr = xmlStr.replace(hitCountElm, '')
          doc += xmlStr
        })
        doc = resStartElm + hitCountElm + doc + resEndElm
      }

      const ext = exportFormat === 'csv' ? 'text/csv' : 'application/xml'
      downloadFile(doc, ext, 'europepmc')
      this.downloadLoading = false
      this.showExport = false
    },
    closeExport() {
      if (!this.downloadLoading) {
        this.showExport = false
      }
    },
  },
}
</script>
<template>
  <div id="grant-search-page" class="grid-row">
    <div class="col-4">
      <br />
    </div>
    <div class="col-12">
      <content-section
        :section="{
          heading: 'h2',
          title: getTitle(),
        }"
      >
        <button
          v-if="hitCount"
          id="export-btn"
          class="secondary"
          @click.prevent="showExport = true"
        >
          Export
        </button>
        <!-- epmc grant search has outline and back link; covid-19 grant search has search bar -->
        <template v-if="getUiType() === 'epmc-search'">
          <template v-for="(v, k) in _props">
            <div v-if="GRANT_FILED_NAMES[k] && v" :key="k">
              <b>{{ GRANT_FILED_NAMES[k] }}</b
              >:
              {{
                typeof v === 'object' && v.length
                  ? v.join(', ')
                  : k === 'current' && v
                  ? 'active'
                  : v.replace(/{/g, '').replace(/}/g, ' ')
              }}
            </div>
          </template>
          <div class="search-again">
            <action :to="{ name: 'grantFinderForm' }"
              >Search again<i slot="icon" class="fas fa-search"
            /></action>
          </div>
        </template>
        <template v-else>
          <p>
            Find grants related to COVID-19 research. These grants are not
            limited to Europe PMC funders. This data is compiled by the UKCDR
            and GloPID-R COVID-19
            <a
              href="//www.ukcdr.org.uk/funding-landscape/covid-19-research-project-tracker/"
              >Research Project Tracker</a
            >, 2020, with the grateful support of funders and research
            organisations worldwide.
          </p>
          <template v-if="showFilterIndication">
            <template v-for="(v, k) in _props">
              <div v-if="GRANT_FILED_NAMES[k] && v" :key="k">
                <b>{{ GRANT_FILED_NAMES[k] }}</b
                >:
                {{
                  typeof v === 'object' && v.length
                    ? v.join(', ')
                    : v.replace(/{/g, '').replace(/}/g, ' ')
                }}
              </div>
            </template>
            <div class="search-again">
              <action
                :to="{ name: 'grantSearch', query: { kw, cat: 'COVID-19' } }"
                >Search again<i slot="icon" class="fas fa-search"
              /></action>
            </div>
          </template>
          <template v-else>
            <label for="keyword-input" class="semi-bold">Keyword</label>
            <input
              id="keyword-input"
              v-model.trim="keywordInput"
              type="text"
              placeholder="Enter keyword"
              @keyup.enter="search"
            />
            <span class="search-icon" @click="search">
              <i class="fas fa-search" />
            </span>
          </template>
        </template>

        <loading v-if="loading" />
        <template v-else-if="hitCount">
          <hr id="result-separator" />
          <div class="result-count first">
            {{ ((page - 1) * pageSize + 1).toLocaleString() }}-{{
              page * pageSize > hitCount
                ? hitCount.toLocaleString()
                : (page * pageSize).toLocaleString()
            }}
            of
            <span class="semi-bold">{{ hitCount.toLocaleString() }}</span>
            results
            <pagination
              v-if="hitCount > pageSize"
              id="top-pagination"
              :total-size="hitCount"
              :page-size="pageSize"
              :max-number-of-displayed-pages="5"
              :current-page="+page"
              @onPageEntered="onPageEntered"
            />
          </div>
          <list :list="grants" :separator-below="true">
            <grant-outline
              slot-scope="{ item }"
              :grant="item"
              :cat="cat"
            ></grant-outline>
          </list>
        </template>
        <notification v-else>
          There are no grants matching your search.
        </notification>
        <div v-if="hitCount && !loading" class="result-count">
          {{ ((page - 1) * pageSize + 1).toLocaleString() }}-{{
            page * pageSize > hitCount
              ? hitCount.toLocaleString()
              : (page * pageSize).toLocaleString()
          }}
          of
          <span class="semi-bold">{{ hitCount.toLocaleString() }}</span>
          results
          <pagination
            v-if="hitCount > pageSize"
            id="bottom-pagination"
            :total-size="hitCount"
            :page-size="pageSize"
            :max-number-of-displayed-pages="5"
            :current-page="+page"
            @onPageEntered="onPageEntered"
          />
        </div>
      </content-section>
    </div>
    <modal v-if="showExport" @close="closeExport">
      <template slot="title">Export grants</template>
      <b>{{ hitCount }}</b> grant
      {{ hitCount === 1 ? 'record' : 'records' }} are available to export
      <div>
        <label
          ><input
            v-model="exportFormat"
            type="radio"
            value="csv"
            :disabled="downloadLoading"
          />CSV (comma separated - excel)</label
        >
      </div>
      <div>
        <label
          ><input
            v-model="exportFormat"
            type="radio"
            value="xml"
            :disabled="downloadLoading"
          />XML</label
        >
      </div>
      <div>
        <button
          :disabled="downloadLoading"
          class="download-btn"
          @click.prevent="download"
        >
          Download
        </button>
      </div>
      <notification v-if="downloadLoading">
        The downloading may take from a few seconds to a few minutes.
      </notification>
      <loading v-if="downloadLoading" />
    </modal>
  </div>
</template>
<style scoped lang="scss">
#export-btn {
  float: right;
  margin-top: $base-unit * -15;
}
.search-again {
  margin-top: $base-unit * 4;
  .action {
    font-size: $base-unit * 4;
  }
}
#result-separator {
  margin-top: $base-unit * 8;
}
.result-count {
  margin: ($base-unit * 2) 0;
  &.first {
    margin-bottom: $base-unit * 6;
  }
  .pagination {
    float: right;
  }
}
input[type='text'] {
  width: 100%;
}
.notification {
  margin-top: $base-unit * 4;
}
.search-icon {
  position: absolute;
  cursor: pointer;
  margin-top: $base-unit * 0.25;
  margin-left: $base-unit * -8;
  width: $base-unit * 8;
  background-color: $epmc-darker-blue;
  line-height: $base-unit * 8;
  text-align: center;
  color: white;
  &:hover {
    background-color: $epmc-dark-blue;
  }
}
.download-btn {
  margin-top: $base-unit * 4;
}
</style>
