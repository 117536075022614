<script>
import { fetchSearchResults } from '@/api'
import formatNum from '@/helpers/number'

export default {
  metaInfo() {
    return {
      title: 'Downloads - Developers - Europe PMC',
    }
  },
  data() {
    return {
      oaCount: {
        query: 'HAS_FT:Y AND OPEN_ACCESS:Y',
        count: 0,
      },
    }
  },
  created() {
    const { oaCount } = this
    fetchSearchResults({ query: oaCount.query }).then((response) => {
      oaCount.count = formatNum(response.hitCount)
    })
  },
}
</script>
<template>
  <div id="downloads-page">
    <div class="grid-row">
      <div class="col-11">
        <h1>Bulk downloads</h1>
        <p>
          There are eight types of Europe PMC content available for automated,
          bulk download:
        </p>
        <p>
          <a href="/downloads/openaccess">Open access subset</a><br />
          Nearly
          <a :href="'/search?query=' + oaCount.query">{{ oaCount.count }}</a>
          full text open access articles available to download using the Europe
          PMC web services or FTP site.
        </p>
        <p>
          <a href="/downloads/manuscripts">Author manuscript collection</a
          ><br />
          Author manuscripts uploaded by authors to PubMed Central and Europe
          PMC, to comply with funders’ Open Access policies, available in XML
          and Text format via FTP.
        </p>
        <p>
          <a href="/downloads/preprints">Preprints subset</a><br />
          Preprint abstracts or full text, open access preprint articles,
          available to download using the Europe PMC web services or in XML
          format via FTP.
        </p>
        <p>
          <a href="//europepmc.org/ftp/pmclitemetadata/"
            >Metadata of all Full-Text Europe PMC articles</a
          ><br />
          This FTP directory contains XML for key metadata for all articles
          available from Europe PMC (metadata is extracted using the
          <a href="/RestfulWebService#result-type">lite response</a> of the
          Europe PMC API). It is updated weekly.
        </p>
        <p>
          <a href="//europepmc.org/ftp/DOI_mappings">PMID-PMCID-DOI mappings</a
          ><br />
          A file of PMID-PMCID-DOI mappings, or partial mappings, available via
          FTP. All PMIDs and PMCIDs are included, with the other two IDs shown
          where available. The file is overwritten on the first of the month.
        </p>
        <p>
          <a href="//europepmc.org/ftp/TextMinedTerms"
            >Accession numbers in Europe PMC articles</a
          ><br />
          This FTP directory contains database accession numbers found in Europe
          PMC articles, using text mining. It is updated weekly.
        </p>
        <p>
          <a href="//europepmc.org/ftp/ref_check"
            >Deposition dates for author manuscripts</a
          ><br />
          A CSV file of deposition dates and other citation details of author
          manuscripts deposited in Europe PMC via the Europe PMC Plus submission
          system, for the purposes of HEFCE REF compliance checking. The file is
          updated monthly.
        </p>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#downloads-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
